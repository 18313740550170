import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const StyledImage = styled('img')``;

const Image = props => {
    return <StyledImage {...props} />;
};

Image.propTypes = {
    alt: PropTypes.string.isRequired,
    height: PropTypes.string,
    objectfit: PropTypes.string,
    sizes: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    srcset: PropTypes.string,
    width: PropTypes.string,
};

Image.defaultProps = {
    height: 'auto',
    objectfit: 'contain',
    srcset: null,
    width: '100%',
};

export default Image;
