import PropTypes from 'prop-types';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';

const Row = styled('div', { shouldForwardProp: prop => ['overflowPadding'].indexOf(prop) === -1 })`
    display: flex;
    flex-direction: row;

    ${({ overflowPadding }) =>
        overflowPadding &&
        `
        &::before, &::after {
            display: block;
            content: "";
            ${responsiveBreakpointsStyle(overflowPadding, 'width: {value}; max-width: {value}; flex: 0 0 {value};')}
        }
    `}
`;

Row.propTypes = {
    overflowPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Row;
