import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import Search from 'assets/icons/Search';
import { above } from 'utils/mediaqueries';
import { closeMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 8px;

    ${above.md} {
        margin: 0 12px;
    }

    > span {
        display: none;

        ${above.md} {
            display: block;
        }
    }
`;
const Text = styled('span')`
    margin-right: 6px;
`;

// This only handles the redirection to the overlay.
const SearchButton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showOverlay = useSelector(state => state.overlay.show);
    const hideOverlay = useSelector(state => state.overlay.hide);
    const currentOverlay = useSelector(state => state.overlay.current);

    const handleClick = () => {
        dispatch(closeMenu());
        currentOverlay === 'searchOverlay'
            ? hideOverlay('searchOverlay')
            : showOverlay('searchOverlay', { zIndex: zIndex.searchOverlay });
    };

    return (
        <Wrapper onClick={() => handleClick()}>
            <Text>{t('header.search')}</Text>
            <Search />
        </Wrapper>
    );
};

export default SearchButton;
