import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const PriceWrapper = styled('div')`
    color: ${colors.black};
    font-size: 1.4rem;
    line-height: 1.2;
`;

const BasePrice = styled('span')`
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
`;

const FullPrice = styled(BasePrice, {
    shouldForwardProp: prop => ['onSale'].indexOf(prop) === -1,
})`
    white-space: nowrap;

    ${({ onSale }) =>
        onSale &&
        `
        color: ${colors.grey4};
        text-decoration: line-through;
    `}
`;

const SalePrice = styled(BasePrice)`
    margin-left: 8px;
`;

const Price = ({ priceWithCurrency, salePriceWithCurrency, onSale, ...rest }) => (
    <PriceWrapper {...rest}>
        <FullPrice className="price" onSale={onSale}>
            {priceWithCurrency}
        </FullPrice>
        {!!onSale && <SalePrice className="salePrice">{salePriceWithCurrency}</SalePrice>}
    </PriceWrapper>
);

Price.propTypes = {
    className: PropTypes.string,
    onSale: PropTypes.bool,
    priceWithCurrency: PropTypes.string.isRequired,
    salePriceWithCurrency: PropTypes.string,
};

Price.defaultProps = {
    className: undefined,
    onSale: false,
    salePriceWithCurrency: undefined,
};

export default Price;
