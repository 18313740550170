import React, { useRef } from 'react';

import Globe from 'assets/icons/Globe';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import { inServer } from 'config/constants';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const SelectWrapper = styled('div')`
    display: flex;
    align-items: center;
    position: relative;
`;

const Select = styled('select')`
    position: relative;
    overflow: hidden;
    padding: 0 18px 0 0;
    font-size: 1.6rem;
    color: ${colors.white};
    background: transparent;
    appearance: none;
    border: none;
    outline: none;
    text-overflow: ellipsis;
    word-break: break-all;
    z-index: 1;

    ${above.md} {
        padding: 0 24px 0 0;
        font-size: 1.8rem;
    }
`;

const Option = styled('option')`
    color: ${colors.black};
`;

const GlobeIcon = styled(Globe)`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 0;
`;

const SeoLinks = styled('div')`
    visibility: hidden;
`;

const SiteSelector = ({ sites = [] }) => {
    const locale = useSelector(state => state.application.locale);

    const ref = useRef();

    const handleSelectChange = () => {
        const url = ref.current[ref?.current?.selectedIndex]?.value;

        if (!inServer && url) {
            window.location.href = url;
        }
    };

    return (
        <>
            <SelectWrapper>
                <Select ref={ref} onChange={handleSelectChange}>
                    {sites.map(site => (
                        <Option key={site.label} selected={site.locale === locale} value={site.to}>
                            {site.label}/{site.currency}
                        </Option>
                    ))}
                </Select>
                <GlobeIcon color={colors.white} />
            </SelectWrapper>
            <SeoLinks>
                {sites.map(site => (
                    <Link key={site.label} to={site.to}>
                        {site.label}
                    </Link>
                ))}
            </SeoLinks>
        </>
    );
};

SiteSelector.propTypes = {
    sites: PropTypes.arrayOf(
        PropTypes.shape({
            currency: PropTypes.string,
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
};

export default SiteSelector;
