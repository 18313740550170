import Basket from 'components/Basket';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { basketProp } from 'utils/proptypes/basket';
import { injectModel } from 'state';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';

const TransitionElement = styled('div', {
    shouldForwardProp: prop => ['clientHeight', 'hasProducts', 'headerHeights'].indexOf(prop) === -1,
})`
    position: absolute;
    top: ${({ headerHeights }) => headerHeights.mobile}px;
    height: ${({ clientHeight, hasProducts, headerHeights }) =>
        hasProducts ? `calc(${clientHeight} - ${headerHeights.mobile}px)` : 'auto'};
    width: 100vw;

    ${above.md} {
        position: fixed;
        right: 0;
        top: ${({ headerHeights }) => headerHeights.desktop}px;
        width: 50vw;
        max-width: 960px;
        height: ${({ clientHeight, hasProducts, headerHeights }) =>
            hasProducts ? `calc(${clientHeight} - ${headerHeights.desktop}px)` : 'auto'};

        &::after {
            display: none;
        }
    }
`;

const BasketOverlay = ({ basket }) => {
    const headerHeights = useHeaderHeights();
    const clientHeight = useClientHeight();

    return (
        <TransitionElement
            className="slide-in right"
            clientHeight={clientHeight}
            hasProducts={basket.items?.length > 0}
            headerHeights={headerHeights}
        >
            <Basket items={basket.items} />
        </TransitionElement>
    );
};

BasketOverlay.propTypes = {
    basket: basketProp.isRequired,
};

export default injectModel('basket')(BasketOverlay);
