import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';

const Menu = ({ color = 'black', height = '24px', viewBox = '0 0 32 24', width = '32px', ...props }) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...props}>
        <Path fill={color} fillRule="evenodd" clipRule="evenodd" d="M32 5.25H0V4H32V5.25Z" />
        <Path fill={color} fillRule="evenodd" clipRule="evenodd" d="M32 12.625H0V11.375H32V12.625Z" />
        <Path fill={color} fillRule="evenodd" clipRule="evenodd" d="M32 20H0V18.75H32V20Z" />
    </SVG>
);

Menu.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Menu;
