export const toSnakeCase = (s: string) => s.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const toKebabCase = (s: string) => s.replace(/\s+/g, '-').toLowerCase().replace(/[åä]/g, 'a').replace(/ö/g, 'o');

export const toCamelCase = (s: string) => s.replace(/([-_ ][a-zåäö])/ig, (letter) => letter.toUpperCase().replace(' ', '').replace('-', '').replace('_', ''));

/**
 * Replace values in a string from a given object
 *
 * @param {string} s - The string that should be transformed
 * @param {string} replace - Object with values to replace
 * 
 * Ex: 
 *  s -> 'No products found when searching for "{searchstring}"'
 *  replace -> { "{searchstring}": 'My given searchstring'  }
 *  returns -> 'No products found when searching for "My given searchstring"'
 */

export const stringReplace = (s: string, replace: Record<string, string>) => {
    Object.keys(replace).forEach(key => {
        const re = new RegExp(key, 'g');
        s = s.replace(re, replace[key]);
    });
    return s;
};

/**
 * Capitalize first letter of a string
 *
 * @param {string} text - The string that should be capitalized
 */

 export const capitalize = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

/**
 * Slice length of string
 *
 * @param {string} text - The string that should be sliced
 * @param {number} maxLength - Max length of string
 * @return {string}
 */

 export const sliceAfterMaxCharacters = (text: string, maxLength: number) => {
    if (text && text.length > maxLength) {
        return text.slice(0, maxLength);
    }
    return text;
};

/**
 * @param {string} text - Any type of sentence or text
 * @param {number} maxCharactersWord - A number that determines the maximum number of letters that can be in a word
 * @param {number} maxCharactersTotal - A number that determines the maximum number of letters that can be in the total text
 * @return {boolean}
 *
 * Example:
 * text = 'A sentence with a word that is too long'
 * maxCharactersWord = 7
 * maxCharactersTotal = 40
 *
 * Returns: true
 */

 export const isAnyWordOrSentenceTooLong = (text: string, maxCharactersWord = 10, maxCharactersTotal = 40): boolean => {
    if (!text) {
        return false;
    }

    // Break out each word from the sentence and place in an array
    const headingAsArray = text.split(' ');

    // Check if the length of the words is longer than maxCharatersWord and return true if any word is too long
    const oneWordIsToLong = headingAsArray.some(word => word.length > maxCharactersWord);

    // Return true if one word is too long or if text is too long. Otherwise return false.
    return text.length > maxCharactersTotal || oneWordIsToLong;
};