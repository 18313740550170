import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundImage from 'components/background/BackgroundImage';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    margin: -8px -6px;
    padding: 0 12px 24px;

    ${above.md} {
        margin: -12px;
    }
`;

const Item = styled('div')`
    padding: 8px 6px;
    cursor: pointer;

    ${above.md} {
        padding: 12px;
    }
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`;

const Images = ({ contentType = '', handleClick, links = [], mediaQuery = [] }) => {
    if (!links.length) {
        return null;
    }

    const grid = contentType === 'imageGrid';

    return (
        <Wrapper flexWrap={grid ? 'wrap' : 'nowrap'} flexDirection={grid ? 'row' : 'column'}>
            {links.map(({ image, label, to }) =>
                label && to && image ? (
                    <Item fontSize="1.6rem" key={image.url} width={grid ? '50%' : '100%'} onClick={() => handleClick()}>
                        <StyledLink key={label} to={to}>
                            {label}
                            <AspectWrapper mt="8px" ratio={4 / 3}>
                                <BackgroundImage
                                    src={image.url}
                                    position={image.backgroundPosition}
                                    size={image.backgroundSize}
                                    query={mediaQuery}
                                />
                            </AspectWrapper>
                        </StyledLink>
                    </Item>
                ) : null
            )}
        </Wrapper>
    );
};

Images.propTypes = {
    contentType: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    mediaQuery: PropTypes.arrayOf(
        PropTypes.exact({
            w: PropTypes.number,
            q: PropTypes.number,
        })
    ).isRequired,
};

export default Images;
