import { headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollHorizontalLayout from 'components/contentLayouts/ScrollHorizontalLayout';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Div = styled('div')``;

const Wrapper = styled('div')`
    position: relative;

    &::before,
    &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0px;
        height: 10px;
        width: 12px;
        background-color: ${colors.background};

        ${above.md} {
            width: 24px;
        }
    }

    &::before {
        left: -12px;

        ${above.md} {
            left: -24px;
        }
    }

    &::after {
        right: -12px;

        ${above.md} {
            right: -24px;
        }
    }
`;

const Line = styled('hr')`
    margin-bottom: 24px;
    border-top: 1px solid ${colors.grey3};
    line-height: 0;
`;

const Content = styled('div')`
    margin-bottom: 32px;

    ${above.sm} {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
`;

const DescriptionWrapper = styled('div')`
    ${above.sm} {
        width: 66.66%;
        padding: 0 12px;
    }

    ${above.md} {
        width: 50%;
    }
`;

const Description = styled('div')`
    font-size: 2.4rem;

    ${above.md} {
        font-size: 3.2rem;
    }
`;

const Tag = styled(Paragraph)`
    display: block;
    margin-bottom: 24px;
`;

const ProductsPromotion = ({ heading, headingTag = 'h2', tag, text, buttons = [], products = [], ...rest }) => (
    <Wrapper {...rest}>
        {heading && (
            <Heading size="lg" as={headingTag} m={['0 0 32px ', null, '0 0 40px', '0 0 56px']}>
                {heading}
            </Heading>
        )}
        {(tag || text || buttons?.length > 0) && (
            <Content>
                {tag && <Tag>{tag}</Tag>}
                {(text || buttons?.length > 0) && (
                    <DescriptionWrapper>
                        {text && (
                            <Description>
                                <Wysiwyg
                                    data={text}
                                    textComponent={Paragraph}
                                    tagComponents={{ url: TextLinkWysisyg }}
                                />
                            </Description>
                        )}
                        {buttons?.length > 0 && <ButtonsList pt="24px" size="md" buttons={buttons} />}
                    </DescriptionWrapper>
                )}
            </Content>
        )}

        <Line />
        <Div width={['calc(100% + 24px)', null, null, 'calc(100% + 48px)']} mx={['-12px', null, null, '-24px']}>
            <ScrollHorizontalLayout
                blocks={products?.map(product => (
                    <ProductCard
                        seeProductButton
                        imageSizes={['75vw', null, '30vw', '33vw', '640px']}
                        key={`${product?.id}-${product?.sku}`}
                        pb="24px"
                        srcWidths={[282, 538, 277, 476, 640]}
                        {...product}
                    />
                ))}
                columnWidth={['75vw', '30vw', 'calc(33.33% - 32px)']}
                gutterWidth={[8, null, 24]}
                overflowPadding={['12px', null, '24px']}
                showDragCursor={[false, null, null, products?.length > 3]}
                width="100%"
            />
        </Div>
        <Div
            display={[
                products.length < 2 ? 'block' : 'none',
                products.length < 3 ? 'block' : 'none',
                products.length < 4 ? 'block' : 'none',
            ]}
        >
            <Line mb="0px" />
        </Div>
    </Wrapper>
);

ProductsPromotion.propTypes = {
    buttons: buttonsProp,
    heading: PropTypes.string,
    headingTag: headingTagProp,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    tag: PropTypes.string,
    text: wysiwygProp,
};

export default ProductsPromotion;
