import React, { useEffect, useRef } from 'react';

import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import { desktopHeaderHeight } from 'hooks/useHeaderHeights';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    position: sticky;
    top: ${desktopHeaderHeight}px;
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid ${colors.grey4};
    border-top: 1px solid ${colors.grey4};
    background-color: ${colors.background};
    transition: all ${transitions.primaryFast};
    z-index: ${zIndex.filterBarDesktop};
    transform: translateY(-1px);

    &.is-sticky {
        border-top: 0px solid ${colors.grey4};
        background-color: ${colors.whiteOpacityHigh};
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
        backdrop-filter: blur(16px);
    }
`;

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled(Paragraph)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const NumberOfProducts = styled(Paragraph)`
    color: ${colors.gray3};
    text-transform: lowercase;
`;

const FilterBarDesktop = ({ openFilterOverlay, hitsCount }) => {
    const { t } = useTranslation();
    const stickyRef = useRef();

    useEffect(() => {
        const stickyElm = stickyRef.current;

        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('is-sticky', e.intersectionRatio === 1),
            { root: document.querySelector('#list-products'), threshold: [1] }
        );

        observer.observe(stickyElm);

        return () => {
            observer.unobserve(stickyElm);
        };
    }, [stickyRef]);

    return (
        <Wrapper ref={stickyRef}>
            <StyledMaxWidthWrapper>
                <Title onClick={() => openFilterOverlay()}>
                    {t('filter_config.filter_and_sort')}
                    <Plus height="16px" width="16px" ml="6px" />
                </Title>
                {hitsCount ? (
                    <NumberOfProducts>{`${hitsCount} ${
                        hitsCount !== 1 ? t('filter_config.products') : t('filter_config.product')
                    }`}</NumberOfProducts>
                ) : null}
            </StyledMaxWidthWrapper>
        </Wrapper>
    );
};

FilterBarDesktop.propTypes = {
    hitsCount: PropTypes.number,
    openFilterOverlay: PropTypes.func.isRequired,
};

export default withRouter(FilterBarDesktop);
