import AspectWrapper from 'components/wrappers/AspectWrapper';
import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const ProductLink = styled(Link)`
    display: block;
    width: 75px;
    height: 100px;
`;

const ProductImage = ({ imageUrl, name, toggleBasket, productLink }) => (
    <ProductLink to={productLink} onClick={() => toggleBasket()}>
        <AspectWrapper ratio={3 / 4} width="75px" overflow="hidden">
            <Image
                alt={name}
                sizes="75px"
                src={{ url: imageUrl, width: [75, 150, 225] }} // Three sizes because of 1, 2 and 3 DPR
                title={name}
                width="75px"
            />
        </AspectWrapper>
    </ProductLink>
);

ProductImage.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productLink: PropTypes.string.isRequired,
    toggleBasket: PropTypes.func.isRequired,
};

export default ProductImage;
