import React, { forwardRef, useEffect, useState } from 'react';

import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import SingleAccordion from 'components/accordions/SingleAccordion';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    margin-top: -1px;
    border-top: 1px solid ${colors.grey4};
    border-bottom: 1px solid ${colors.grey4};
`;

const ContentWrapper = styled('div')`
    width: 100%;
    margin-bottom: 24px;

    ${above.md} {
        width: 50%;
        margin: 0 -12px 24px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    ${above.md} {
        padding: 0 12px;
    }
`;

const Table = styled('table')`
    width: 100%;
    margin: 0;
    padding: 0;
`;

const TableRow = styled('tr')`
    display: flex;
    padding: 16px 0;
    text-align: left;

    ${above.md} {
        padding: 16px 0px 16px 12px;
    }

    :not(:last-of-type) {
        border-bottom: 1px solid ${colors.grey3};
    }
`;

const TableCol = styled('th')`
    width: 50%;
    padding: 0;
`;

const ProductAccordion = forwardRef(
    ({ characteristics, characteristicsLabel, description, descriptionLabel, openAccordions }, ref) => {
        const [activeAccordion, setActiveAccordion] = useState(description ? [0] : []);

        const content = [];

        if (description) {
            content.push({
                label: descriptionLabel,
                Component: (
                    <ContentWrapper>
                        <StyledParagraph dangerouslySetInnerHTML={{ __html: description }} />
                    </ContentWrapper>
                ),
            });
        }

        if (characteristics?.length > 0) {
            content.push({
                label: characteristicsLabel,
                Component: (
                    <ContentWrapper>
                        <Table>
                            <tbody>
                                {characteristics.map(({ label, value }) => (
                                    <TableRow key={`${label}-${value}`}>
                                        <TableCol fontWeight="500">{label}</TableCol>
                                        <TableCol fontWeight="400" textAlign="right">
                                            <Wysiwyg
                                                data={value}
                                                textComponent={props => <Paragraph {...props} />}
                                                tagComponents={{ url: TextLinkWysisyg }}
                                            />
                                        </TableCol>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                    </ContentWrapper>
                ),
            });
        }

        // Opens all accordions when clicking on scrollToAccordions
        useEffect(() => {
            if (openAccordions) {
                setActiveAccordion(content.map((_, index) => index));
            }
        }, [openAccordions]);

        return content.length > 0 ? (
            <Wrapper ref={ref}>
                {content.map(({ label, Component }, index) => (
                    <SingleAccordion
                        maxWithContent
                        animationDuration={200}
                        borderBottom={content.length - 1 !== index}
                        colorText={colors.black}
                        headerPX="0"
                        headerPY={['16px', null, '24px']}
                        headingSize="md"
                        isActive={activeAccordion.includes(index)}
                        key={label}
                        plusSize="24px"
                        text={label}
                        timingFunction="ease"
                        handleClick={() =>
                            setActiveAccordion(
                                activeAccordion.includes(index)
                                    ? activeAccordion.filter(activeIndex => activeIndex !== index)
                                    : [...activeAccordion, index]
                            )
                        }
                    >
                        {Component}
                    </SingleAccordion>
                ))}
            </Wrapper>
        ) : null;
    }
);

ProductAccordion.propTypes = {
    characteristics: PropTypes.arrayOf(
        PropTypes.exact({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        })
    ).isRequired,
    characteristicsLabel: PropTypes.string,
    description: PropTypes.string,
    descriptionLabel: PropTypes.string,
    openAccordions: PropTypes.bool,
};

ProductAccordion.defaultProps = {
    characteristicsLabel: '',
    description: '',
    descriptionLabel: '',
    openAccordions: false,
};

export default ProductAccordion;
