import { closeMenu, openMenu, setActiveMenu } from 'state/models/Header/actions';
import { useDispatch, useSelector } from 'react-redux';

import CloseBig from 'assets/icons/CloseBig';
import Menu from 'assets/icons/Menu';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    cursor: pointer;
    margin: 0 8px;
`;

const MenuButton = () => {
    const dispatch = useDispatch();
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);

    const close = () => {
        dispatch(closeMenu());
    };

    // Automatically open the first menu option
    const open = () => {
        dispatch(openMenu());
        dispatch(setActiveMenu(0));
    };

    return <Wrapper>{menuIsOpen ? <CloseBig onClick={() => close()} /> : <Menu onClick={() => open()} />}</Wrapper>;
};

export default MenuButton;
