import React, { useRef } from 'react';

import BasketWithProducts from 'components/Basket/BasketWithProducts';
import EmptyBasket from 'components/Basket/EmptyBasket';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import { injectModel } from 'state';
import overlayProps from 'utils/proptypes/overlay';
import styled from 'libs/styled';
import { transformProductCardMini } from 'utils/dataTransformers/product';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['clientHeight', 'headerHeights'].indexOf(prop) === -1,
})`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 12px 12px 24px;
    background-color: ${colors.background};

    ${above.lg} {
        padding: 24px;
    }
`;

const Basket = ({ items, overlay }) => {
    const node = useRef();
    const headerHeights = useHeaderHeights();
    const clientHeight = useClientHeight();

    const closeBasket = () => {
        overlay.hide();
    };

    return (
        <Wrapper clientHeight={clientHeight} headerHeights={headerHeights} ref={node}>
            {items?.length > 0 ? (
                <BasketWithProducts closeBasket={closeBasket} products={items.map(transformProductCardMini)} />
            ) : (
                <EmptyBasket closeBasket={closeBasket} />
            )}
        </Wrapper>
    );
};

Basket.propTypes = {
    items: PropTypes.array,
    overlay: overlayProps,
};

export default injectModel('overlay')(Basket);
