import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const TextLink = styled(Link)`
    position: relative;
    display: inline-block;
    line-height: 1;

    &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
    }

    ${media.hover} {
        &:hover::after {
            animation: hoverTextLink ${transitions.primary};
        }
    }

    @keyframes hoverTextLink {
        0% {
            transform: scale3d(1, 1, 1);
            transform-origin: 100% 50%;
        }
        50% {
            transform: scale3d(0, 1, 1);
            transform-origin: 100% 50%;
        }
        50.1% {
            transform: scale3d(0, 1, 1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
        }
    }
`;

// Used to replace the default link when using WYSIWYG
export const TextLinkWysisyg = ({ attrs, children, ...rest }) => (
    <TextLink tabIndex={attrs['tabIndex']} to={attrs['data-url']} {...rest}>
        {children}
    </TextLink>
);

TextLinkWysisyg.propTypes = {
    attrs: PropTypes.object,
};

// Used to replace the default link when using WYSIWYG and forces all links to taget="_black"
export const TextLinkWysisygForceTargetBlank = ({ attrs, children, ...rest }) => (
    <TextLink rel="noopener noreferrer" tabIndex={attrs['tabIndex']} target="_black" to={attrs['data-url']} {...rest}>
        {children}
    </TextLink>
);

TextLinkWysisygForceTargetBlank.propTypes = {
    attrs: PropTypes.object,
};

export default TextLink;
