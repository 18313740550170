import React, { useEffect, useRef } from 'react';

import Arrow from 'assets/icons/Arrow';
import Paragraph from 'components/text/Paragraph';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';

const BannerWrapper = styled('div')`
    display: flex;
    justify-content: center;
    background-color: ${colors.green};
    overflow: hidden;
`;

const Messages = styled('ul', {
    shouldForwardProp: prop => ['animationTime'].indexOf(prop) === -1,
})``;

const Message = styled('li')`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
`;
const Banner = () => {
    const banner = useSelector(state => state.header.data.banner);
    const headerHeights = useHeaderHeights();
    const bannerHeight = `${headerHeights.banner}px`;
    const messagesRef = useRef(null);
    const animationTime = 1000;
    const animationDelay = 5000;

    useEffect(() => {
        let index = 0;
        let loopTimeout;
        let resetTimeOut;

        const clearLoop = () => {
            clearTimeout(loopTimeout);
            clearTimeout(resetTimeOut);
        };

        const initLoop = () => {
            // Clone the first message and add it last to make it look like infinity loop
            messagesRef.current.append(messagesRef.current.children[0].cloneNode([true]));
            triggerLoop();
        };

        const triggerLoop = () => {
            // Add transition and transform to next message
            messagesRef.current.style.transform = `translateY(-${100 * index}%)`;
            messagesRef.current.style.transition = `transform ${animationTime}ms ease`;
            index++;

            // Reset styling and index on the last message
            if (index > banner.length) {
                // Reset to 1 instead of 0 to avoid the first transition due to cloning
                index = 1;

                // Wait for animationTime before reset
                resetTimeOut = setTimeout(() => {
                    messagesRef.current.style.transform = `translateY(0%)`;
                    messagesRef.current.style.transition = `transform 0ms ease`;
                }, animationTime);
            }

            loopTimeout = setTimeout(triggerLoop, animationTime + animationDelay);
        };

        if (banner.length > 1) {
            initLoop();
        }

        return () => {
            clearLoop();
        };
    }, [messagesRef]);

    if (!banner.length) {
        return null;
    }

    return (
        <BannerWrapper height={bannerHeight}>
            <Messages ref={messagesRef} animationTime={animationTime}>
                {banner.map(({ message, showArrow }, index) => (
                    <Message key={index} height={bannerHeight}>
                        <Wysiwyg data={message} textComponent={Paragraph} tagComponents={{ url: TextLinkWysisyg }} />
                        {showArrow && <Arrow color="currentColor" ml="8px" />}
                    </Message>
                ))}
            </Messages>
        </BannerWrapper>
    );
};

export default Banner;
