import TextLink, { TextLinkWysisyg } from 'components/text/TextLink';
import { linkProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import FooterAccordion from 'components/accordions/FooterAccordion';
import Newsletter from 'components/Newsletter';
import PropTypes from 'prop-types';
import React from 'react';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import colors from 'config/theme/colors';
import contentMargins from 'config/theme/contentMargins';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Row = styled('div')`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -12px;
    color: ${colors.white};
`;

const Col = styled('div')`
    padding: 0px 12px;
    line-height: 1.2;
`;

const ContentWrapper = styled('ul')`
    padding-top: 8px;
    padding-bottom: 24px;
`;

const LinkWrapper = styled('li')`
    :not(:last-of-type) {
        margin-bottom: 12px;
    }
`;

const Address = styled('address')`
    font-style: normal;
`;

const Text = styled('p')`
    margin-bottom: 8px;
`;

const FooterContentMobile = ({ textarea, newsletter = {}, address, contact, menu }) => {
    const { t } = useTranslation();

    const accordionContent = [
        {
            label: t('footer.newsletter'),
            Component: (
                <ContentWrapper as="div" px={contentMargins}>
                    <Newsletter theme="white" {...newsletter} />
                </ContentWrapper>
            ),
        },
    ];

    if (address || contact) {
        accordionContent.push({
            label: t('footer.address'),
            Component: (
                <ContentWrapper as={Address} px={contentMargins}>
                    {address && (
                        <Wysiwyg
                            data={address}
                            textComponent={props => <Text {...props} />}
                            tagComponents={{ url: TextLinkWysisyg }}
                        />
                    )}
                    {contact && (
                        <Wysiwyg
                            data={contact}
                            textComponent={props => <Text {...props} />}
                            tagComponents={{ url: TextLinkWysisyg }}
                        />
                    )}
                </ContentWrapper>
            ),
        });
    }

    menu.forEach(menuItem => {
        accordionContent.push({
            label: menuItem.label,
            Component: (
                <ContentWrapper px={contentMargins}>
                    {menuItem.links.map(link => (
                        <LinkWrapper key={`${link.to}-${link.label}`}>
                            <TextLink to={link.to}>{link.label}</TextLink>
                        </LinkWrapper>
                    ))}
                </ContentWrapper>
            ),
        });
    });

    return (
        <Row>
            <Col width={['100%', null, '50%']} mb="56px">
                {textarea?.text && <Wysiwyg data={textarea.text} tagComponents={{ url: TextLinkWysisyg }} />}
                {textarea?.link && (
                    <>
                        <TextLink mt="8px" to={textarea.link.to}>
                            {textarea.link.label}
                        </TextLink>
                    </>
                )}
            </Col>
            <Col width="100%">
                <FooterAccordion content={accordionContent} />
            </Col>
        </Row>
    );
};

FooterContentMobile.propTypes = {
    address: wysiwygProp,
    contact: wysiwygProp,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.arrayOf(linkProp),
        })
    ),
    newsletter: PropTypes.object,
    textarea: PropTypes.shape({
        text: wysiwygProp,
        link: linkProp,
    }),
};

export default FooterContentMobile;
