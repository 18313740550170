import BaseLink from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Button = styled('button', {
    shouldForwardProp: prop => ['current'].indexOf(prop) === -1,
})`
    display: flex;
    align-items: center;

    margin: 0 16px 0 0;

    ${above.md} {
        margin: 0 32px 8px 0;
    }

    :hover {
        color: ${colors.black};
    }

    ${({ current }) =>
        current &&
        `
        margin-top: -1px;
        
        ::before  {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 0.8rem;
            border-radius: 50%;
            background: black;

            ${above.md} {
                margin-right: 1.6rem;
                width: 16px;
                height: 16px;
            }
        }
    `}
`;

const SubCategoryItem = ({ as, fontSize = ['3.6rem', null, null, '6.4rem'], current = false, title, to, ...rest }) => {
    let renderAs = undefined;
    if (as) {
        renderAs = as;
    } else if (typeof to === 'string') {
        renderAs = BaseLink;
    }

    return (
        <Button
            as={renderAs}
            current={current}
            fontSize={fontSize}
            color={current ? colors.black : colors.grey6}
            to={to}
            {...rest}
        >
            {title}
        </Button>
    );
};

SubCategoryItem.propTypes = {
    as: PropTypes.string,
    current: PropTypes.bool,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
};

export default SubCategoryItem;
