import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { closeMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import { useDispatch } from 'react-redux';

const Wrapper = styled('div')`
    padding: 24px 12px;
    margin: -6px 0;
`;

const StyledLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;

    ${above.md} {
        padding: 4px 0;
    }
`;

const Featured = ({ featuredLinks = [] }) => {
    const dispatch = useDispatch();

    if (!featuredLinks.length) {
        return null;
    }

    return (
        <Wrapper>
            {featuredLinks.map(({ label, to }) =>
                label && to ? (
                    <StyledLink volatile key={label} to={to} onClick={() => dispatch(closeMenu())}>
                        <Heading as="h3">{label}</Heading>
                    </StyledLink>
                ) : null
            )}
        </Wrapper>
    );
};

Featured.propTypes = {
    featuredLinks: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, to: PropTypes.string })),
};

export default Featured;
