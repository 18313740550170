/**
 * Add new colors from design to the palette object
 * Add color name into to ColorType
 * Customize as needed
 */

const palette: {
    black: string[];
    blue: string[];
    brown: string[];
    green: string[];
    grey: string[];
    neutral: string[];
    silver: string[];
    white: string[];
} = {
    black: ['#000000', 'rgba(0,0,0,0.6)', 'rgba(0,0,0,0.3)'], // Standard, 60%, 30%,
    blue: ['#1E232D', '#D2E1F0', '#1301FF'], // Brand, Brand Light, Error
    brown: ['#463C23'], // Brand
    green: ['#1E3219', '#354730'], // Brand, Brand Tint
    grey: ['#F6F6F6', '#ECECEC', '#CFCFCF', '#7B7B7B', '#4B4B4B', '#717171'], // 01, 02, 03, 04, 05, Text color grey
    neutral: ['#F9F9F9', '#C8C3C3', '#D2C8B9', '#C3C3B9'], // White, Sand, Warm Orange, Green
    silver: ['#8C9191'], // Brand
    white: ['#FFFFFF', 'rgba(255,255,255,0.6)', 'rgba(255,255,255,0.3)'], // Standard, 60%, 30%,
};

export interface ColorType {
    background: string;
    black: string;
    blackOpacityHigh: string;
    blackOpacityLow: string;
    blue: string;
    blueLight: string;
    brown: string;
    error: string;
    errorSecondary: string;
    green: string;
    greenTint: string;
    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    grey5: string;
    grey6: string;
    neutralGreen: string;
    neutralWarmOrange: string;
    neuturalSand: string;
    neuturalWhite: string;
    silver: string;
    success: string;
    white: string;
    whiteOpacityHigh: string;
    whiteOpacityLow: string;
}

const colors: ColorType = {
    background: palette.neutral[0],
    black: palette.black[0],
    blackOpacityHigh: palette.black[2],
    blackOpacityLow: palette.black[1],
    blue: palette.blue[0],
    blueLight: palette.blue[1],
    brown: palette.brown[0],
    error: palette.blue[2],
    errorSecondary: palette.white[0],
    green: palette.green[0],
    greenTint: palette.green[1],
    grey1: palette.grey[0],
    grey2: palette.grey[1],
    grey3: palette.grey[2],
    grey4: palette.grey[3],
    grey5: palette.grey[4],
    grey6: palette.grey[5],
    neutralGreen: palette.neutral[3],
    neutralWarmOrange: palette.neutral[2],
    neuturalSand: palette.neutral[1],
    neuturalWhite: palette.neutral[0],
    silver: palette.silver[0],
    success: palette.green[0],
    white: palette.white[0],
    whiteOpacityHigh: palette.white[2],
    whiteOpacityLow: palette.white[1],
};

export default colors;
