/* eslint-disable camelcase */
import React, { Fragment } from 'react';

import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import createOgTags from 'components/metadata/utils/createOgTags';
import createTwitterTags from 'components/metadata/utils/createTwitterTags';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import formatMetaDescription from 'components/metadata/utils/formatMetaDescription';
import { injectModel } from 'state';
import suffix from 'components/metadata/utils/suffix';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * ProductMeta for Orrefors
 * @version 1.1
 *
 * ProductMeta
 * @param {object} product - product object build up the meta. Here it may need some kind of tweaking, the custom_attributes dosent always match, neither is the data in generall (not 100%).
 * @param {string} domain - application.domain, current domain
 * @param {string} path - application.path, current path
 * @param {string} protocol - application.protocol string. always https
 * @param {object} page -  Only using the hrefs list from the page. Stepping over it to access and display the hreflangs
 * @param {external func} formatMetaDescription - This will handle the description if its to long. It will clear it from html and bbcode elements. also if > 151 it will add '...'
 * @param {external func} getHrefLang - this is handling the config for the setup of translation of the hreflangs
 * @param {external func} decodeHTMLEntities - Decoding if we get any special characters in any other format then wanted. Please see function file for more information
 * @param {external func} createOgTags - Creates the necessary og tags passed down to it
 * @param {external func} createHrefLangs - Creates the hreflang meta tags
 * @param {external func} createTwitterTags - Creates the necessary twitter tags passed down to it
 */

const ProductMeta = React.memo(
    ({ transformedProduct }) => {
        const { t } = useTranslation();

        const application = useSelector(
            state => state.application,
            (prev, next) =>
                prev.application?.domain !== next.application?.domain ||
                prev.application?.protocol !== next.application?.protocol ||
                prev.application?.path !== next.application?.path
        );

        const page = useSelector(
            state => state.page,
            (prev, next) => prev.page !== next.page
        );

        const { domain, protocol, path } = application;
        const { data: product } = page;
        const seoObject = product.seo || {};

        // Setup base url
        const protocolPath = protocol ? `${protocol}://` : '';
        const localePath = path || '';
        const baseUrl = protocolPath + domain || '';

        // Setup productname as title
        const productName = product.name || null;

        // Setup Type and canonical
        const canonical = seoObject.canonical_uri || product.uri ? `${baseUrl + localePath + product.uri}` : null;

        // Setup title and include the product variations
        const title = `${seoObject.title || decodeHTMLEntities(productName)}${suffix}`;

        const description = seoObject.description
            ? formatMetaDescription(product.seo.description)
            : t('utils.seo_description_fallback');

        // If comapny have ex. last image as a enviroment image use: media[media.length - 1] instead
        const media = transformedProduct.desktopHero;

        // Setup OG object data for tags
        const ogData = {
            title,
            description,
            media,
            price: product.price,
            variations: product.variations,
            ogType: 'Product',
        };

        // Setup twitter object data for tags
        const twitterData = {
            description,
            title,
            media,
        };

        // Create ogTags (facebook) 1200 x 630
        const ogTags = createOgTags(ogData).props.children || [];

        // Create twitter tags 1200px X 675
        const twitterTags = createTwitterTags(twitterData).props.children || [];

        return (
            <Fragment>
                <Helmet>
                    {title && <title>{title}</title>}
                    {canonical && <link rel="canonical" href={canonical} />}
                    {description && <meta name="description" content={description} />}

                    {twitterTags.map(tag => tag)}
                    {ogTags.map(tag => tag)}
                </Helmet>
            </Fragment>
        );
    },
    // Use React.memo() to only re-render if product id && selectedSize changes.
    (prevProps, nextProps) => prevProps.product?.id === nextProps.product?.id
);

ProductMeta.propTypes = {
    transformedProduct: PropTypes.object.isRequired,
};

export default injectModel('page')(ProductMeta);
