import PropTypes from 'prop-types';
import React from 'react';
import Rect from 'assets/icons/base/Rect';
import SVG from 'assets/icons/base/SVG';

const MouseSmall = ({ color = 'black', ...props }) => (
    <SVG height="53px" viewBox="0 0 26 53" width="26px" fill="none" {...props}>
        <Rect x="1.5" y="1.5" width="23px" height="50px" rx="11.5" stroke={color} strokeOpacity="0.3" />
    </SVG>
);

MouseSmall.propTypes = {
    color: PropTypes.string,
};

export default MouseSmall;
