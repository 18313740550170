import React, { useEffect } from 'react';

import CloseBig from 'assets/icons/CloseBig';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import { inServer } from 'config/constants';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';

const TransitionElement = styled('div', { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    position: fixed;
    top: ${({ headerHeights }) => headerHeights.mobile + headerHeights.banner}px;
    right: 0;
    width: 100%;
    overflow: auto;
    background-color: ${colors.neuturalWhite};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);

    ${above.md} {
        top: ${({ headerHeights }) => headerHeights.desktop + headerHeights.banner}px;
        width: 50vw;
        max-width: 960px;
    }
`;

const Content = styled('div')`
    border-width: 4px 0px 4px 0px;
    border-style: solid;
    padding: 24px 45px 24px 12px;

    ${above.md} {
        border-width: 0px 0px 0px 4px;
        padding: 24px 60px 24px 24px;
    }
`;

const Icon = styled('div')`
    position: absolute;
    top: 16px;
    right: 12px;

    ${above.md} {
        top: 24px;
        right: 24px;
    }
`;

const NoticePopup = () => {
    const { notice, popups } = useSelector(
        state => state,
        (prev, next) => prev.notice?.data !== next.notice?.data || prev.popups !== next.popups
    );

    const headerHeights = useHeaderHeights();

    const closePopup = () => {
        popups.hide('noticePopup');
    };

    const startTimer = () => {
        if (notice.data) {
            window.timer = setTimeout(() => {
                closePopup();
            }, 10000);
        }
    };

    const stopTimer = () => {
        if (!inServer) {
            clearTimeout(window.timer);
        }
    };

    useEffect(() => {
        if (notice.data) {
            startTimer();
        }
        return () => {
            if (!inServer) {
                clearTimeout(window.timer);
            }
        };
    }, [notice]);

    let borderColor = 'none';
    switch (notice.data?.type) {
        case 'error':
            borderColor = colors.error;
            break;

        case 'success':
            borderColor = colors.success;
            break;

        default:
            break;
    }

    return (
        <TransitionElement
            className="slide-in right"
            headerHeights={headerHeights}
            onMouseEnter={() => stopTimer()}
            onMouseLeave={() => startTimer()}
        >
            <Content borderColor={borderColor}>
                {notice.data?.title && (
                    <Heading mb="12px" size="xs">
                        {notice.data.title}
                    </Heading>
                )}
                {notice.data?.message && <Paragraph size="sm">{notice.data.message}</Paragraph>}
                <Icon>
                    <CloseBig cursor="pointer" onClick={closePopup} />
                </Icon>
            </Content>
        </TransitionElement>
    );
};

export default NoticePopup;
