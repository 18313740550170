import { alignItemsProp, justifyCotnentProp } from 'utils/proptypes/modules/styleProps';

import Column from 'components/contentLayouts/ScrollHorizontalLayout/Column';
import Drag from 'assets/icons/cursors/drag.svg';
import PropTypes from 'prop-types';
import React from 'react';
import Row from 'components/contentLayouts/ScrollHorizontalLayout/Row';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import { asArray } from 'utils/array';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    * {
        cursor: inherit;
    }
`;

/**
 * Fill a scrollable slider with components.
 * Use overflowPadding together with a BleedWrapper to make the content fit to a grid but stil scroll the full viewport.
 *
 * @param {string} alignItems - Flexbox attribute to align items verticaly.
 * @param {node[]} blocks - An array of JSX components to render inside each column.
 * @param {string||string[]} columnWidth - The width of each column as a string. Can accepted an array of responsive values.
 * @param {number||number[]} gutterWidth - The distance between each column. The number will be interpreted as "px".
 * @param {string} heading - Heading as a string.
 * @param {string} headingTag - The html-tag used by heading; h1, h2, h3 etc.
 * @param {string} justifyContent - Flexbox attribute to justify content horizontally.
 * @param {string||string[]} overflowPadding - Add extra padding at the begining and end of the slider. Can accepted an array of responsive values.
 */

const ScrollHorizontalLayout = ({
    alignItems = 'stretch',
    blocks = [],
    columnWidth = '25vw',
    contentPosition,
    gutterWidth = 0,
    handleUserSwiping = true,
    justifyContent = 'flex-start',
    overflowPadding,
    showDragCursor = false,
    width,
}) => {
    // Rewrites for ex. [false, null, true] to ['default', null, `url(${Drag}) 40 40, pointer`]
    // and is then used as responsive cursor prop
    const showDragCursorArray = asArray(showDragCursor);
    const finalCursorArray = showDragCursorArray.map(value => {
        if (value === true) {
            return `url(${Drag}) 40 40, pointer`;
        } else if (value === false) {
            return 'default';
        }
        return null;
    });

    const margin = contentPosition === 'center' ? '0 auto' : contentPosition === 'right' ? '0 0 0 auto' : null;

    return (
        <Wrapper cursor={finalCursorArray}>
            <ScrollWrapper displayScrollbar direction="horizontal" disableClickDrag={!handleUserSwiping}>
                <Row
                    overflowPadding={overflowPadding}
                    alignItems={alignItems}
                    justifyContent={justifyContent}
                    width={width}
                    margin={margin}
                >
                    {blocks.map(
                        (Component, i) =>
                            Component && (
                                <Column
                                    colWidth={columnWidth} // Rename prop to avoid collision with styled
                                    gutterWidth={gutterWidth}
                                    key={`${i}-${Component?.key}`}
                                >
                                    {Component}
                                </Column>
                            )
                    )}
                </Row>
            </ScrollWrapper>
        </Wrapper>
    );
};

ScrollHorizontalLayout.propTypes = {
    alignItems: alignItemsProp,
    blocks: PropTypes.arrayOf(PropTypes.node),
    columnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    contentPosition: PropTypes.string,
    gutterWidth: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    handleUserSwiping: PropTypes.bool,
    justifyContent: justifyCotnentProp,
    overflowPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    showDragCursor: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default ScrollHorizontalLayout;
