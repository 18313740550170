/*
 * NOTE: use this file to setup your projects overall transitions.
 * Customize as your design is.
 */
export interface TransitionType {
    primary: string;
    primaryFast: string;
    primarySlow: string;
    secondary: string;
    secondaryFast: string;
    secondarySlow: string;
}

const transitions: TransitionType = {
    primary: '800ms cubic-bezier(0.19, 1, 0.22, 1)',
    primaryFast: '500ms cubic-bezier(0.19, 1, 0.22, 1)',
    primarySlow: '1000ms cubic-bezier(0.19, 1, 0.22, 1)',
    secondary: '400ms ease',
    secondaryFast: '200ms ease',
    secondarySlow: '500ms ease',
};

export default transitions;
