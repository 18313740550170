import React, { useEffect } from 'react';

import AddedToBasketItem from 'components/products/productCardMini/AddedToBasket';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import { inServer } from 'config/constants';
import popupProps from 'utils/proptypes/popup';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const TransitionElement = styled('div', { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    position: fixed;
    top: ${({ headerHeights }) => headerHeights.mobile + headerHeights.banner}px;
    right: 0;
    width: 100%;
    padding: 16px 12px;
    overflow: auto;
    background-color: ${colors.neuturalWhite};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);

    ${above.md} {
        top: ${({ headerHeights }) => headerHeights.desktop + headerHeights.banner}px;
        width: 50vw;
        max-width: 960px;
        padding: 24px;
    }
`;

const AddedToBasketPopup = () => {
    const { latestAddedProduct, overlay, popups } = useSelector(
        state => state,
        (prev, next) =>
            prev.latestAddedProduct !== next.latestAddedProduct ||
            prev.overlay !== next.overlay ||
            prev.popups !== next.popups
    );

    const headerHeights = useHeaderHeights();

    const openBasket = () => {
        const openOverlay = overlay.current;

        if (openOverlay !== 'basketOverlay') {
            overlay.show('basketOverlay', {
                transparent: false,
                zIndex: zIndex.basket,
                isUpsell: false,
            });
        }
    };

    const closePopup = () => {
        popups.hide('addedToBasketPopup');
    };

    const startTimer = () => {
        if (!inServer && latestAddedProduct.product !== null) {
            window.timer = setTimeout(() => {
                closePopup();
            }, 3000);
        }
    };

    const stopTimer = () => {
        if (!inServer) {
            clearTimeout(window.timer);
        }
    };

    useEffect(() => {
        if (latestAddedProduct) {
            startTimer();
        }
        return () => {
            if (!inServer) {
                clearTimeout(window.timer);
            }
        };
    }, [latestAddedProduct]);

    return (
        <TransitionElement
            className="slide-in right"
            headerHeights={headerHeights}
            onMouseEnter={() => stopTimer()}
            onMouseLeave={() => startTimer()}
        >
            <AddedToBasketItem
                {...latestAddedProduct.product}
                closePopup={() => closePopup()}
                openBasket={() => openBasket()}
            />
        </TransitionElement>
    );
};

AddedToBasketPopup.propTypes = {
    popups: popupProps.isRequired,
};

export default AddedToBasketPopup;
