import React, { useEffect, useRef } from 'react';
import { above, below } from 'utils/mediaqueries';
import { useDispatch, useSelector } from 'react-redux';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import MenuDesktop from './Desktop';
import MenuMobile from './Mobile';
import { closeMenu } from 'state/models/Header/actions';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useClientHeight from 'hooks/useClientHeight';
import { useLocation } from 'react-router';
import zIndex from 'config/theme/z-index';
import zoomOutCursorWhite from 'assets/icons/cursors/zoom-out-white.svg';

const Wrapper = styled('nav', { shouldForwardProp: prop => ['menuIsOpen'].indexOf(prop) === -1 })`
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: ${zIndex.menu};
    transition: max-width ${transitions.primary}, transform ${transitions.primary};
    transform: translateX(${({ menuIsOpen }) => (menuIsOpen ? '0' : '-100%')});

    ${below.md} {
        // Used to prevent a height 100vh bug on android phones
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 200px;
            background-color: ${colors.background};
        }
    }
`;

const StyledMaxWidthWrapper = styled(MaxWidthWrapper, {
    shouldForwardProp: prop => ['clientHeight'].indexOf(prop) === -1,
})`
    position: relative;
    height: 100%;

    // White background, looks like the menu
    &::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: ${({ clientHeight }) => clientHeight};
        background-color: ${colors.background};
        z-index: -1;

        ${above.md} {
            right: 25%; /* 3 / 12 */
            left: auto;
        }
        ${above.lg} {
            right: 41.66%; /* 5 / 12 */
        }
    }
`;

const BackDrop = styled('div', { shouldForwardProp: prop => ['menuIsOpen'].indexOf(prop) === -1 })`
    display: none;

    ${above.md} {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        width: 158.33vw;
        background: ${colors.blackOpacityHigh};
        visibility: ${({ menuIsOpen }) => (menuIsOpen ? 'visible' : 'hidden')};
        z-index: ${zIndex.menu - 1};
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
        backdrop-filter: blur(16px);
        transition: all ${transitions.primary};
        opacity: ${({ menuIsOpen }) => (menuIsOpen ? 1 : 0)};
    }
`;

const CloseMenuArea = styled('div', { shouldForwardProp: prop => ['menuIsOpen'].indexOf(prop) === -1 })`
    position: absolute;
    top: 0;
    left: 75%; /* 9 / 12 */
    width: 75vw; /* 9 / 12 */
    height: 100%;
    z-index: ${zIndex.menu + 1};
    visibility: ${({ menuIsOpen }) => (menuIsOpen ? 'visible' : 'hidden')};
    cursor: url('${zoomOutCursorWhite}') 20 20, pointer;

    ${above.lg}{
        left: 58.33%; /* 7 / 12 */
        width: 58.33%; /* 7 / 12 */
    }
`;

const Menu = () => {
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const hideOverlay = useSelector(state => state.overlay.hide);
    const clientHeight = useClientHeight();
    const dispatch = useDispatch();
    const location = useLocation();

    const handleCloseMenu = () => {
        dispatch(closeMenu());
    };

    //Sets previous location-history
    const prevLocationRef = useRef(location.pathname);

    //Checks if the new targeted location matches the old one. If it doesn't, it closes the menu.
    useEffect(() => {
        if (location.pathname !== prevLocationRef.current) {
            handleCloseMenu();
        }
    }, [location]);

    useEffect(() => {
        if (menuIsOpen) {
            hideOverlay();
        }
    }, [menuIsOpen]);

    return (
        <>
            <Wrapper menuIsOpen={menuIsOpen} height={clientHeight}>
                <Below breakpoint="md" render={() => <MenuMobile />} />
                <Above
                    breakpoint="md"
                    render={() => (
                        <StyledMaxWidthWrapper includeContentMargins clientHeight={clientHeight}>
                            <MenuDesktop />
                            <CloseMenuArea menuIsOpen={menuIsOpen} onClick={() => handleCloseMenu()} />
                        </StyledMaxWidthWrapper>
                    )}
                />
            </Wrapper>
            <BackDrop menuIsOpen={menuIsOpen} height={clientHeight} />
        </>
    );
};

export default Menu;
