import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';

// Global font-size and line-height is set in components/styles/global.css
const StyledHeading = styled('h2', {
    shouldForwardProp: prop => ['size'].indexOf(prop) === -1,
})`
    letter-spacing: -0.01em;

    ${props =>
        props.size === 'xs' &&
        `
		font-size: 1.6rem;

        ${above.md}{
			font-size: 1.8rem;
        }
    `}

    ${props =>
        props.size === 'sm' &&
        `
		font-size: 2.4rem;
    `}

    ${props =>
        props.size === 'md' &&
        `
		font-size: 2.4rem;

		${above.md}{
			font-size: 3.2rem;
        }
		
    `}

    ${props =>
        props.size === 'lg' &&
        `
		font-size: 3.6rem;

		${above.md}{
			font-size: 6.4rem;
        }
		
    `}
`;

const Heading = ({ size = 'md', children, ...rest }) =>
    typeof children === 'string' ? (
        <StyledHeading size={size} {...rest} dangerouslySetInnerHTML={{ __html: children }} />
    ) : (
        <StyledHeading size={size} {...rest}>
            {children}
        </StyledHeading>
    );

Heading.propTypes = {
    size: PropTypes.string,
};

export default Heading;
