import FilterAccordion from 'components/accordions/FilterAccordion';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import SortOptions from 'components/Filter/SortOptions';
import TextLink from 'components/text/TextLink';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import { injectModel } from 'state';
import overlayProps from 'utils/proptypes/overlay';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

const FilterHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    padding: 16px 12px;
    flex-shrink: 0; /* Fix for safari iPhone */
    border-bottom: 1px solid ${colors.grey4};
    background-color: ${colors.background};

    @supports (position: sticky) {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 1;
    }

    ${above.md} {
        padding: 20px 24px;
    }
`;

const FilterFooter = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 12px;

    ${above.md} {
        padding: 24px;
    }
`;

const NoResultText = styled('span')`
    text-align: center;
    font-size: 1.4rem;
    color: ${colors.grey6};
    margin-top: 8px;
`;

const Filter = ({ overlay, className, filters = [], handleChange = {}, clear = {}, hitsCount, sorts }) => {
    const { t } = useTranslation();
    const clientHeight = useClientHeight();

    return (
        <Wrapper className={className} height={clientHeight}>
            <FilterHeader>
                <Heading as="h3" fontSize="1.6rem">
                    {t('filter_config.filter_and_sort')}
                </Heading>
                <TextLink as="button" fontSize="1.8rem" onClick={() => overlay.hide()}>
                    {t('filter_config.close')}
                </TextLink>
            </FilterHeader>
            <SortOptions sorts={sorts} handleChange={handleChange.sort} />
            <FilterAccordion filters={filters} handleChange={handleChange.filter?.value} />
            <FilterFooter>
                <ThemeButton mb="8px" size="lg" theme="outlinedBlack" onClick={() => clear.filter()}>
                    {t('filter_config.clear_filter')}
                </ThemeButton>
                <ThemeButton size="lg" theme="black" onClick={() => overlay.hide()}>
                    {t('filter_config.show_results')} {`(${hitsCount})`}
                </ThemeButton>
                {hitsCount < 1 && <NoResultText>{t('filter_config.no_results_with_these_filters')}</NoResultText>}
            </FilterFooter>
        </Wrapper>
    );
};

Filter.propTypes = {
    className: PropTypes.string,
    clear: PropTypes.object,
    filters: PropTypes.arrayOf(
        PropTypes.exact({
            key: PropTypes.string,
            label: PropTypes.string,
            stats: PropTypes.object,
            totalSelected: PropTypes.number,
            values: PropTypes.array,
        })
    ),
    handleChange: PropTypes.shape({
        filter: PropTypes.shape({
            value: PropTypes.func,
            range: PropTypes.func,
        }),
        sort: PropTypes.func,
    }),
    hitsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    overlay: overlayProps.isRequired,
    sorts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default injectModel('overlay')(Filter);
