/**
 * Takes a button from the cms and renames/restructures the data so that it is easier to work with in the front-end.
 * All front-end components should expect the button to first pass through this function.
 * All buttons in the cms should be able to pass through this function.
 * @param {object} button - Button data straight from the cms.
 * @param {object} options - Options passed to button transformer.
 * @param {object} - Transformed button data.
 */

export default (button, options = {}) => {
    // Just return false if we don't have any data
    if (!button) {
        return undefined;
    }

    // If button is a nested object
    button = button.button || button;

    // Theme can be named differently depending on where the data comes from
    const theme = button.theme || button.button_theme || button.buttonTheme || button.theme;

    // The color theme should be camelCased
    const colorMap = {
        'transparent_white': 'outlinedWhite',
        'transparent_black': 'outlinedBlack',
    };

    // Return the transformed data.
    return {
        theme: options.theme || colorMap[theme] || theme,
        label: options.label || button.label,
        to: button.link || null,
    };
};
