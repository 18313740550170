import ColorDotWithText from 'components/Filter/ColorDotWithText';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Filter = styled('div')`
    cursor: pointer;
`;

const FilterColorOption = ({ filters, handleChange, parentFilterKey }) =>
    filters.map(item => (
        <Filter key={item.value} onClick={() => handleChange(parentFilterKey, item.value)}>
            <ColorDotWithText
                image={item.image}
                isActive={item.selected}
                text={item.value}
                useOutline={item.useOutline}
            />
        </Filter>
    ));

FilterColorOption.propTypes = {
    filters: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, image: PropTypes.string, selected: PropTypes.string })
    ).isRequired,
    handleChange: PropTypes.func.isRequired,
    parentFilterKey: PropTypes.string.isRequired,
};

export default FilterColorOption;
