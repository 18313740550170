import { above, below } from 'utils/mediaqueries';

import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { transformProductCard } from 'utils/dataTransformers/product';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(MaxWidthWrapper)`
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 24px;
    overflow: auto;
    border-top: 1px solid ${colors.grey4};

    ${above.md} {
        flex-direction: row;
        padding-top: 56px;
        overflow: visible;
    }
`;

const CategoryCol = styled('div')`
    ${below.md} {
        &.no-category-result {
            display: none;
        }
    }

    ${above.md} {
        flex-grow: 0;
        flex-shrink: 0;
        width: 33.33%;
        padding-right: 12px;
    }
`;

const CategoryList = styled('ul')`
    position: relative;
    padding-bottom: 24px;
    margin-bottom: 24px;

    ${below.md} {
        ::after {
            display: block;
            content: '';
            position: absolute;
            bottom: 0px;
            left: 50%;
            width: 100vw;
            border-bottom: 1px solid ${colors.grey4};
            transform: translateX(-50%);
        }
    }
`;

const CategoryItem = styled('li')`
    margin-top: 8px;
    font-size: 2.4rem;

    ${above.md} {
        margin-top: 12px;
        font-size: 3.2rem;
    }
`;

const ProductCol = styled('div')`
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    padding-bottom: 80px;
    margin-left: -4px;
    margin-right: -4px;
    z-index: 1;

    ${above.sm} {
        margin-left: 0;
        margin-right: 0;
    }

    ${above.md} {
        overflow: auto;
        flex-grow: 0;
        flex-shrink: 0;
        max-height: 100%;
        width: 66.66%;
        padding-left: 12px;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    ${below.md} {
        ::after {
            display: block;
            content: '';
            position: fixed;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 80px;
            background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            z-index: 2;
        }
    }
`;

const ProductList = styled('ul')`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${above.sm} {
        justify-content: flex-start;
    }

    ${above.md} {
        justify-content: space-between;
    }
`;

const ProductItem = styled('li')`
    width: calc(50% - 4px);
    margin-bottom: 32px;

    ${above.sm} {
        width: calc(33.33% - 8px);
        :not(:nth-of-type(3n + 3)) {
            margin-right: 12px;
        }
    }

    ${above.md} {
        width: calc(50% - 12px);
        :not(:nth-of-type(3n + 3)) {
            margin-right: 0px;
        }
    }
`;

const ViewAllButton = styled(ThemeButton)`
    position: fixed;
    bottom: 32px;
    width: calc(100% - 24px);
    z-index: 3;

    ${above.md} {
        position: absolute;
        width: calc(25% - 24px);
    }
`;

const SearchContent = ({ products = [], categories = [], hitsCount = 0, searchString }) => {
    const { t } = useTranslation();
    const hideOverlay = useSelector(state => state.overlay.hide);
    const marketId = useSelector(state => state.application.shop_config.market_id);
    const searchPage = useSelector(state => state.application.config.options.specified_pages.search_results.slug);
    const headerHeights = useHeaderHeights();
    const clientHeight = useClientHeight();

    return (
        <Wrapper
            includeContentMargins
            height={[
                `calc(${clientHeight} - ${headerHeights.mobile + 55}px)`,
                null,
                null,
                `calc(${clientHeight} - ${headerHeights.desktop + 124}px)`,
            ]}
        >
            <CategoryCol className={categories.length <= 0 && 'no-category-result'}>
                <Heading size="md" color={colors.grey4}>
                    {t('search.categories')}
                </Heading>
                {categories.length > 0 ? (
                    <CategoryList>
                        {categories.map((category, i) =>
                            i < 5 ? (
                                <CategoryItem key={category.uri}>
                                    <Link to={category.uri} onClick={hideOverlay}>
                                        {category.title}
                                    </Link>
                                </CategoryItem>
                            ) : null
                        )}
                    </CategoryList>
                ) : (
                    <CategoryList>
                        <CategoryItem color={colors.error} fontSize="1.8rem !important">
                            {t('search.no_categories_found')}
                        </CategoryItem>
                    </CategoryList>
                )}
            </CategoryCol>
            <ProductCol>
                <ProductList>
                    {products.map(product => {
                        const transformedProduct = transformProductCard(product, marketId, t);
                        return (
                            <ProductItem key={`${transformedProduct.sku}_${transformedProduct.uri}`}>
                                <ProductCard {...transformedProduct} onClick={hideOverlay} />
                            </ProductItem>
                        );
                    })}
                </ProductList>
            </ProductCol>
            <ViewAllButton to={`${searchPage}?s=${encodeURI(searchString)}`} theme="black" onClick={hideOverlay}>
                {t('search.view_all')} ({hitsCount})
            </ViewAllButton>
        </Wrapper>
    );
};

SearchContent.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object),
    hitsCount: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.object),
    searchString: PropTypes.string,
};

export default SearchContent;
