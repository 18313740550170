import { backgroundTypeProp, imageProp, videoProp } from 'utils/proptypes/modules/mediaProps';

import BackgroundColor from 'components/background/BackgroundColor';
import BackgroundImage from 'components/background/BackgroundImage';
import BackgroundVideo from 'components/base/video/BackgroundVideo';
import PropTypes from 'prop-types';
import React from 'react';

// Set a image, video or color as a background
const BackgroundSwitch = ({ children, background, type, mediaQuery }) => {
    let BackgroundComponent;

    if (!type && background) {
        type = background.type;
    }
    
    switch (type) {
        case 'image':
            BackgroundComponent = props =>
                // Fallback to grey background if image is selected but url is missing
                background?.url ? (
                    <BackgroundImage
                        src={background.url}
                        position={background.backgroundPosition}
                        size={background.backgroundSize}
                        query={mediaQuery || background.mediaQuery}
                        {...props}
                    />
                ) : (
                    <BackgroundColor color="grey" {...props} />
                );
            break;

        case 'video':
            BackgroundComponent = props =>
                // Fallback to grey background if video is selected but url is missing
                background?.url ? (
                    <BackgroundVideo src={background.url} {...props} />
                ) : (
                    <BackgroundColor color="grey" {...props} />
                );
            break;

        case 'color':
            BackgroundComponent = props => <BackgroundColor color={background} {...props} />;
            break;

        default:
            BackgroundComponent = props => <div {...props} />;
            console.warn(`Background type ${type} not found.`);
            break;
    }

    return <BackgroundComponent>{children}</BackgroundComponent>;
};

BackgroundSwitch.propTypes = {
    background: PropTypes.oneOfType([imageProp, PropTypes.string, videoProp]).isRequired,
    children: PropTypes.node,
    mediaQuery: PropTypes.arrayOf(PropTypes.object),
    type: backgroundTypeProp,
};

export default BackgroundSwitch;
