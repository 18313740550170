import CloseBig from 'assets/icons/CloseBig';
import Cookies from 'js-cookie';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import getApplicationAliasNameById from 'utils/getApplicationAliasNameById';
import styled from 'libs/styled';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TransitionElement = styled('div')`
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 0px;

    ${above.sm} {
        right: 30px;
        left: unset;
        bottom: 30px;
    }
    
    ${above.md} {
        position: relative;
        right: 30px;
        left: 30px;
        bottom: 30px;    
    }
`;

const Wrapper = styled('div')`
    position: relative;
    border-radius: 5px;
    padding: 16px 12px;
    background-color: ${colors.white};

    ${above.sm} {
        max-width: 480px;
    }

    ${above.md} {
        padding: 16px;
    }
`;

const Icon = styled('button')`
    position: absolute;
    top: 18px;
    right: 18px;
`;

const CountrySiteSelectorOverlay = () => {
    const { t } = useTranslation();
    const { search: urlQuery } = useLocation();
    const { aliasId, path, protocol, customer } = useSelector(state => state.application);
    const overlay = useSelector(state => state.overlay);
    const alternateHrefs = useSelector(state => state.page.alternate_hrefs);

    const externalApplications = process.env.REACT_APP_EXTERNAL_APPLICATIONS?.split(',');

    const customerCountryName = customer.geo?.countryName;
    const currentApplicationName = getApplicationAliasNameById(aliasId);
    const currentDomain = window.location.href;
    const suggestedApplicationName = externalApplications?.includes(customerCountryName) 
        ? customerCountryName
        : getApplicationAliasNameById(customer.primaryApplication?.aliasId);

    let customerApplicationUrl = '';
    if (customer.primaryApplication?.aliasId && customer.primaryApplication?.url) {
        // Check if there's a localized page for the customer primary application
        customerApplicationUrl = `${protocol}:${customer.primaryApplication.url}${urlQuery}`;

        // In new projects the data is called "hrefs", not "alternate_hrefs".
        if (alternateHrefs) {
            const pageTranslationMatch = alternateHrefs.find(
                href => href.alias_id === customer.primaryApplication.aliasId
            );
            if (pageTranslationMatch) {
                customerApplicationUrl = pageTranslationMatch.uri + urlQuery;
            }
        }
    }

    // Save the user's choice in a cookie and hides the overlay.
    const selectApplication = (domain, selectedAliasId, selectedPath) => {
        const cookieValue = JSON.stringify({ domain, selectedAliasId, selectedPath });
        Cookies.set('site_selector_popup', cookieValue, {
            expires: 7,
            path,
        });

        overlay.hide();
    };

    return (
        <TransitionElement className="fade-in center">
            <Wrapper>
                <Icon onClick={() => selectApplication(aliasId, path)}>
                    <CloseBig />
                </Icon>

                <Heading mb="24px">{t('overlay.title')}</Heading>
                <Paragraph mb="16px">
                    {t('overlay.country_popup_text', {
                        customerCountryName,
                        applicationName: currentApplicationName,
                    })}
                </Paragraph>

                {/* Stay on the current application */}
                <ThemeButton
                    theme="black"
                    mb="16px"
                    width="100%"
                    style={{ textTransform: 'uppercase' }}
                    onClick={() => selectApplication(currentDomain, aliasId, path)}
                >
                    {t('overlay.stay_current_application', { applicationName: currentApplicationName })}
                </ThemeButton>

                {/* Switch to suggested application */}
                {customerApplicationUrl && (
                    <ThemeButton
                        theme="outlinedBlack"
                        mb="16px"
                        width="100%"
                        style={{ textTransform: 'uppercase' }}
                        to={customerApplicationUrl}
                        onClick={() =>
                            selectApplication(customerApplicationUrl, customer.primaryApplication?.aliasId, customer.primaryApplication?.path)
                        }
                    >
                        {t('overlay.go_to_suggested_location', { applicationName: suggestedApplicationName })}
                    </ThemeButton>
                )}
            </Wrapper>
        </TransitionElement>
    );
};

export default CountrySiteSelectorOverlay;
