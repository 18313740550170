import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';

const Arrow = ({ color = 'black', height = '16px', viewBox = '0 0 16 16', width = '16px', ...props }) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...props}>
        <Path d="M1 8C5.33333 8 14 8 14 8" stroke={color} strokeWidth="1.25" />
        <Path d="M8 2L14 8L8 14" stroke={color} strokeWidth="1.25" />
    </SVG>
);

Arrow.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Arrow;
