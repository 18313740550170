import React, { FunctionComponent } from 'react';

import Circle from './base/Circle';
import Path from './base/Path';
import SVG from './base/SVG';

interface Props {
    color: string;
    height: string;
    viewBox: string;
    width: string;
}

const Globe: FunctionComponent<Props> = ({
    width = '18px',
    height = '18px',
    viewBox = '0 0 18 18',
    color = 'white',
    ...props
}: Props) => (
    <SVG width={width} height={height} viewBox={viewBox} fill="none" {...props}>
        <Circle cx="9" cy="9" r="8.5" stroke={color} />
        <Path
            d="M13.5 9C13.5 11.4167 12.9547 13.5798 12.0985 15.1211C11.2328 16.6794 10.1167 17.5 9 17.5C7.88332 17.5 6.76723 16.6794 5.90154 15.1211C5.04526 13.5798 4.5 11.4167 4.5 9C4.5 6.58333 5.04526 4.42016 5.90154 2.87886C6.76723 1.32062 7.88332 0.5 9 0.5C10.1167 0.5 11.2328 1.32062 12.0985 2.87886C12.9547 4.42016 13.5 6.58333 13.5 9Z"
            stroke="white"
        />
        <Path d="M1 9H17" stroke={color} />
        <Path d="M9 1V17" stroke={color} />
    </SVG>
);

export default Globe;
