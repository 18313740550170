import LevelThreeImages from 'Header/Default/Menu/LevelThreeImages';
import LevelThreeLinks from 'Header/Default/Menu/LevelThreeLinks';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';

const LinkWrapper = styled('div', {
    shouldForwardProp: prop => ['active', 'headerHeights', 'spaceTop'].indexOf(prop) === -1,
})`
    display: ${({ active }) => (active ? 'block' : 'none')};
    height: ${({ headerHeights }) => `calc(100vh - ${headerHeights.desktop + headerHeights.banner - 24}px)`};
    padding-bottom: 24px;

    overflow-y: auto;
    overflow-x: hidden;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: ${({ spaceTop }) => spaceTop || 0};
    }
`;

const MenuLevelThree = ({ active = false, contentType = '', handleClick, items = [], spaceTop = '', links = [] }) => {
    const headerHeights = useHeaderHeights();

    if (!(items.length || links.length)) {
        return null;
    }

    // There are both links and items due to contentTypes
    const contentLinks = contentType === 'links' ? links : items;

    const mediaQuery = [
        /* 0  <-  xs */ { w: 200, q: 70 },
        /* xs <-> sm */ { w: 200, q: 70 },
        /* sm <-> md */ { w: 200, q: 70 },
        /* md <-> lg */ { w: 200, q: 70 },
        /* lg <-> xl */ { w: 300, q: 70 },
        /* xl ->     */ { w: 400, q: 70 },
    ];

    return (
        <LinkWrapper active={active} headerHeights={headerHeights} spaceTop={spaceTop}>
            {contentType === 'links' ? (
                <LevelThreeLinks
                    handleClick={handleClick}
                    links={contentLinks}
                    twoColumns={contentLinks.length >= 10}
                />
            ) : (
                <LevelThreeImages
                    contentType="imageGrid"
                    handleClick={handleClick}
                    links={contentLinks}
                    mediaQuery={mediaQuery}
                />
            )}
        </LinkWrapper>
    );
};

MenuLevelThree.propTypes = {
    active: PropTypes.bool.isRequired,
    contentType: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    items: PropTypes.array,
    links: PropTypes.array,
    spaceTop: PropTypes.string,
};

export default MenuLevelThree;
