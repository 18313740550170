import TextLink, { TextLinkWysisyg } from 'components/text/TextLink';
import { linkProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import Newsletter from 'components/Newsletter';
import PropTypes from 'prop-types';
import React from 'react';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Row = styled('div')`
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    color: ${colors.white};
`;

const Col = styled('div')`
    padding: 0px 12px;
    line-height: 1.2;
`;

const Text = styled('p')`
    margin-bottom: 8px;
`;

const LinkWrapper = styled('li')`
    :not(:last-of-type) {
        margin-bottom: 12px;
    }
`;

const FooterContentDesktop = ({ textarea, address, contact, newsletter = {}, menu }) => {
    const { t } = useTranslation();

    return (
        <>
            <Row mb="100px" justifyContent="space-between">
                <Col width="25%">
                    {textarea?.text && <Wysiwyg data={textarea.text} tagComponents={{ url: TextLinkWysisyg }} />}
                    {textarea?.link && (
                        <>
                            <TextLink mt="8px" to={textarea.link.to}>
                                {textarea.link.label}
                            </TextLink>
                        </>
                    )}
                </Col>
                <Col width="50%">
                    <Newsletter theme="white" {...newsletter} />
                </Col>
            </Row>
            {(address || contact) && (
                <Row mb="24px">
                    <Col width="25%">{t('footer.address')}</Col>
                    <Col width="25%">
                        {address && (
                            <Wysiwyg
                                data={address}
                                textComponent={props => <Text {...props} />}
                                tagComponents={{ url: TextLinkWysisyg }}
                            />
                        )}
                    </Col>
                    <Col width="50%">
                        {contact && (
                            <Wysiwyg
                                data={contact}
                                textComponent={props => <Text {...props} />}
                                tagComponents={{ url: TextLinkWysisyg }}
                            />
                        )}
                    </Col>
                </Row>
            )}
            {menu?.map((menuItem, index) => (
                <Row mb={index < menu.length - 1 ? '24px' : '64px'} key={`${menuItem.label}-${index}`}>
                    <Col width="25%">{menuItem.label}</Col>
                    <Col as="ul" width="75%">
                        {menuItem.links?.map((link, index) =>
                            link ? (
                                <LinkWrapper key={`${link.to}-${index}`}>
                                    <TextLink to={link.to}>{link.label}</TextLink>
                                </LinkWrapper>
                            ) : null
                        )}
                    </Col>
                </Row>
            ))}
        </>
    );
};

FooterContentDesktop.propTypes = {
    address: wysiwygProp,
    contact: wysiwygProp,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.arrayOf(linkProp),
        })
    ),
    newsletter: PropTypes.shape({
        heading: PropTypes.string,
        headingTag: PropTypes.string,
        form: PropTypes.shape({
            submitLabel: PropTypes.string,
            placeholder: PropTypes.string,
            inputErrorText: PropTypes.string,
            privacyText: wysiwygProp,
            checkboxErrorText: PropTypes.string,
            formSuccessText: PropTypes.string,
            formErrorText: PropTypes.string,
        }),
    }),
    textarea: PropTypes.shape({
        text: wysiwygProp,
        link: linkProp,
    }),
};

export default FooterContentDesktop;
