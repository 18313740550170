import { linkProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import FooterBottomRow from './FooterBottomRow';
import FooterContent from './FooterContent';
import OrreforsLogo from 'assets/icons/OrreforsLogo';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import contentMargins from 'config/theme/contentMargins';
import { injectModel } from 'state';
import maxWidth from 'config/theme/maxWidth';
import styled from 'libs/styled';

const FooterWrapper = styled('footer')`
    color: ${colors.white};
    background-color: ${colors.black};
`;

const PositionWrapper = styled('div')`
    max-width: ${maxWidth};
    margin: 0 auto;
    padding-top: 16px;

    ${above.sm} {
        padding-top: 24px;
    }
`;

const FooterLogo = styled(OrreforsLogo)`
    margin-bottom: 56px;

    ${above.sm} {
        margin-bottom: 104px;
    }
`;

const Footer = ({ footer = {} }) => {
    const { data = {} } = footer;

    const newsletterForm = data?.newsletter?.form || {};

    return (
        <FooterWrapper>
            <PositionWrapper px={contentMargins}>
                <FooterLogo />
                <FooterContent
                    textarea={data.textarea}
                    address={data.address}
                    contact={data.contact}
                    menu={data.menu}
                    newsletter={{
                        id: 'footerNewsletter',
                        heading: data.newsletter?.heading?.text,
                        headingTag: data.newsletter?.heading?.type,
                        ...newsletterForm,
                    }}
                />
                <FooterBottomRow partners={data.partners} />
            </PositionWrapper>
        </FooterWrapper>
    );
};

Footer.propTypes = {
    footer: PropTypes.shape({
        data: PropTypes.shape({
            address: wysiwygProp,
            contact: wysiwygProp,
            newsletter: PropTypes.shape({
                heading: PropTypes.shape({
                    heading: PropTypes.string,
                    headingTag: PropTypes.string,
                }),
                form: PropTypes.shape({
                    submitLabel: PropTypes.string,
                    placeholder: PropTypes.string,
                    inputErrorText: PropTypes.string,
                    privacyText: wysiwygProp,
                    checkboxErrorText: PropTypes.string,
                    formSuccessText: PropTypes.string,
                    formErrorText: PropTypes.string,
                }),
            }),
            menu: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    link: PropTypes.arrayOf(linkProp),
                })
            ),
            partners: PropTypes.arrayOf(
                PropTypes.shape({
                    icon: PropTypes.string,
                    name: PropTypes.string,
                    to: PropTypes.string,
                })
            ),
            textarea: PropTypes.shape({
                text: wysiwygProp,
                link: linkProp,
            }),
        }),
    }).isRequired,
};

export default injectModel('footer')(Footer);
