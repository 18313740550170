import Image from 'components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const ImageWrapper = styled('div')`
    width: 100%;
    height: 100%;
    position: relative;

    .hover-image {
        opacity: 0;
    }

    ${media.hover} {
        &:hover {
            .hover-image {
                opacity: 1;
            }
        }
    }
`;

const StyledImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity ${transitions.primaryFast};
`;

const ProductCardImage = ({
    hoverImage = '',
    imageSizes = ['50vw', '50vw', '33.33vw', null, '650px'],
    primaryImage,
    srcWidths = [188, 384, 341, 480, 650],
    title,
}) => {
    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));

    return (
        <ImageWrapper>
            <StyledImage
                alt={title}
                fm="jpg"
                fit="crop"
                crop="entropy"
                sizes={imageSizes}
                src={{ url: primaryImage, width: srcWidths, height: srcHeights }}
                title={title}
            />
            {hoverImage && (
                <StyledImage
                    className="hover-image"
                    alt={title}
                    fm="jpg"
                    fit="crop"
                    crop="entropy"
                    sizes={imageSizes}
                    src={{ url: hoverImage, width: srcWidths, height: srcHeights }}
                    title={title}
                />
            )}
        </ImageWrapper>
    );
};

ProductCardImage.propTypes = {
    hoverImage: PropTypes.string,
    imageSizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    primaryImage: PropTypes.string.isRequired,
    srcWidths: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    title: PropTypes.string.isRequired,
};

export default ProductCardImage;
