import AspectWrapper from 'components/wrappers/AspectWrapper';
import Image from 'components/Image';
import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const PositionZoom = styled('div')`
    position: absolute;
    padding: 24px;
    top: 0;
    right: 0;
    cursor: pointer;

    * {
        cursor: pointer;
    }
`;

const ProductMediaitemDesktop = ({ image, imageSizes, index, setCurrentImageIndex, srcHeights, srcWidths }) => (
    <AspectWrapper key={`${image.src}`} ratio={3 / 4} position="relative" mb="24px">
        <PositionZoom onClick={() => setCurrentImageIndex(index)}>
            <Plus />
        </PositionZoom>
        <StyledImage
            alt={image.alt}
            fit="crop"
            sizes={imageSizes}
            src={{ url: image.src, width: srcWidths, height: srcHeights }}
            title={image.name}
            crop="entropy"
        />
    </AspectWrapper>
);

ProductMediaitemDesktop.propTypes = {
    image: PropTypes.shape({
        alt: PropTypes.string,
        src: PropTypes.string,
        name: PropTypes.string,
    }),
    imageSizes: PropTypes.arrayOf(PropTypes.string),
    index: PropTypes.number,
    setCurrentImageIndex: PropTypes.func,
    srcHeights: PropTypes.arrayOf(PropTypes.number),
    srcWidths: PropTypes.arrayOf(PropTypes.number),
};

export default ProductMediaitemDesktop;
