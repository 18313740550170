import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    > * {
        margin: 8px;
        text-decoration: none;
    }
`;

const ButtonsList = ({ buttons, size = ['md', null, null, 'lg'], ...rest }) =>
    buttons && buttons.length > 0 ? (
        <Wrapper m="-8px" {...rest}>
            {buttons.map((button, index) => (
                <ThemeButton key={`${button.label}-${index}`} to={button.to} theme={button.theme} size={size}>
                    {button.label}
                </ThemeButton>
            ))}
        </Wrapper>
    ) : null;

ButtonsList.propTypes = {
    buttons: buttonsProp,
    size: PropTypes.string,
};

export default ButtonsList;
