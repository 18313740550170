import Image from 'components/Image';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    cursor: pointer;

    ${above.sm} {
        padding: 4px 12px;
    }

    ${above.md} {
        padding: 4px 24px;
    }
`;

const Text = styled(Paragraph, { shouldForwardProp: prop => ['isActive'].indexOf(prop) === -1 })`
    color: ${({ isActive }) => (isActive ? colors.black : colors.grey6)};
`;

const ImageDot = styled(Image, {
    shouldForwardProp: prop => ['isActive', 'useOutline'].indexOf(prop) === -1,
})`
    width: 12px;
    height: 12px;
    margin-right: 6px;
    border-radius: 50%;
    border: 1px solid ${({ isActive, useOutline }) => (isActive ? colors.black : useOutline ? colors.grey6 : 'none')};
    padding: ${({ isActive }) => (isActive ? '2px' : '0')};
    background: transparent;
`;

const ColorDotWithText = ({ isActive = false, handleChange, image, useOutline = false, text }) => (
    <Wrapper onClick={handleChange}>
        <ImageDot
            format="jpg"
            isActive={isActive}
            name={text}
            alt={text}
            sizes="12px"
            src={{ url: image, width: [12, 24, 36] }} // Three sizes because of 1, 2 and 3 DPR
            useOutline={useOutline}
        />
        <Text isActive={isActive}>{text}</Text>
    </Wrapper>
);

ColorDotWithText.propTypes = {
    handleChange: PropTypes.func,
    image: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    text: PropTypes.string.isRequired,
    useOutline: PropTypes.bool,
};

export default ColorDotWithText;
