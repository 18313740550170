import React, { useState } from 'react';

import Image from 'components/products/productCardMini/Image';
import IncreaseOrDecrease from 'components/products/productCardMini/Basket/IncreaseOrDecrease';
import Information from 'components/products/productCardMini/Basket/Information';
import ProductCardPrice from 'components/products/productCardMini/Basket/BasketPrice';
import PropTypes from 'prop-types';
import Remove from 'components/products/productCardMini/Basket/Remove';
import SpinnerIcon from 'assets/icons/Spinner';
import colors from 'config/theme/colors';
import { injectModel } from 'state';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    position: relative;
    margin-bottom: 12px;
    padding-bottom: 12px;
`;

const InfoAndRemove = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-left: 12px;
    width: 50%;
`;

const PriceAndControls = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 50%;
`;

const Spinner = styled(SpinnerIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ProductCardBasket = ({
    basket,
    borderBottom = true,
    className,
    designers,
    image,
    line,
    name,
    onClickPermalink,
    onSale,
    priceWithCurrency,
    quantity,
    salePriceWithCurrency,
    url,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleIncrease = line => {
        if (line) {
            setIsLoading(true);

            basket.updateBasketLineQuantity(line, +1).then(() => {
                setIsLoading(false);
            });
        }
    };

    const handleDecrease = line => {
        if (line) {
            setIsLoading(true);

            basket.updateBasketLineQuantity(line, -1).then(() => {
                setIsLoading(false);
            });
        }
    };

    const handleRemove = (line, quantity) => {
        if (line) {
            setIsLoading(true);

            basket.removeFromBasket(line, quantity).then(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <Wrapper
            borderBottom={borderBottom ? `1px solid ${colors.grey4}` : null}
            className={className}
            opacity={isLoading ? 0.6 : 1}
        >
            <Image name={name} productLink={url} imageUrl={image} toggleBasket={onClickPermalink} />
            <InfoAndRemove>
                <Information designers={designers} link={url} name={name} toggleBasket={onClickPermalink} />
                <Remove
                    onClick={() => {
                        handleRemove(line, quantity);
                    }}
                />
                {isLoading && <Spinner />}
            </InfoAndRemove>
            <PriceAndControls>
                <ProductCardPrice
                    priceWithCurrency={priceWithCurrency}
                    salePriceWithCurrency={salePriceWithCurrency}
                    onSale={onSale}
                />
                <IncreaseOrDecrease
                    quantity={quantity}
                    decrease={() => handleDecrease(line)}
                    increase={() => handleIncrease(line)}
                />
            </PriceAndControls>
        </Wrapper>
    );
};

ProductCardBasket.propTypes = {
    basket: PropTypes.object.isRequired,
    borderBottom: PropTypes.bool,
    className: PropTypes.string,
    designers: PropTypes.string,
    image: PropTypes.string.isRequired,
    line: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClickPermalink: PropTypes.func,
    onSale: PropTypes.bool.isRequired,
    priceWithCurrency: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    salePriceWithCurrency: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default injectModel('basket')(ProductCardBasket);
