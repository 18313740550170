import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import Rect from 'assets/icons/base/Rect';
import SVG from 'assets/icons/base/SVG';

const Decrease = ({ color = 'black', ...props }) => (
    <SVG height="25px" viewBox="0 0 24 25" width="24px" fill="none" {...props}>
        <Path
            stroke={color}
            strokeWidth="1.25"
            d="M11.9999 11.5072V12.0067M11.9999 12.0067L11.9999 12.5072M11.9999 12.0067H18M11.9999 12.0067H5.99982"
        />
        <Rect stroke={color} x="0.5" y="0.506927" width="23px" height="23px" rx="11.5" />
    </SVG>
);

Decrease.propTypes = {
    color: PropTypes.string,
};

export default Decrease;
