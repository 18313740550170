import {
    ALLOW_SCROLL,
    DISABLE_OVERLAY,
    ENABLE_OVERLAY,
    FETCH_CATEGORIES,
    FETCH_CATEGORIES_ERROR,
    FETCH_CATEGORIES_SUCCESS,
    PREVENT_SCROLL,
    RESOLVE_APP,
    RESOLVE_APP_ERROR,
    RESOLVE_APP_SUCCESS,
    RESOLVE_COUNTRY,
    RESOLVE_COUNTRY_ERROR,
    RESOLVE_COUNTRY_SUCCESS,
    SET_CONTENT_LOADED,
    SET_CURRENT_HREF,
    SET_CURRENT_PATHNAME,
    SET_INITIAL_QUERY,
    SET_MARKET_ID,
    SET_PATH_BEFORE_STORE,
    SET_STATE,
    UPDATE_SHOP_CONFIG,
} from './constants';

// The initial state of this store.
const initialState = {
    activeOverlay: null,
    aliasId: null,
    applicationId: null,
    applicationName: null,
    config: {},
    contentLoaded: false,
    customer: {
        geo: {
            countryCode: '',
            countryName: '',
            currency: '',
            ip: '',
            market: '',
            pricelist: '',
        },
        primaryApplication: {
            aliasId: null,
            appId: null,
            locale: '',
            path: '',
            url: '',
        },
    },
    country: null,
    currentHref: '',
    currentPathname: '/',
    ecommerce: {
        currency: {
            decimals: '',
            id: '',
            name: '',
            prefix: '',
            separator: '',
            suffix: '',
        },
        market: {
            id: '',
            locked: false,
        },
        pricelist: {
            id: '',
            locked: false,
        },
        routes: {
            checkoutErrorUri: '',
            checkoutThanksUri: '',
            checkoutUri: '',
        },
        shippingCountries: {
            availableInMarket: [],
            default: '',
            otherAvailable: [],
        },
    },
    isFetching: false,
    isScrollable: false,
    languages: [],
    locale: null,
    path: null,
    pathBeforeStore: '/',
    'shop_config': null,
    state: null,
    stores: [],
};

const generateProductsKey = (shopConfig, locale) => locale + shopConfig.pricelist_id + shopConfig.market_id;

export { initialState };

export default (state = initialState, action) => {
    switch (action.type) {
        case RESOLVE_APP:
            return {
                ...state,
                isFetching: true,
            };
        case RESOLVE_APP_SUCCESS:
            return {
                ...state,
                isFetching: false,
                applicationId: action.application.id,
                applicationName: action.application.name,
                aliasId: action.application.alias_id,
                config: {
                    'global_options': action.config.global_options,
                    options: action.config.options,
                },
                customer: action.customer,
                domain: action.application.domain,
                ecommerce: action.ecommerce,
                languages: action.languages,
                locale: action.application.locale,
                path: action.application.path,
                productsKey: generateProductsKey(action.shop_config, action.application.locale),
                protocol: action.application.protocol,
                stores: action.stores,
                'shop_config': action.shop_config,
            };
        case RESOLVE_APP_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case RESOLVE_COUNTRY: {
            return {
                ...state,
                isFetching: true,
            };
        }
        case RESOLVE_COUNTRY_SUCCESS: {
            return {
                ...state,
                country: action.country,
                isFetching: false,
            };
        }
        case RESOLVE_COUNTRY_ERROR: {
            return {
                ...state,
                isFetching: false,
            };
        }

        case ENABLE_OVERLAY:
            return {
                ...state,
                activeOverlay: action.overlay,
            };

        case DISABLE_OVERLAY:
            return {
                ...state,
                activeOverlay: null,
            };

        case ALLOW_SCROLL:
            return {
                ...state,
                isScrollable: false,
            };

        case PREVENT_SCROLL:
            return {
                ...state,
                isScrollable: true,
            };
        case SET_CURRENT_HREF:
            return {
                ...state,
                currentHref: action.currentHref,
            };
        case SET_CURRENT_PATHNAME:
            return {
                ...state,
                currentPathname: action.currentPathname,
            };
        case SET_PATH_BEFORE_STORE:
            return {
                ...state,
                pathBeforeStore: action.pathBeforeStore,
            };
        case SET_STATE:
            return {
                ...state,
                state: action.state,
            };
        case SET_CONTENT_LOADED:
            return {
                ...state,
                contentLoaded: action.contentLoaded,
            };
        case SET_MARKET_ID:
            return {
                ...state,
                'shop_config': {
                    ...state.shop_config,
                    'market_id': action.market_id,
                },
            };
        case UPDATE_SHOP_CONFIG:
            return {
                ...state,
                'shop_config': {
                    ...state.shop_config,
                    'market_id': action.marketId,
                    'pricelist_id': action.pricelistId,
                    'user_country': action.countryId,
                    currency: action.currency,
                },
            };
        case SET_INITIAL_QUERY:
            return {
                ...state,
                initialQueryString: action.queryString,
                initialQueryObject: action.queryObject,
            };
        case FETCH_CATEGORIES:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                config: {
                    ...state.config,
                    'product_categories': action.categories,
                },
                isFetching: false,
            };
        case FETCH_CATEGORIES_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
