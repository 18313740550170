import React, { forwardRef } from 'react';

import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    margin: 0 -6px;
    padding: 32px 0;

    ${above.sm} {
        flex-direction: row;
        margin: 0 -12px;
        padding: 96px 0;
    }
`;

const InfoWrapper = styled('div')`
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 32px;
    padding: 0;

    ${above.sm} {
        width: 50%;
        flex-direction: column;
        padding: 0 12px;
    }
`;

const Heading = styled('div')`
    width: 33.33%;
    padding: 0 6px;

    ${above.sm} {
        width: 100%;
        padding: 0;
        margin-bottom: 12px;
    }
`;

const Info = styled('div')`
    width: 66.66%;
    padding: 0 6px;

    ${above.sm} {
        padding: 0;
    }
`;

const Description = styled('div')`
    width: 100%;
    padding: 0 6px;

    ${above.sm} {
        width: 50%;
        padding: 0 12px;
    }
`;

const MoreInfo = styled(Paragraph)`
    margin-top: 32px;
    padding: 0 6px;
    text-decoration: underline;
    cursor: pointer;

    ${above.sm} {
        display: none;
    }
`;

const ProductInformation = forwardRef(
    ({ designers, name, scrollToAccordions, shortDescription, informationLabel }, ref) => {
        const { t } = useTranslation();
        return (
            <Wrapper>
                <InfoWrapper>
                    <Heading>
                        <Paragraph as="h3" fontSize="1.6rem" lineHeight="1.2">
                            {informationLabel}
                        </Paragraph>
                    </Heading>
                    <Info>
                        <Paragraph>{name}</Paragraph>
                        <Paragraph>{designers}</Paragraph>
                    </Info>
                </InfoWrapper>
                <Description ref={ref}>
                    <Paragraph size="xl">{shortDescription}</Paragraph>
                </Description>
                <MoreInfo onClick={() => scrollToAccordions()}>{t('product_page.information.more_details')}</MoreInfo>
            </Wrapper>
        );
    }
);

ProductInformation.propTypes = {
    designers: PropTypes.string,
    informationLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
    scrollToAccordions: PropTypes.func.isRequired,
    shortDescription: PropTypes.string,
};

ProductInformation.defaultProps = {
    designers: '',
    shortDescription: '',
    informationLabel: '',
};

export default ProductInformation;
