import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
/* eslint-disable camelcase */
import ProductsContainer from 'containers/ProductsContainer';
import ProductsPromotion from 'components/contentLayouts/ProductsPromotion';
import PropTypes from 'prop-types';
import React from 'react';
import { sortArrayByTargetArray } from 'utils/array';
import { transformProductCard } from 'utils/dataTransformers/product';
import useSaveResponse from 'hooks/useSaveResponse';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const RelatedProducts = ({ relatedProductsIds, upsellHeading, relatedHeading }) => {
    const { t } = useTranslation();
    const marketId = useSelector(state => state.application.shop_config.market_id);

    // Save response from container
    const [useResponse, saveResponse] = useSaveResponse();

    // Takes all the id:s from different related groups and merges them into one so that we only need one call to algolia
    const relatedProductsIdsValues = Object.values(relatedProductsIds) || [];
    const mergedProductIds = relatedProductsIdsValues.reduce((mergedIds, ids) => {
        ids.forEach(id => {
            if (!mergedIds.includes(id)) {
                mergedIds.push(`${id}`);
            }
        });

        return mergedIds;
    }, []);

    return (
        <ProductsContainer
            pageSize={20}
            ids={mergedProductIds}
            render={({ response }) => {
                saveResponse(response);
                const products = useResponse?.current?.products || [];

                if (products.length === 0) {
                    return null;
                }

                const transformedProducts = products.map(product => transformProductCard(product, marketId, t));

                // Sort the products from Centra in the same order as it was from the cms
                const sortedStandardProducts = relatedProductsIds.standard
                    ? sortArrayByTargetArray(
                          relatedProductsIds.standard,
                          transformedProducts.filter(p => relatedProductsIds.standard.includes(p.id)),
                          'id'
                      )
                    : [];

                // Sort the products from Centra in the same order as it was from the cms
                const sortedUpsellProducts = relatedProductsIds.upsell
                    ? sortArrayByTargetArray(
                          relatedProductsIds.upsell,
                          transformedProducts.filter(p => relatedProductsIds.upsell.includes(p.id)),
                          'id'
                      )
                    : [];

                return (
                    <MaxWidthWrapper includeContentMargins>
                        {sortedStandardProducts.length > 0 ? (
                            <ProductsPromotion
                                heading={relatedHeading?.text}
                                headingTag={relatedHeading?.type}
                                products={sortedStandardProducts}
                                my={['56px', null, '80px', '112px']}
                            />
                        ) : null}
                        {sortedUpsellProducts.length > 0 ? (
                            <ProductsPromotion
                                heading={upsellHeading?.text}
                                headingTag={upsellHeading?.type}
                                products={sortedUpsellProducts}
                                my={['56px', null, '80px', '112px']}
                            />
                        ) : null}
                    </MaxWidthWrapper>
                );
            }}
        />
    );
};

RelatedProducts.propTypes = {
    relatedHeading: PropTypes.object,
    relatedProductsIds: PropTypes.object.isRequired,
    upsellHeading: PropTypes.object,
};

export default RelatedProducts;
