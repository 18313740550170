import Arrow from 'assets/icons/Arrow';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import styled from 'libs/styled';

const StyledThemeButton = styled(ThemeButton)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

const ArrowButton = ({ children, ...rest }) => (
    <StyledThemeButton {...rest}>
        {children}
        <Arrow ml="8px" color="currentColor" />
    </StyledThemeButton>
);

ArrowButton.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ArrowButton;
