import { useDispatch, useSelector } from 'react-redux';

import LevelOne from 'Header/Default/Menu/Mobile/LevelOne';
import LevelTwo from 'Header/Default/Menu/Mobile/LevelTwo';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import { closeMenu } from 'state/models/Header/actions';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div', { shouldForwardProp: prop => ['headerHeights', 'menuIsOpen'].indexOf(prop) === -1 })`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    z-index: ${zIndex.menu};
    padding-top: ${({ headerHeights }) => headerHeights.mobile}px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: ${colors.background};
`;

const LevelTwoWrapper = styled('div')`
    position: relative;
`;

const Buttons = styled('div')`
    margin: auto -4px 0;
    padding: 32px 12px;
`;

const Menu = () => {
    const { activeMenu, menuIsOpen } = useSelector(
        state => state.header.state,
        (prev, next) =>
            prev.header?.state.activeMenu !== next.header?.state.activeMenu ||
            prev.header?.state.menuIsOpen !== next.header?.state.menuIsOpen
    );

    const dispatch = useDispatch();
    const { mainMenu, generalLinks } = useSelector(state => state.header.data.navigation);
    const headerHeights = useHeaderHeights();

    return (
        <Wrapper headerHeights={headerHeights} menuIsOpen={menuIsOpen}>
            <div>
                <LevelOne />
                {mainMenu?.length > 0 && (
                    <LevelTwoWrapper>
                        {mainMenu.map(({ subMenus, featuredLinks, headerLink }, index) => (
                            <LevelTwo
                                active={activeMenu === index}
                                featuredLinks={featuredLinks}
                                key={headerLink.label}
                                parent={headerLink}
                                subMenus={subMenus}
                            />
                        ))}
                    </LevelTwoWrapper>
                )}
            </div>
            {generalLinks?.length > 0 && (
                <Buttons>
                    {generalLinks.map(({ label, to }) => (
                        <ThemeButton
                            key={label}
                            mx="4px"
                            theme="outlinedBlack"
                            to={to}
                            onClick={() => dispatch(closeMenu())}
                        >
                            {label}
                        </ThemeButton>
                    ))}
                </Buttons>
            )}
        </Wrapper>
    );
};

export default Menu;
