import { closeMenu, openMenu, resetMenu, setActiveMenu } from 'state/models/Header/actions';
import { useDispatch, useSelector } from 'react-redux';

import BaseLink from 'components/base/Link';
import React from 'react';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Nav = styled('nav')`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 12px;
`;

const Links = styled('ul')`
    display: flex;
    align-items: center;
    margin-left: -10px;
`;

const Item = styled('li', { shouldForwardProp: prop => ['active', 'menuIsOpen'].indexOf(prop) === -1 })`
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 24px;
    cursor: pointer;
    color: ${({ menuIsOpen }) => (menuIsOpen ? colors.grey6 : null)};
    transition: color ${transitions.primary};
    padding-left: 10px;

    &:hover {
        color: ${colors.black};
    }

    ${({ active }) =>
        active &&
        `
        color: ${colors.black};
            :before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                display: inline-block;
                border-radius: 50%;
                background: ${colors.black};
                width: 6px;
                height: 6px;
            }
        `}

    &:last-of-type {
        margin-right: 0;
    }
`;

const MenuLevelOne = () => {
    const { activeMenu, menuIsOpen } = useSelector(
        state => state.header.state,
        (prev, next) =>
            prev.header?.state.hasBanner !== next.header?.state.hasBanner ||
            prev.header?.state.menuIsOpen !== next.header?.state.menuIsOpen
    );

    const dispatch = useDispatch();
    const mainMenu = useSelector(state => state.header.data.navigation.mainMenu);

    const handleOnClick = ({ hasSubmenu, index }) => {
        if (hasSubmenu) {
            dispatch(setActiveMenu(index));
            if (!menuIsOpen) {
                dispatch(openMenu());
            }
        } else {
            dispatch(resetMenu());
            if (menuIsOpen) {
                dispatch(closeMenu());
            }
        }
    };

    return (
        <Nav id="desktopMenu">
            <Links>
                {mainMenu.map(({ featuredLinks, headerLink, subMenus }, index) => {
                    const hasSubmenu = subMenus?.length > 0 || featuredLinks?.length > 0;

                    return headerLink ? (
                        <Item
                            active={activeMenu === index}
                            menuIsOpen={menuIsOpen}
                            key={headerLink.label}
                            onClick={() => handleOnClick({ hasSubmenu, index })}
                        >
                            <BaseLink volatile to={hasSubmenu ? null : headerLink.to}>
                                {headerLink.label}
                            </BaseLink>
                        </Item>
                    ) : null;
                })}
            </Links>
        </Nav>
    );
};

export default MenuLevelOne;
