import React, { useState } from 'react';

import FilterColorOption from 'components/Filter/FilterColorOption';
import FilterListOption from 'components/Filter/FilterListOption';
import PropTypes from 'prop-types';
import SingleAccordion from 'components/accordions/SingleAccordion';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    border-top: 1px solid ${colors.grey4};
    border-bottom: 1px solid ${colors.grey4};
`;

const Filters = styled('div')`
    padding-bottom: 18px;
`;

const FilterAccordion = ({ filters = [], handleChange = {} }) => {
    const [activeAccordion, setActiveAccordion] = useState([]);
    return (
        <Wrapper>
            {filters.map((filter, index) => (
                <SingleAccordion
                    animationDuration={200}
                    borderBottom={filters.length - 1 !== index}
                    colorText={colors.black}
                    headerPX={['12px', null, null, '24px']}
                    headerPY="16px"
                    headingSize="xs"
                    headingWeight="500"
                    isActive={activeAccordion.includes(index)}
                    key={filter.key}
                    text={`${filter.label} ${filter.totalSelected > 0 ? `(${filter.totalSelected})` : ''}`}
                    timingFunction="ease"
                    handleClick={() =>
                        setActiveAccordion(
                            activeAccordion.includes(index)
                                ? activeAccordion.filter(activeIndex => activeIndex !== index)
                                : [...activeAccordion, index]
                        )
                    }
                >
                    <Filters>
                        {filter.key === '_filter_colors' ? (
                            <FilterColorOption
                                parentFilterKey={filter.key}
                                filters={filter.values}
                                handleChange={handleChange}
                            />
                        ) : (
                            <FilterListOption
                                parentFilterKey={filter.key}
                                filters={filter.values}
                                handleChange={handleChange}
                            />
                        )}
                    </Filters>
                </SingleAccordion>
            ))}
        </Wrapper>
    );
};

FilterAccordion.propTypes = {
    filters: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
};

export default FilterAccordion;
