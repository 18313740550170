import React, { useEffect, useRef, useState } from 'react';

import Above from 'components/breakpoints/Above';
import Banner from 'components/Banner';
import BasketButton from 'Header/Default/Menu/buttons/BasketButton';
import Below from 'components/breakpoints/Below';
import LevelOneDesktop from 'Header/Default/Menu/Desktop/LevelOne';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Menu from 'Header/Default/Menu';
import MenuButton from './Menu/buttons/MenuButton';
import OrreforsLogo from 'assets/icons/OrreforsLogo';
import SearchButton from 'Header/Default/Menu/buttons/SearchButton';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useHeaderHeights from 'hooks/useHeaderHeights';
import useIsShowroom from 'hooks/useIsShowroom';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const Header = styled('header', {
    shouldForwardProp: prop => ['backdropFilter', 'color', 'invertedColor'].indexOf(prop) === -1,
})`
    width: 100%;
    left: 0;
    z-index: ${zIndex.header};
    backdrop-filter: ${({ backdropFilter }) => backdropFilter || 'none'};
    transition: background ${transitions.primary};

    animation-duration: 800ms;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

    @keyframes open-menu-with-banner {
        from {
            top: 38px; /* headerHeight.banner */
        }
        to {
            top: 0;
        }
    }

    // Icons
    path,
    polygon {
        fill: ${({ color }) => color};
        transition: ${transitions.primary};
    }

    ${above.md} {
        #icons-wrapper {
            color: ${({ color, invertedColor, menuIsOpen }) => (menuIsOpen ? invertedColor : color)};
            transition: color ${transitions.primary};
            path,
            polygon {
                fill: ${({ color, invertedColor, menuIsOpen }) => (menuIsOpen ? invertedColor : color)};
            }
        }
    }
`;

const HeaderContent = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin: 0 -12px;

    ${above.md} {
        padding: 20px 0;
    }
`;

const LogoLink = styled(Link)`
    width: 50%;
    padding: 0 12px;

    ${above.md} {
        width: 25%;
    }
`;

const IconsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    padding: 0 12px;

    ${above.md} {
        width: 25%;
    }
`;

const HandleOnTopOfContent = styled('div', { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    width: 100%;
    display: block;
    height: ${({ headerHeights }) => headerHeights.mobile}px;

    ${above.md} {
        height: ${({ headerHeights }) => headerHeights.desktop}px;
    }
`;

const Default = () => {
    const { hasBanner, menuIsOpen } = useSelector(
        state => state.header.state,
        (prev, next) =>
            prev.header?.state.hasBanner !== next.header?.state.hasBanner ||
            prev.header?.state.menuIsOpen !== next.header?.state.menuIsOpen
    );

    const { template, type } = useSelector(
        state => state.page,
        (prev, next) => prev.page?.template !== next.page?.template || prev.page?.type !== next.page?.type
    );

    const isShowRoom = useIsShowroom();
    const currentOverlay = useSelector(state => state.overlay.current);
    const isAboveMd = useAboveBreakpoint('md');
    const headerHeights = useHeaderHeights();
    const prevScroll = useRef(0);
    const currentScroll = useRef();
    const [state, setState] = useState({ openOverlay: null, isHovered: false, scrolledPastBanner: false });
    const { openOverlay, isHovered, scrolledPastBanner } = state;

    // Overlays that change headerTheme to BlackWhite when open
    const whiteBlackThemeOverlays = ['basketOverlay', 'searchOverlay'].indexOf(openOverlay) !== -1;

    // On top of content pages (uses headerTheme transparentWhite)
    const frontPage = type === 'page' && template === 'frontpage';
    const certificateCheckout = type === 'ecommerce_checkout' && template === 'ecommerce_checkout_gift_certificate';
    const productPage = type === 'product' && template === null;
    const standardPage = type === 'page' && template === 'default';
    const onTopOfContent = frontPage || productPage || standardPage || certificateCheckout;

    const heroContentTextColor = useSelector(state => state.page.data?.hero_content?.[0]?.data?.text_color);
    const giftCertContentTextColor = useSelector(state => state.page.data?.content?.text_color);
    const textColor = certificateCheckout ? giftCertContentTextColor : heroContentTextColor;

    const handleScroll = () => {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || 0;
        const scolledPastBannerThreshold = scrollPosition > headerHeights.banner;

        if (scolledPastBannerThreshold !== state.scrolledPastBanner) {
            setState({ ...state, scrolledPastBanner: scolledPastBannerThreshold });
        }

        prevScroll.current = currentScroll.current;
        currentScroll.current = scrollPosition;
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [state]);

    useEffect(() => {
        setState({ ...state, openOverlay: currentOverlay });
    }, [currentOverlay]);

    let headerTheme = 'whiteBlack';

    if (menuIsOpen) {
        headerTheme = 'split';
    } else if (isHovered || whiteBlackThemeOverlays) {
        headerTheme = 'whiteBlack';
    } else if (scrolledPastBanner && !isHovered && !menuIsOpen) {
        headerTheme = 'blurBlack';
    } else if (onTopOfContent && textColor === 'black') {
        headerTheme = 'transparentBlack';
    } else if (onTopOfContent) {
        headerTheme = 'transparentWhite';
    }

    const headerThemes = {
        blurBlack: {
            backgroundColor: colors.whiteOpacityHigh,
            color: colors.black,
            invertedColor: colors.white,
            backdropFilter: 'blur(16px)',
            boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.04)',
        },
        split: {
            backgroundColor: [colors.background, null, null, 'transparent'],
            color: colors.black,
            invertedColor: colors.white,
        },
        transparentWhite: {
            backgroundColor: 'transparent',
            color: colors.white,
            invertedColor: colors.black,
        },
        transparentBlack: {
            backgroundColor: 'transparent',
            color: colors.black,
            invertedColor: colors.white,
        },
        whiteBlack: {
            backgroundColor: colors.background,
            color: colors.black,
            invertedColor: colors.white,
            backdropFilter: 'blur(16px)', // Keeps blur during transition from blur to white
        },
    };

    const headerStyles = {
        top:
            !menuIsOpen && !whiteBlackThemeOverlays && hasBanner && !scrolledPastBanner
                ? `${headerHeights.banner}px`
                : 0,
        position: scrolledPastBanner || menuIsOpen ? 'fixed' : 'absolute',
        animationName:
            (menuIsOpen || whiteBlackThemeOverlays) && hasBanner && !scrolledPastBanner
                ? ['open-menu-with-banner']
                : null,
        ...headerThemes[headerTheme],
    };

    return (
        <>
            {hasBanner && <Banner />}
            {!onTopOfContent && <HandleOnTopOfContent headerHeights={headerHeights} />}
            <Header
                borderBottom={
                    menuIsOpen
                        ? [`1px solid ${headerStyles.color}`, null, null, 'none']
                        : `1px solid ${headerStyles.color}`
                }
                menuIsOpen={menuIsOpen}
                onMouseEnter={isAboveMd && !menuIsOpen ? () => setState({ ...state, isHovered: true }) : null}
                onMouseLeave={isAboveMd ? () => setState({ ...state, isHovered: false }) : null}
                {...headerStyles}
            >
                <MaxWidthWrapper includeContentMargins>
                    <HeaderContent>
                        <LogoLink to="/">
                            <OrreforsLogo />
                        </LogoLink>
                        <Above breakpoint="md" render={() => <LevelOneDesktop />} />
                        <IconsWrapper id="icons-wrapper">
                            <SearchButton />
                            <Below breakpoint="md" render={() => <MenuButton />} />
                            {!isShowRoom && <BasketButton />}
                        </IconsWrapper>
                    </HeaderContent>
                </MaxWidthWrapper>
            </Header>
            <Menu />
        </>
    );
};

export default Default;
