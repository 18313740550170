import React, { useState } from 'react';
import contentMargins, { bleedMargins } from 'config/theme/contentMargins';

import PropTypes from 'prop-types';
import SingleAccordion from 'components/accordions/SingleAccordion';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    border-top: 1px solid ${colors.grey4};
`;

const FooterAccordion = ({ content }) => {
    const [activeAccordion, setActiveAccordion] = useState([]);

    return (
        <Wrapper mx={bleedMargins}>
            {content.map(({ label, Component }, index) => (
                <SingleAccordion
                    animationDuration={200}
                    borderBottom={content.length - 1 !== index}
                    colorText={colors.white}
                    headerPX={contentMargins}
                    isActive={activeAccordion.includes(index)}
                    key={`${label}-${index}`}
                    text={label}
                    timingFunction="ease"
                    handleClick={() =>
                        setActiveAccordion(
                            activeAccordion.includes(index)
                                ? activeAccordion.filter(activeIndex => activeIndex !== index)
                                : [...activeAccordion, index]
                        )
                    }
                >
                    {Component}
                </SingleAccordion>
            ))}
        </Wrapper>
    );
};

FooterAccordion.propTypes = {
    content: PropTypes.arrayOf(
        PropTypes.exact({
            Component: PropTypes.node,
            label: PropTypes.string,
        })
    ).isRequired,
};

export default FooterAccordion;
