import Filter from 'components/Filter';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const TransitionElement = styled('div')`
    position: absolute;
    top: 0;
    width: 100vw;
    background-color: ${colors.background};
    transition: max-width ${transitions.primaryFast};

    // Used to prevent a height 100vh bug on android phones.
    // This element will overflow the TransitionElement and hide content behind when viewport changes height.
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 200px;
        background-color: ${colors.background};
    }

    ${above.sm} {
        width: 45%;
        max-width: 400px;
    }
`;

const FilterOverlay = ({ ...rest }) => (
    <TransitionElement className={['slide-in bottom', null, 'slide-in left']}>
        <Filter {...rest} />
    </TransitionElement>
);

FilterOverlay.propTypes = {
    clear: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    handleChange: PropTypes.shape({ value: PropTypes.func, range: PropTypes.func }).isRequired,
    hitsCount: PropTypes.number.isRequired,
    sorts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default FilterOverlay;
