import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';

// Used inside buttons
const CloseSmall = ({ color = 'black', height = '16px', viewBox = '0 0 16 17', width = '17px', ...props }) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...props}>
        <Path
            d="M12.2432 4.25732L8.0005 8.50001M8.0005 8.50001L3.75781 12.7427M8.0005 8.50001L12.2432 12.7427M8.0005 8.50001L3.75781 4.25732"
            stroke={color}
            strokeWidth="1.25"
        />
    </SVG>
);

CloseSmall.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default CloseSmall;
