/**
 * Appends a querystring with imgix options
 *
 * @param {string} auto - The auto parameter helps you automate a baseline level of optimization across your entire image catalog.
 * @param {string} background - Color to fill in any transparent areas
 * @param {string} crop - Crop mode controls how the image is aligned when fit=crop is set.
 * @param {string} fit - The fit parameter controls how the output image is fit to its target dimensions after resizing, and how any background areas will be filled.
 * @param {string} format - The output format to convert the image to.
 * @param {(number|string)[]} heights - An array of heights
 * @param {string} mask - Sets the type of mask from a pre-defined list of shapes, or from a transparent image URL.
 * @param {number|string} quality - Controls the output quality of lossy file formats (jpg, pjpg, webp, or jxr).
 * @param {string} src - The image url
 * @param {(number|string)[]} widths - An array of widths
 */

const generateImgixSrc = ({ src, width, height, format, background, quality, auto, fit, crop, mask }) => {
    const bg = background && background.indexOf('#') === 0 ? background.slice(1) : background;
    if (src && !src.startsWith('data:')) {
        return `${src}?q=${quality}${fit ? `&fit=${fit}` : ''}${fit === 'crop' && crop ? `&crop=${crop}` : ''}${
            width ? `&w=${width}` : ''
        }${height ? `&h=${height}` : ''}${format ? `&fm=${format}` : ''}${bg ? `&bg=${bg}` : ''}${
            auto ? `&auto=${auto}` : ''
        }${mask ? `&mask=${mask}` : ''}`;
    }

    return src ? src : bg ? bg : undefined;
};

export default generateImgixSrc;
