import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';

const CloseBig = ({ color = 'black', height = '24px', width = '32px', viewBox = '0 0 32 24', ...props }) => (
    <SVG height={height} width={width} viewBox={viewBox} fill="none" {...props}>
        <Path d="M24 4l-8 8m0 0l-8 8m8-8l8 8m-8-8L8 4" stroke={color} strokeWidth="1.25" />
    </SVG>
);

CloseBig.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default CloseBig;
