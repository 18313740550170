import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div', {
    shouldForwardProp: prop => !['contentPosition'].includes(prop),
})`
    ${({ contentPosition }) => {
        if (contentPosition === 'left') {
            return 'margin-right: auto';
        } else if (contentPosition === 'right') {
            return 'margin-left: auto';
        }
        return 'margin: 0 auto';
    }}
`;

Wrapper.propTypes = {
    contentPosition: PropTypes.string.isRequired,
};

// @todo: Remove and use ColumnsLayout instead as it does the same as of v 2.0

/**
 * Use to make the child component cover a percentage of this wrapper, calculated by number of columns inside the totalColumns
 *
 * @param {node} children - The child component
 * @param {number||number[]} columns - The number of columns the content should cover.
 * @param {string} contentPosition - Position the content to the left, right or center.
 * @param {number||number[]} totalColumns - The number of columns to use as a base.
 * @param {object} rest - Spread remaning props to wrapper, used to add class, styles etc.
 *
 * How to Use:
 * For example, if the content should be 8 of a 12 grid, the following should be submitted:
 * columns={8} and totalColumns={12}
 * This will make the content cover 66.66% of the GridWrapper.
 * You can also submit values as arrays for responsiveness
 *
 * For full width without subtracting leftOverSpace the following should be submitted:
 * columns={12} and totalColumns={12}
 */

const GridWrapper = ({ children, columns, contentPosition, totalColumns, ...rest }) => {
    const calculateWidth = (columns, totalColumns) => {
        let lastValue = 0;

        const columnsIsArray = Array.isArray(columns);
        const totalColumnsIsArray = Array.isArray(totalColumns);

        // Half the space betweeen each grid column.
        const leftOverSpace = 8;

        if (columnsIsArray && totalColumnsIsArray) {
            let lastTotalValue = 0;

            if (columns.length !== totalColumns.length) {
                throw new Error('GridWrapper arrays needs to be same length');
            }

            return columns.map((column, index) => {
                lastValue = column || lastValue;
                lastTotalValue = totalColumns[index] || lastTotalValue;

                // Returns 100% without leftOverSpace
                if (column === 12 && lastTotalValue === 12) {
                    return `100%`;
                }

                // Returns ex 33%. Half leftover space is subtracted from the total width.
                return `calc(${(lastValue / lastTotalValue) * 100}% - ${leftOverSpace}px)`;
            });
        } else if (columnsIsArray) {
            return columns.map(column => {
                lastValue = column || lastValue;

                // Returns 100% without leftOverSpace
                if (lastValue === 12 && totalColumns === 12) {
                    return `100%`;
                }

                // Returns ex 33%. Half leftover space is subtracted from the total width.
                return `calc(${(lastValue / totalColumns) * 100}% - ${leftOverSpace}px)`;
            });
        } else if (totalColumnsIsArray) {
            return totalColumns.map(totalColumn => {
                lastValue = totalColumn || lastValue;

                // Returns 100% without leftOverSpace
                if (columns === 12 && lastValue === 12) {
                    return `100%`;
                }

                // Returns ex 33%. Half leftover space is subtracted from the total width.
                return `calc(${(columns / lastValue) * 100}% - ${leftOverSpace}px)`;
            });
        }

        // Returns 100% without leftOverSpace
        if (columns === 12 && totalColumns === 12) {
            return `100%`;
        }

        // Returns ex 33%. Half leftover space is subtracted from the total width.
        return `calc(${(columns / totalColumns) * 100}% - ${leftOverSpace}px)`;
    };

    return (
        <Wrapper contentPosition={contentPosition} width={calculateWidth(columns, totalColumns)} {...rest}>
            {children}
        </Wrapper>
    );
};

GridWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    columns: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
    contentPosition: PropTypes.string,
    totalColumns: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};

GridWrapper.defaultProps = {
    className: '',
    contentPosition: 'center',
    totalColumns: 12,
};

export default GridWrapper;
