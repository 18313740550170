import BreadcrumbsStructuredData from 'components/metadata/structuredData/BreadcrumbsStructuredData';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const List = styled('ul')`
    display: flex;
    flex-wrap: wrap;
`;

const Breadcrumb = styled('li')`
    display: block;
    margin: 0;
    margin-right: 4px;
    color: inherit;
    font-size: 1.4rem;
    line-height: 1.9rem;

    ${above.md} {
        font-size: 1.6rem;
        line-height: 1;
    }
`;

// Standard Breadcrumbs component
const Breadcrumbs = ({ items = [], currentTitle, textColor = 'black', ...rest }) => {
    const { t } = useTranslation();

    const breadcrumbs = [
        { title: t('breadcrumbs.start'), permalink: '/' },
        ...items,
        currentTitle && { title: currentTitle },
    ];

    return (
        <>
            <BreadcrumbsStructuredData breadcrumbs={breadcrumbs} />
            <List {...rest} color={textColor === 'white' ? colors.white : colors.black}>
                {breadcrumbs.map(
                    (breadcrumb, index) =>
                        breadcrumb && (
                            <Breadcrumb key={breadcrumb.title} color={textColor}>
                                <Link volatile to={breadcrumb.permalink || breadcrumb.to} color={textColor}>
                                    {index > 0 && '•'} {decodeHTMLEntities(breadcrumb.title)}
                                </Link>
                            </Breadcrumb>
                        )
                )}
            </List>
        </>
    );
};

Breadcrumbs.propTypes = {
    currentTitle: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            permalink: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
    textColor: PropTypes.oneOf(['black', 'white']),
};

export default Breadcrumbs;
