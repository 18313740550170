import ContentWrapper from 'components/wrappers/ContentWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'assets/icons/Spinner';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import searchAndReplaceWysiwyg from 'libs/wordpress/utils/searchAndReplaceWysiwyg';
import styled from 'libs/styled';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const NoResultWrapper = styled('div')`
    text-align: center;
    padding: 80px 0 140px;

    ${above.md} {
        padding: 180px 0 240px;
    }
`;

const Text = styled('p')`
    color: ${colors.gray3};
    font-size: 16px;
    line-height: 26px;

    a {
        color: inherit;
    }
`;

const NoResultComponent = ({ loading = true, text = '', searchString = '' }) => {
    // Replace {search} with the searchstring
    const replacedText = searchString && text ? searchAndReplaceWysiwyg(text, '{search}', searchString) : text;

    return (
        <ContentWrapper>
            <NoResultWrapper>
                {loading ? (
                    <Spinner />
                ) : (
                    <Wysiwyg
                        data={replacedText}
                        textComponent={props => <Text {...props} />}
                        tagComponents={{ url: TextLinkWysisyg }}
                    />
                )}
            </NoResultWrapper>
        </ContentWrapper>
    );
};

NoResultComponent.propTypes = {
    loading: PropTypes.bool,
    searchString: PropTypes.string,
    text: PropTypes.oneOfType([wysiwygProp, PropTypes.string]),
};

export default NoResultComponent;
