import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 24px 12px;

    ${above.md} {
        padding: 16px 24px;
    }
`;

const Options = styled('div')`
    display: flex;
    flex-direction: column;
    margin: -6px 0;
`;

const Label = styled('label')`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: fit-content;
    padding: 6px 0;

    input:checked ~ span {
        background-color: ${colors.black};
    }
`;

const CheckBox = styled('span')`
    position: relative;
    border: 1px solid ${colors.black};
    height: 12px;
    width: 12px;
    min-width: 12px;
    background: transparent;
    transition: background 0.2s linear;
    cursor: pointer;
    border-radius: 12px;
    margin-right: 6px;

    &::before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        border: 2px solid ${colors.white};
        height: 11px;
        width: 11px;
        transform: translate(-50%, -50%);
        border-radius: 11px;
    }
`;

const InvisibleInput = styled('input')`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const SortOptions = ({ sorts = [], handleChange }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Paragraph mb="16px" fontWeight="500">
                {t('filter_config.sort_by')}:
            </Paragraph>
            <Options>
                {sorts.map(({ index, label, selected }) => (
                    <Label key={`${index}_${selected}`} onChange={e => handleChange(e.target.value)}>
                        <InvisibleInput name="sort" type="radio" value={index} defaultChecked={selected} />
                        <CheckBox />
                        {label}
                    </Label>
                ))}
            </Options>
        </Wrapper>
    );
};

SortOptions.propTypes = {
    handleChange: PropTypes.func.isRequired,
    sorts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default SortOptions;
