import PropTypes from 'prop-types';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';

const excludedProps = ['colWidth', 'gutterWidth'];

const Column = styled('div', {
    shouldForwardProp: prop => excludedProps.indexOf(prop) === -1,
})`
    ${({ gutterWidth }) => responsiveBreakpointsStyle(gutterWidth, 'margin-right: {value}px;')}

    &:last-of-type {
        margin-right: 0px;
    }

    ${({ colWidth }) => responsiveBreakpointsStyle(colWidth, 'width: {value}; max-width: {value}; flex: 0 0 {value};')}
`;

Column.propTypes = {
    colWidth: PropTypes.string.isRequired,
    gutterWidth: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
};

export default Column;
