import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Remove = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <Paragraph cursor="pointer" textDecorationLine="underline" fontSize="1.6rem" onClick={onClick}>
            {t('checkout_basket.remove')}
        </Paragraph>
    );
};

Remove.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default Remove;
