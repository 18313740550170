/* eslint-disable react/jsx-sort-default-props */
/* eslint-disable react/sort-prop-types */
import GridWrapper from 'components/wrappers/GridWrapper';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Used by the module system.
 * Makes sure that all contentBlocks that should not be 100% of their parent has the same settings and widths accross the site.
 * Blocks like TextBlock etc should be narrower on larger screens to preserve readability.
 * @param {node} children - The child component
 * @param {number} xs - Overwrite ModuleGridWrappers default props for xs
 * @param {number} sm - Overwrite ModuleGridWrappers default props for sm
 * @param {number} md - Overwrite ModuleGridWrappers default props for md
 * @param {number} lg - Overwrite ModuleGridWrappers default props for lg
 * @param {number} xl - Overwrite ModuleGridWrappers default props for xl
 * @param {number} xxl - Overwrite ModuleGridWrappers default props for xxl
 * @param {object} rest - Spread remaning props to wrapper, used to add class, styles etc.
 */

const ModuleGridWrapper = ({ children, xs, sm, md, lg, xl, xxl, ...rest }) => (
    <GridWrapper totalColumns={12} columns={[xs, sm, md, lg, xl, xxl]} {...rest}>
        {children}
    </GridWrapper>
);

ModuleGridWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xxl: PropTypes.number,
};

ModuleGridWrapper.defaultProps = {
    xs: 12,
    sm: null,
    md: 10,
    lg: 8,
    xl: null,
    xxl: null,
};

export default ModuleGridWrapper;
