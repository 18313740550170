import { above, below } from 'utils/mediaqueries';

import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import SiteSelector from './SiteSelector';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Row = styled('div')`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -12px;
    padding: 24px 0px 30px;
    color: ${colors.white};
    ${above.sm} {
        padding: 20px 0px;
    }
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 1px;
        background-color: ${colors.grey4};
        transform: translateX(-50%);
    }
`;

const Col = styled('div')`
    padding: 0px 12px;
    > span {
        ${below.md} {
            display: none;
        }
    }
`;

const Icons = styled('ul')`
    display: inline-flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    margin: -6px;
    ${above.md} {
        margin: -12px;
    }
`;

const Icon = styled('li')`
    padding: 6px;
    ${above.md} {
        padding: 12px;
    }
`;

const FooterBottomRow = ({ partners = [] }) => {
    const sites = [
        {
            currency: 'EUR',
            label: 'GB',
            locale: 'en_GB',
            to: 'https://orrefors.com/',
        },
        {
            currency: 'SEK',
            label: 'SE',
            locale: 'sv_SE',
            to: 'https://www.orrefors.se/',
        },
        {
            currency: 'USD',
            label: 'US',
            locale: '',
            to: 'https://www.orrefors.us/',
        },
    ];

    const showSites = sites.length > 0;

    return (
        <Row>
            {showSites && (
                <Col display="flex" position="relative" alignItems="center" width={['50%', null, null, '25%']}>
                    <SiteSelector sites={sites} />
                </Col>
            )}
            <Col
                color={colors.grey4}
                fontSize="1.6rem"
                textAlign={showSites ? ['right', null, null, 'left'] : 'left'}
                width={showSites ? ['50%', null, null, '33.33%'] : ['50%', null, null, '58.33%']}
            >
                {new Date().getFullYear()} © Orrefors. <span>All rights reserved</span>
            </Col>
            <Col
                marginTop={showSites ? ['24px', null, null, '0'] : '0'}
                textAlign="right"
                width={showSites ? ['100%', null, null, '41.66%'] : ['50%', null, null, '41.66%']}
            >
                {partners.length > 0 && (
                    <Icons>
                        {partners.map(partner => (
                            <Icon key={partner.icon}>
                                <Link volatile to={partner.to}>
                                    <Image
                                        alt={partner.name}
                                        height="24px"
                                        sizes="32px"
                                        src={{ url: partner.icon, width: [32, 64, 96] }} // Three sizes because of 1, 2 and 3 DPR
                                        title={partner.name}
                                        width="auto"
                                    />
                                </Link>
                            </Icon>
                        ))}
                    </Icons>
                )}
            </Col>
        </Row>
    );
};

FooterBottomRow.propTypes = {
    partners: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            name: PropTypes.string,
            to: PropTypes.string,
        })
    ),
};

export default FooterBottomRow;
