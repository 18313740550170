import React, { forwardRef } from 'react';
import { above, below } from 'utils/mediaqueries';

import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import SubCategoryItem from './SubCategoryItem';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';

const Div = styled('div')``;

const Links = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    ${above.md} {
        flex-wrap: wrap;
    }

    ${below.sm} {
        &::before,
        &::after {
            display: block;
            content: '';
            ${responsiveBreakpointsStyle(
                ['12px', null, '24px'],
                'width: {value}; max-width: {value}; flex: 0 0 {value};'
            )}
        }
    }
`;

const SubCategoryList = forwardRef(({ items, ...rest }, ref) => {
    if (items?.length < 1) {
        return null;
    }
    return (
        <Div {...rest} ref={ref}>
            <MaxWidthWrapper includeContentMargins>
                <Div width={['calc(100% + 24px)', null, '100%']} mx={['-12px', null, '0']}>
                    <ScrollWrapper displayFadeEffect direction="horizontal" padding="24px 0 16px">
                        <Links>
                            {items.map((item, index) => (
                                <SubCategoryItem
                                    as={item.as}
                                    current={item.current}
                                    key={item.title}
                                    title={item.title}
                                    to={item.to}
                                    mr={index === items.length - 1 ? '0px !important' : null}
                                    onClick={item.onClick}
                                />
                            ))}
                        </Links>
                    </ScrollWrapper>
                </Div>
            </MaxWidthWrapper>
        </Div>
    );
});

SubCategoryList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            as: PropTypes.string,
            title: PropTypes.string,
            to: PropTypes.string,
            current: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ).isRequired,
};

export default SubCategoryList;
