import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { closeMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import { useDispatch } from 'react-redux';

const Wrapper = styled('ul')`
    margin: -4px 0;
    padding-bottom: 32px;
`;

const Item = styled('li')`
    font-size: 2.4rem;
    padding: 6px 0;

    ${above.md} {
        font-size: 3.6rem;
        padding: 4px 0;
    }
`;

const Featured = ({ featuredLinks = [], featuredRef = {} }) => {
    const dispatch = useDispatch();

    if (!featuredLinks.length) {
        return null;
    }

    return (
        <Wrapper ref={featuredRef}>
            {featuredLinks.map(({ label, to }) => (
                <Item key={label} onClick={() => dispatch(closeMenu())}>
                    <Link volatile to={to}>
                        {label}
                    </Link>
                </Item>
            ))}
        </Wrapper>
    );
};

Featured.propTypes = {
    featuredLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    featuredRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
        .isRequired,
};

export default Featured;
