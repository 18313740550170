/**
 * Get correct language from locale
 * @param {string} locale - locale to get language from
 * @returns {string}
 * */

export const hrefLangs = {
    'sv_SE': 'sv-SE',
    'en_GB': 'en-GB',
};

const getHrefLangFromLocale = locale => {
    if (!locale) {
        return;
    }
    return hrefLangs[locale];
};

export default getHrefLangFromLocale;
