import PropTypes from 'prop-types';
import React from 'react';
import contentMargins from 'config/theme/contentMargins';
import maxWidth from 'config/theme/maxWidth';
import styled from 'libs/styled';

/**
 * Used to set the same max-width of the content-system across the whole site.
 * @param {string[]} customMaxWidth - Override maxWidth css property.
 * @param {bool} includeContentMargins - Includes content margins to max width.
 * @param {string[]} px - Override contentMargins vertically.
 */

const Wrapper = styled('div')`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const MaxWidthWrapper = ({ children, customMaxWidth, includeContentMargins = false, px, ...rest }) => {
    const maxContentMargin = `${contentMargins[contentMargins.length - 1]}`;
    const maxWidthWithContentMargins = `${parseInt(maxWidth, 10) + parseInt(maxContentMargin, 10) * 2}px`;

    return (
        <Wrapper
            maxWidth={customMaxWidth || (includeContentMargins ? maxWidthWithContentMargins : maxWidth)}
            px={px || (includeContentMargins ? contentMargins : null)}
            {...rest}
        >
            {children}
        </Wrapper>
    );
};

MaxWidthWrapper.propTypes = {
    customMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    includeContentMargins: PropTypes.bool,
    px: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default MaxWidthWrapper;
