import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import Breadcrumbs from 'components/Breadcrumbs';
import Heading from 'components/text/Heading';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { imageProps } from 'utils/proptypes/media';
import { mediaQueries } from 'config/theme/breakpoints';
import styled from 'libs/styled';

const Wrapper = styled('div')``;

const CategoryAndDateWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;

    ${above.md} {
        width: 50%;
        margin-bottom: 24px;
    }
`;

const PostHero = ({
    background,
    backgroundQueries = mediaQueries.fullWidth,
    backgroundRatio = [3 / 4, 3 / 2],
    backgroundType,
    breadcrumbs = [],
    category = '',
    date = '',
    title = '',
    ...rest
}) => (
    <Wrapper {...rest}>
        <MaxWidthWrapper includeContentMargins position="relative">
            <Breadcrumbs items={breadcrumbs} currentTitle={title} margin={['12px 0 32px', null, null, '16px 0 56px']} />
            <Heading
                as="h1"
                my="0px"
                size="lg"
                marginBottom={['32px', null, null, '52px']}
                maxWidth={['100%', null, '50%']}
            >
                {title}
            </Heading>
            {(category || date) && (
                <CategoryAndDateWrapper>
                    {category && (
                        <Paragraph size="md" width="50%">
                            {category}
                        </Paragraph>
                    )}
                    {date && (
                        <Paragraph size="md" width="50%">
                            {date}
                        </Paragraph>
                    )}
                </CategoryAndDateWrapper>
            )}
        </MaxWidthWrapper>
        <AspectWrapper ratio={backgroundRatio}>
            <BackgroundSwitch type={backgroundType} background={background} mediaQuery={backgroundQueries} />
        </AspectWrapper>
    </Wrapper>
);

PostHero.propTypes = {
    background: imageProps,
    backgroundQueries: PropTypes.array,
    backgroundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    backgroundType: PropTypes.string,
    breadcrumbs: PropTypes.array.isRequired,
    category: PropTypes.string,
    createdAt: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default PostHero;
