import AnimatedScrollDown from 'assets/icons/scroll/AnimatedScrollDown';
import ArrowButton from 'components/buttons/styledButtons/ArrowButton';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundImage from 'components/background/BackgroundImage';
import Breadcrumbs from 'components/Breadcrumbs';
import HeadingAndNumber from './HeadingAndNumber';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import ThemeButton from 'components/buttons/ThemeButton';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import { backgroundFlexibleProp } from 'utils/proptypes/modules/mediaProps';
import colors from 'config/theme/colors';
import { mediaQueries } from 'config/theme/breakpoints';
import styled from 'libs/styled';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Div = styled('div')``;

const DescriptionWrapper = styled('div')`
    margin-bottom: 32px;

    ${above.sm} {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 -12px 24px;
    }
`;

const DescriptionAndButtonWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${above.sm} {
        width: 66.66%;
        padding: 0 12px;
    }

    ${above.md} {
        width: 50%;
        font-size: 3.2rem;
    }
`;

const Description = styled('div')`
    font-size: 2.4rem;
`;

const Shortcut = styled(Paragraph)`
    display: block;

    ${above.sm} {
        padding: 0 12px;
    }
`;

const ArchiveHero = ({
    background,
    backgroundQueries = mediaQueries.fullWidth,
    backgroundRatio = [3 / 4, 3 / 2],
    breadcrumbs,
    button,
    heroDescription = '',
    heroTitle = '',
    numberOfItems = 0,
    onClick,
    scrollIcon = false,
    shortCutText,
    shortCutType = 'text',
    ...rest
}) => (
    <Div {...rest}>
        <MaxWidthWrapper includeContentMargins position="relative">
            {breadcrumbs && <Breadcrumbs currentTitle={heroTitle} items={breadcrumbs} mt={['12px', null, '16px']} />}
            <HeadingAndNumber title={heroTitle} numberOfItems={numberOfItems} my={['32px', null, '40px', '56px']} />
            <DescriptionWrapper>
                <Div alignItems="center" display="flex" marginBottom={['24px', null, '0']}>
                    {shortCutType === 'button' ? (
                        <ThemeButton
                            marginLeft={[null, null, '12px']}
                            theme="outlinedBlack"
                            size="lg"
                            onClick={onClick}
                        >
                            {shortCutText}
                        </ThemeButton>
                    ) : (
                        <Shortcut
                            cursor={onClick ? 'pointer' : 'default'}
                            textDecoration={onClick ? 'underline' : 'none'}
                            onClick={onClick}
                        >
                            {shortCutText}
                        </Shortcut>
                    )}
                    {scrollIcon && (
                        <AnimatedScrollDown color={colors.black} ml={['8px', null, '16px']} position="relative" />
                    )}
                </Div>
                <DescriptionAndButtonWrapper>
                    <Description>
                        <Wysiwyg
                            data={heroDescription}
                            textComponent={props => <Paragraph size="xl" {...props} />}
                            tagComponents={{ url: TextLinkWysisyg }}
                        />
                    </Description>
                    {button && (
                        <ArrowButton fontSize="1.6rem" marginTop="22px" size="lg" theme={button.theme} to={button.to}>
                            {button.label}
                        </ArrowButton>
                    )}
                </DescriptionAndButtonWrapper>
            </DescriptionWrapper>
        </MaxWidthWrapper>
        <AspectWrapper ratio={backgroundRatio}>
            {background && (
                <BackgroundImage
                    src={background.url}
                    position={background.backgroundPosition}
                    size={background.backgroundSize}
                    query={backgroundQueries}
                />
            )}
        </AspectWrapper>
    </Div>
);

ArchiveHero.propTypes = {
    background: backgroundFlexibleProp,
    backgroundQueries: PropTypes.array,
    backgroundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    breadcrumbs: PropTypes.array,
    button: PropTypes.shape({
        theme: PropTypes.string,
        to: PropTypes.string,
        label: PropTypes.string,
    }),
    heroDescription: wysiwygProp,
    heroTitle: PropTypes.string.isRequired,
    numberOfItems: PropTypes.number,
    onClick: PropTypes.func,
    scrollIcon: PropTypes.bool,
    shortCutText: PropTypes.string.isRequired,
    shortCutType: PropTypes.oneOf(['button', 'text']),
};

export default ArchiveHero;
