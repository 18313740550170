import Breadcrumbs from 'components/Breadcrumbs';
import CmsModules from 'libs/wordpress/content/CmsModules';
import Heading from 'components/text/Heading';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import SubCategoryList from 'components/SubCategoryList';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Preamble = styled('div')`
    margin: 24px 0;

    ${above.sm} {
        margin: 32px 0;
    }
`;

const CustomerService = ({ data }) => {
    const {
        ancestors,
        breadcrumbs,
        children,
        heading,
        id,
        page_content: pageContent,
        preamble,
        siblings,
        title,
    } = data;
    const isSubPage = ancestors.length > 0;

    // Menu array
    const menuBase = isSubPage ? siblings : children;
    const relatedLinks = menuBase.map(item => ({
        title: item.title,
        to: item.id === id ? undefined : item.permalink,
        current: item.id === id,
        // Render the item inside the links list as the h1
        as: item.id === id ? 'h1' : undefined,
    }));

    return (
        <>
            <MaxWidthWrapper includeContentMargins>
                <Breadcrumbs currentTitle={title} items={breadcrumbs} my={['12px', null, null, '16px']} />
                <Heading as={isSubPage ? 'h2' : 'h1'} margin={['32px 0', '40px 0', null, '56px 0']} size="lg">
                    {isSubPage ? ancestors[ancestors.length - 1]?.title : title}
                </Heading>
                {preamble && (
                    <Preamble>
                        <Wysiwyg
                            data={preamble.paragraph}
                            textComponent={props => <Paragraph size="xl" {...props} />}
                            tagComponents={{ url: TextLinkWysisyg }}
                        />
                    </Preamble>
                )}
            </MaxWidthWrapper>
            {relatedLinks?.length > 1 && (
                <SubCategoryList
                    borderBottom={`1px solid ${colors.grey4}`}
                    borderTop={`1px solid ${colors.grey4}`}
                    items={relatedLinks}
                />
            )}
            {isSubPage && heading && (
                <MaxWidthWrapper includeContentMargins>
                    <Heading as="h2" margin={['32px 0', null, null, '48px 0']} size="md">
                        {heading}
                    </Heading>
                </MaxWidthWrapper>
            )}
            <CmsModules isCustomerServiceCategory data={pageContent} />
        </>
    );
};

CustomerService.propTypes = {
    data: PropTypes.object.isRequired, // CMS Data
};

export default CustomerService;
