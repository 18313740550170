import React, { useEffect, useRef, useState } from 'react';

import Above from 'components/breakpoints/Above';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import Below from 'components/breakpoints/Below';
import ColumnsLayout from 'components/contentLayouts/ColumnsLayout';
import Image from 'components/Image';
import ProductImageZoom from './ProductImageZoom';
import ProductMediaitemDesktop from './ProductMediaitemDesktop';
import PropTypes from 'prop-types';
import ScrollHorizontalLayout from 'components/contentLayouts/ScrollHorizontalLayout';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    padding-bottom: 24px;
    position: relative;

    ${above.md} {
        &::before,
        &::after {
            display: block;
            content: '';
            position: absolute;
            bottom: 24px;
            width: 24px;
            height: 10px;
            background-color: ${colors.background};
        }

        &::before {
            left: 0px;
        }

        &::after {
            right: 0px;
        }
    }
`;

const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const ProductMedia = ({ images = [], productSku }) => {
    const imageSizes = ['100vw', null, '50vw'];
    const srcWidths = [375, 720, 768, 960, 1024, 1300];
    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));

    /** Product zoom **/
    const galleryRef = useRef(null);
    const [currentImage, setCurrentImage] = useState(null);

    // Use imageNodes instead of just image src, this is needed for open/close animation to track position
    const [imageNodes, setimageNodes] = useState(null);

    // Update productZoom variables when product changes
    useEffect(() => {
        const imageElements = galleryRef.current?.childNodes[0]?.getElementsByTagName('img');
        const images = imageElements ? Array.from(imageElements) : null;
        setimageNodes(images);

        // Reset current image if we are navigating between product pages
        setCurrentImage(null);
    }, [productSku]);

    const setCurrentImageIndex = index => {
        setCurrentImage(index);
    };

    return (
        <Wrapper ref={galleryRef}>
            <Above
                breakpoint="md"
                render={() => (
                    <>
                        {images.length > 2 ? (
                            <ScrollHorizontalLayout
                                blocks={images.map((image, index) => (
                                    <ProductMediaitemDesktop
                                        key={image.src}
                                        image={image}
                                        imageSizes={imageSizes}
                                        index={index}
                                        srcHeights={srcHeights}
                                        srcWidths={srcWidths}
                                        setCurrentImageIndex={setCurrentImageIndex}
                                    />
                                ))}
                                showDragCursor={[false, null, null, true]}
                                columnWidth="50vw"
                                gutterWidth="0"
                            />
                        ) : (
                            <ColumnsLayout
                                blocks={images.map((image, index) => (
                                    <ProductMediaitemDesktop
                                        key={image.src}
                                        image={image}
                                        imageSizes={imageSizes}
                                        index={index}
                                        srcHeights={srcHeights}
                                        srcWidths={srcWidths}
                                        setCurrentImageIndex={setCurrentImageIndex}
                                    />
                                ))}
                                columnSizes={6}
                                gutterWidth="0"
                                gutterHeight="0"
                            />
                        )}

                        <ProductImageZoom
                            currentImageIndex={currentImage}
                            imageNodes={imageNodes}
                            setCurrentImageIndex={setCurrentImageIndex}
                        />
                    </>
                )}
            />
            <Below
                breakpoint="md"
                render={() =>
                    images.map(image => (
                        <AspectWrapper key={`${image.src}`} ratio={3 / 4}>
                            <StyledImage
                                alt={image.alt}
                                fit="crop"
                                sizes={imageSizes}
                                src={{ url: image.src, width: srcWidths, height: srcHeights }}
                                title={image.name}
                                crop="entropy"
                            />
                        </AspectWrapper>
                    ))
                }
            />
        </Wrapper>
    );
};

ProductMedia.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            alt: PropTypes.string,
            name: PropTypes.string,
            url: PropTypes.string,
        })
    ).isRequired,
    productSku: PropTypes.string.isRequired,
};

export default ProductMedia;
