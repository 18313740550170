import React, { useEffect, useRef } from 'react';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import FilterBarDesktop from 'components/Filter/FilterBarDesktop';
import FilterStickyButton from 'components/categories/FilterStickyButton';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import NoResultComponent from 'components/NoResultComponent';
import Pagination from 'components/Pagination';
import ProductGrid from 'components/products/ProductGrid';
import PropTypes from 'prop-types';
import SelectedFilter from 'components/Filter/SelectedFilter';
import { inServer } from 'config/constants';
import { injectModels } from 'state';
import overlayProps from 'utils/proptypes/overlay';
import pageProps from 'utils/proptypes/page';
import { scrollTo } from 'utils/scrollFunctions';
import styled from 'libs/styled';
import { transformProductCard } from 'utils/dataTransformers/product';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    overflow-anchor: none;
`;

const ProductsWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

const ProductListing = ({ overlay, products, response, page: pageData, noResultText }) => {
    const isTemplateProductCategory = pageData?.data?.post_type === 'ecommerce_category';
    const { t } = useTranslation();
    const marketId = useSelector(state => state.application.shop_config.market_id);
    const wrapperRef = useRef({});
    const isAboveMd = useAboveBreakpoint('md');

    const {
        clear = {},
        filters,
        hitsCount,
        page,
        pageSize,
        productsCount,
        pagination = {},
        selectedFilters = [],
        sorts = [],
        handleChange = {},
    } = response;

    const { allProducts } = products;

    useEffect(() => {
        if (overlay.current === 'filterOverlay') {
            overlay.update('filterOverlay', {
                clear,
                filters,
                handleChange,
                hitsCount,
                sorts,
            });
        }
    }, [filters]);

    const openFilterOverlay = () => {
        overlay.show('filterOverlay', {
            clear,
            filters,
            handleChange,
            hitsCount,
            sorts,
        });
    };

    const clickPagination = () => {
        // Scroll back to top of wrapper on pagination
        if (wrapperRef && wrapperRef.current) {
            scrollTo({
                smooth: true,
                // -65 to algin properly with position sticky filter
                top: wrapperRef.current.offsetTop - 65,
            });
        }
    };

    // Use response.products on server because allProducts is empty sometimes on server
    const productsToUse = inServer ? response.products : allProducts;
    const loading = Object.keys(response).length === 0 && response.constructor === Object;

    return (
        <>
            {/* #list-products is used by FilterBarDesktop */}
            <Wrapper id="list-products" ref={wrapperRef}>
                <Above
                    breakpoint="md"
                    render={() => <FilterBarDesktop hitsCount={hitsCount} openFilterOverlay={openFilterOverlay} />}
                />
                {selectedFilters.length > 0 && (
                    <SelectedFilter
                        selectedFilters={selectedFilters}
                        handleChange={handleChange.filter}
                        clear={clear}
                    />
                )}
                <MaxWidthWrapper includeContentMargins px={['8px', null, '12px', '24px']}>
                    {productsToUse && productsToUse.length > 0 ? (
                        <ProductsWrapper>
                            <ProductGrid
                                products={productsToUse.map(product => transformProductCard(product, marketId, t))}
                            />
                        </ProductsWrapper>
                    ) : (
                        <NoResultComponent text={noResultText} loading={loading} />
                    )}
                </MaxWidthWrapper>
                {pagination?.pages?.length > 1 && (
                    <Pagination
                        currentIndex={page}
                        maxSiblings={isAboveMd ? 2 : 1}
                        mb={['24px', null, null, '40px']}
                        message={t('pagination.products_pagination')}
                        mt={['24px', null, null, '80px']}
                        pageItemCount={productsCount}
                        pageSize={pageSize}
                        pages={pagination.pages}
                        totalItemCount={hitsCount}
                        usePaginationMeta={isTemplateProductCategory}
                        onClick={clickPagination}
                    />
                )}
            </Wrapper>
            <Below
                breakpoint="md"
                render={() => (
                    <FilterStickyButton
                        numberOfFilters={selectedFilters?.length}
                        openFilterOverlay={openFilterOverlay}
                    />
                )}
            />
        </>
    );
};

ProductListing.propTypes = {
    noResultText: PropTypes.string,
    overlay: overlayProps.isRequired,
    page: pageProps.isRequired,
    products: PropTypes.shape({
        allProducts: PropTypes.array,
        setNextFetchMode: PropTypes.func,
    }).isRequired,
    response: PropTypes.shape({
        clear: PropTypes.object,
        filters: PropTypes.arrayOf(PropTypes.object),
        handleChange: PropTypes.object,
        hasMore: PropTypes.bool,
        hitsCount: PropTypes.number,
        page: PropTypes.number,
        pageSize: PropTypes.number,
        pagination: PropTypes.exact({
            nextUrl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
            pages: PropTypes.arrayOf(PropTypes.object),
            previousUrl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        }),
        productsCount: PropTypes.number,
        products: PropTypes.arrayOf.isRequired,
        selectedFilters: PropTypes.arrayOf(PropTypes.object),
        sorts: PropTypes.arrayOf(
            PropTypes.exact({ index: PropTypes.string, label: PropTypes.string, selected: PropTypes.bool })
        ),
    }).isRequired,
};

export default injectModels(['overlay', 'products', 'page'])(ProductListing);
