/** @jsx jsx */
import { SIZES, STYLES, THEMES } from 'components/buttons/ThemeButtonConfig';
import { above, media } from 'utils/mediaqueries';
import { css, jsx } from '@emotion/core';

import BaseLink from 'components/base/Link';
import PropTypes from 'prop-types';
import styled from 'libs/styled';

/**
 * @version 1.1
 * @param {string|string[]} [size] Change your size here, is able to make it with responsive styling
 * @param {string} [theme] Change theme accordingly, can be conditioned in the component of usage
 * @param {string} [as] Change element to ex Link with: as={Link} to get the button Styling as a <a>-tag
 * @param {object} availableSizes  Getting the avaliable sizes from the sizes object in config
 * @param {string} [type] pass down the button type, default button
 * @param {string|string[]} rest pass down any specific styling will overwrite default settings from config
 * @note This file is needed to be in the jsx type. otherwise we are not able to use the object css styling we trying to make here.
 * @returns <StyledButton>
 */

// Create styled button
const StyledButton = styled('button', { shouldForwardProp: prop => ['hover', 'size'].indexOf(prop) === -1 })`
    ${({ hover }) =>
        hover &&
        `
        ${above.md} {
            ${media.hover}{
                &:hover {
                    ${hover.color ? `color: ${hover.color};` : null}
                    ${hover.borderColor ? `border-color: ${hover.borderColor};` : null}
                    ${hover.backgroundColor ? `background-color: ${hover.backgroundColor};` : null}
                }
            }
        }
    `};
`;

// Go over list of special sizes value and add to styling
const mapSize = (obj, key, prop) => {
    if (obj && key) {
        return Array.isArray(key) ? key.map(s => (s ? obj[s][prop] : null)) : obj[key][prop];
    }

    return null;
};

const Button = ({
    as,
    availableSizes = SIZES,
    size = 'md',
    styles = STYLES,
    theme = 'black',
    to,
    type = 'button',
    ...rest
}) => {
    // Update props
    theme = THEMES[theme ? theme : ''] || {};
    as = as || typeof to === 'string' ? BaseLink : undefined;

    // Deconstruct the theme object
    const { hover, disabled, ...restTheme } = theme || {};

    if (disabled) {
        restTheme[':disabled'] = {
            ...disabled,
            border: disabled.borderColor ? `1px solid ${disabled.borderColor}` : 'none',
            cursor: 'not-allowed',
        };
    }

    const newstyles = css({
        ...restTheme,
        ...styles,
    });

    return (
        <StyledButton
            as={as}
            border={hover?.borderColor ? `1px solid ${hover.borderColor}` : 'none'}
            css={newstyles}
            hover={hover}
            padding={styles.padding || mapSize(availableSizes, size, 'padding')}
            to={to}
            type={!as ? type : null}
            {...rest}
        />
    );
};

Button.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    availableSizes: PropTypes.object,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    styles: PropTypes.object,
    theme: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
};

export default Button;
