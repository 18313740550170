import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import OrreforsLogo from 'assets/icons/OrreforsLogo';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';

const Wrapper = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.grey4};
    background-color: ${colors.background};
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
`;

const CheckoutHeader = () => {
    const headerHeights = useHeaderHeights();
    const checkoutLink = useSelector(state => state.header.data.checkoutLink);

    return (
        <Wrapper height={[`${headerHeights.mobile}px`, null, null, `${headerHeights.desktop}px`]}>
            <MaxWidthWrapper includeContentMargins display="flex" justifyContent="space-between">
                <StyledLink to="/">
                    <OrreforsLogo color="black" />
                </StyledLink>
                <Link to={checkoutLink.to}>
                    <Paragraph textDecoration="underline">{checkoutLink.label}</Paragraph>
                </Link>
            </MaxWidthWrapper>
        </Wrapper>
    );
};

export default CheckoutHeader;
