/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import BackgroundImage from 'components/background/BackgroundImage';
import CmsModules from 'libs/wordpress/content/CmsModules';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ProductAccordion from 'components/accordions/ProductAccordion';
import ProductHero from 'components/products/ProductHero';
import ProductInformation from 'components/products/ProductInformation';
import ProductMedia from 'components/products/ProductMedia';
import ProductMeta from 'components/metadata/ProductMeta';
import ProductStickyAddToBasket from 'components/products/ProductStickyAddToBasket';
import ProductStructuredData from 'components/metadata/structuredData/ProductStructuredData';
import PropTypes from 'prop-types';
import RelatedProducts from 'components/products/RelatedProducts';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import { inServer } from 'config/constants';
import { mediaQueries } from 'config/theme/breakpoints';
import { scrollTo } from 'utils/scrollFunctions';
import { stringReplace } from 'utils/string';
import styled from 'libs/styled';
import transformHeading from 'libs/wordpress/utils/transformHeading';
import { transformProductPageData } from 'utils/dataTransformers/product';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useHeaderHeights from 'hooks/useHeaderHeights';
import useIsShowroom from 'hooks/useIsShowroom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const StyledBackgroundImage = styled(BackgroundImage)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
`;

const Content = styled('div')`
    position: relative;
    width: 100%;
    z-index: 1;
    background-color: ${colors.background};
    padding-bottom: 51px; /* Sticky bar height */

    ${above.lg} {
        padding-bottom: 53px; /* Sticky bar height */
    }

    /* Makes room for sticky bar above footer */
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -75px;
        height: 75px;
        width: 100vw;
        background-color: ${colors.background};

        ${above.lg} {
            bottom: -77px;
            height: 77px;
        }
    }
`;

const ProductView = ({ data, relationships = {} }) => {
    const isShowroom = useIsShowroom();
    const headerHeights = useHeaderHeights();
    const { t } = useTranslation();
    const isAboveMd = useAboveBreakpoint('md');
    const offsetScroll = (isAboveMd ? headerHeights.desktop : headerHeights.mobile) + headerHeights.banner;
    const customAttributesRelationship = useSelector(
        state => state.application.config.options.custom_attributes_relationship
    );

    const product = useMemo(() => transformProductPageData(data, relationships, customAttributesRelationship, t), [
        data.uri,
    ]);

    const stickyRef = useRef(null);
    const [state, setState] = useState({
        openAccordions: false,
        stickyPositionX: 0,
    });

    const {
        related_heading: relatedHeading,
        upsell_heading: upsellHeading,
        information_label: informationLabel,
        scroll_to_intro_label: scrollToIntroLabel,
        product_attributes_label: productAttributesLabel,
        product_information_label: productInformationLabel,
        scroll_to_specification_label: scrollToSpecificationLabel,
    } = useSelector(state => state.application.config.options.product_page);

    // Scroll to accordions
    const accordionRef = useRef(null);
    const scrollToAccordions = () => {
        setState(state => ({ ...state, openAccordions: true }));

        accordionRef.current &&
            scrollTo({
                top: !inServer ? accordionRef.current.offsetTop + window.innerHeight - offsetScroll : 0,
            });
    };

    // Scroll to short description
    const shortDescriptionRef = useRef(null);
    const scrollToShortDescription = () => {
        shortDescriptionRef.current &&
            scrollTo({
                top: !inServer ? shortDescriptionRef.current.offsetTop + window.innerHeight - offsetScroll : 0,
            });
    };

    useEffect(() => {
        const handleResize = () => {
            const distanceX = stickyRef?.current?.getBoundingClientRect().x;
            setState(state => ({ ...state, stickyPositionX: distanceX }));
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [stickyRef]);

    const relatedHeadingsVariables = {
        '{designer}': product.designers,
        '{series}': product.series,
    };

    const transformedRelatedHeading = transformHeading(relatedHeading);
    const transformedUpsellHeading = transformHeading(upsellHeading);

    if (transformedRelatedHeading.text) {
        transformedRelatedHeading.text = stringReplace(transformedRelatedHeading.text, relatedHeadingsVariables);
    }

    if (transformedUpsellHeading.text) {
        transformedUpsellHeading.text = stringReplace(transformedUpsellHeading.text, relatedHeadingsVariables);
    }

    const heroImageSrc = isAboveMd ? product.desktopHero?.src : product.mobileHero?.src;

    return (
        <>
            <ProductMeta transformedProduct={product} />
            <ProductStructuredData />
            {heroImageSrc && (
                <StyledBackgroundImage
                    position="50% 50%"
                    query={mediaQueries.fullWidth}
                    size="cover"
                    src={heroImageSrc}
                    zIndex="-1"
                />
            )}
            <ProductHero
                badges={product.badges}
                breadcrumbs={product.breadcrumbs}
                designers={product.designers}
                name={product.name}
                scrollToAccordionsLabel={scrollToIntroLabel}
                scrollToShortDescriptionLabel={scrollToSpecificationLabel}
                scrollToAccordions={scrollToAccordions}
                scrollToShortDescription={scrollToShortDescription}
                stickyRef={stickyRef}
            />
            <Content>
                <MaxWidthWrapper includeContentMargins>
                    <ProductInformation
                        designers={product.designers}
                        informationLabel={informationLabel}
                        name={product.name}
                        ref={shortDescriptionRef}
                        scrollToAccordions={scrollToAccordions}
                        shortDescription={product.shortDescription}
                    />
                </MaxWidthWrapper>
                {product.mediaGallery.length > 0 && (
                    <ProductMedia images={product.mediaGallery} productSku={product.sku} />
                )}
                {product.pageContent && <CmsModules isProductPage data={product.pageContent} />}
                <ProductAccordion
                    ref={accordionRef}
                    characteristics={product.characteristics}
                    characteristicsLabel={productAttributesLabel}
                    description={product.description}
                    descriptionLabel={productInformationLabel}
                    openAccordions={state.openAccordions}
                />
                {product.relatedProductsIds && (
                    <RelatedProducts
                        relatedHeading={transformedRelatedHeading}
                        upsellHeading={transformedUpsellHeading}
                        relatedProductsIds={product.relatedProductsIds}
                    />
                )}
            </Content>
            {!isShowroom && (
                <ProductStickyAddToBasket
                    currentProduct={product.currentProduct}
                    inStock={product.inStock}
                    positionLeft={state.stickyPositionX}
                    price={product.price}
                    variationId={product.variationId}
                />
            )}
        </>
    );
};

ProductView.propTypes = {
    data: PropTypes.object.isRequired,
    relationships: PropTypes.object,
};

export default ProductView;
