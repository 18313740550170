import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    position: sticky;
    bottom: 24px;
    padding: 0 12px;
`;

const Button = styled(ThemeButton)`
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
`;

const FilterStickyButton = ({ numberOfFilters = 0, openFilterOverlay }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Button size="xl" theme="blurWhite" onClick={() => openFilterOverlay()}>
                {t('filter_config.filter_and_sort')} {numberOfFilters > 1 && `(${numberOfFilters})`}
            </Button>
        </Wrapper>
    );
};

FilterStickyButton.propTypes = {
    numberOfFilters: PropTypes.number,
    openFilterOverlay: PropTypes.func.isRequired,
};

export default FilterStickyButton;
