/* eslint-disable camelcase */

interface Badge {
    text: string;
    theme: string;
}

interface CustomAttribute {
    display_text: string;
    internal_name: string;
    description: string;
}

interface CustomAttributeMap extends CustomAttribute {
    id: string;
    value?: string;
    values?: Record<string, CustomAttribute>;
}

interface Breadcrumb {
    title: string;
    permalink?: string;
}

type Wysiwyg = string | (string | object)[];

interface Characteristic {
    label: string;
    value: string | Wysiwyg;
}

interface Price {
    [key: string]: any;
}

interface Image {
    name: string;
    alt: string;
    src: string;
}

/**
 * Transform product price into desired structure
 */

export const transformProductPrice = (rawPrice: Record<string, any>): Price => {
    if (!rawPrice) {
        return {};
    }

    // Take a value that only exist in basket and check if it exists
    const isBasket = !!rawPrice.line;

    const discount = rawPrice.discount || 0;
    const discountPercentage = rawPrice.discount_percentage || 0;
    const discountWithCurrency = rawPrice.discount_with_currency;

    const fullPrice = isBasket ? rawPrice.total_price_before_discount_as_number : rawPrice.price;
    const fullPriceExVat = rawPrice.price_ex_vat;
    const fullPriceWithCurrency = isBasket ? rawPrice.total_price_before_discount : rawPrice.price_with_currency;

    const salePrice = isBasket ? rawPrice.total_price_as_number : rawPrice.sale_price;
    const salePriceExVat = rawPrice.sale_price_ex_vat;
    const salePriceWithCurrency = isBasket ? rawPrice.total_price : rawPrice.sale_price_with_currency;

    const vat = rawPrice.vat;
    const vatPercentage = rawPrice.vat_percentage;

    const currency = fullPriceWithCurrency && fullPriceWithCurrency.replace(/[0-9.\s]/g, '');
    const currencyID = rawPrice.currency;

    const badeNewProduct = rawPrice.new_product || false;
    const badgeOnSale = rawPrice.on_sale || false;
    const onSale = salePrice && fullPrice && salePrice < fullPrice;

    return {
        discount,
        discountPercentage,
        discountWithCurrency,
        price: fullPrice,
        priceExVat: fullPriceExVat,
        priceWithCurrency: fullPriceWithCurrency,
        fullPrice,
        fullPriceExVat,
        fullPriceWithCurrency,
        salePrice,
        salePriceExVat,
        salePriceWithCurrency,
        vat,
        vatPercentage,
        currency,
        currencyID,
        badeNewProduct,
        badgeOnSale,
        onSale,
    };
};

/**
 * Transform and add product badges so that it is the same across the page
 *
 * Prio order for badges
 * 1. Sold out
 * 2. Sale
 * 3. New
 * 4. Custom badges
 * 5. Recycled
 */

export const transformProductBadges = (
    rawCustomBadges: CustomAttributeMap | CustomAttribute[],
    inStock: boolean,
    newUntil: string,
    rawMaterials: CustomAttributeMap | CustomAttribute[],
    onSaleBadge: boolean,
    price: Price,
    t: (text: string) => string
): Badge[] => {
    const badges: Badge[] = [];

    // #1 Sold out
    if (!inStock) {
        badges.push({
            text: t('product_card.out_of_stock'),
            theme: 'soldOut',
        });
    }

    // #2 Sale
    if (onSaleBadge && price.discountPercentage > 0) {
        badges.push({
            text: `${price.discountPercentage}%`,
            theme: 'black',
        });
    }

    // #3 New
    if (newUntil) {
        const now = new Date();
        const newUntilDate = new Date(newUntil);

        if (now < newUntilDate) {
            badges.push({
                text: t('product_card.new'),
                theme: 'white',
            });
        }
    }

    // #4 Custom
    const customBadges =
        typeof rawCustomBadges?.values === 'object' ? Object.values(rawCustomBadges?.values) : rawCustomBadges;
    if (Array.isArray(customBadges)) {
        customBadges.forEach(badge => {
            badges.push({
                text: badge.display_text,
                theme: 'white',
            });
        });
    }

    // #5 Recycled
    const materials = rawMaterials?.values ? Object.values(rawMaterials?.values) : rawMaterials;
    if (Array.isArray(materials) && materials?.some(material => material.internal_name === 'atervunnet_glas')) {
        badges.push({
            text: t('product_card.recycled_glass'),
            theme: 'green',
        });
    }

    return badges;
};

/**
 * Transform the data for the product card so that it is the same across the page
 */

export const transformProductCard = (rawData: Record<string, any>, marketId: string, t: (text: string) => string) => {
    const inStock: boolean = marketId && rawData._in_stock?.includes(marketId);
    const transformedPrice = transformProductPrice(rawData.price);
    const badges = transformProductBadges(
        rawData._badges,
        inStock,
        rawData._new_until,
        rawData._materials,
        rawData._on_sale_badge,
        transformedPrice,
        t
    );

    const name: string = rawData.name;
    const sku: string = rawData.sku;
    const url: string = rawData.uri;
    const category: string = rawData._categories_names
        ? rawData._categories_names[rawData._categories_names.length - 1]
        : '';
    const designer: string = rawData?._designers.join(', ');
    const primaryImage: string = rawData._first_image;
    const hoverImage: string = rawData._hover_image;

    return {
        badges: badges.slice(0, 2),
        id: parseInt(rawData.id, 10),
        inStock,
        name,
        sku,
        url,
        category,
        designer,
        price: transformedPrice,
        primaryImage,
        hoverImage,
    };
};

/**
 * Transform the data for the product card basket
 * This transform is used both on the product page to prepare "AddedToBasket"
 * and in the checkout/basket. The data structure differs on these places.
 */

export const transformProductCardMini = (rawData: Record<string, any>) => {
    const fullPrice: number = rawData.price?.price || rawData.total_price_before_discount_as_number;
    const salePrice: number = rawData.price?.sale_price || rawData.total_price_as_number;
    const priceWithCurrency: string = rawData.price?.price_with_currency || rawData.total_price_before_discount;
    const salePriceWithCurrency: string = rawData.price?.sale_price_with_currency || rawData.total_price;

    const rawDesigners: Record<string, CustomAttribute> =
        rawData.custom_attributes?.sa_designers?.values ||
        rawData.product_reference?.custom_attributes?.sa_designers?.values;

    const designers = rawDesigners
        ? Object.values(rawDesigners)
              .map(designer => designer.display_text)
              .join(', ')
        : '';

    const name = `${rawData.name || rawData.product_reference?.name}`;
    const id = `${rawData.id}`;
    const imageUrl = `${rawData.media[0]?.sizes?.original?.url}`;
    const quantity: number = rawData.quantity;
    const url = `${rawData.uri || rawData.url}`;

    return {
        designers,
        id,
        image: imageUrl,
        line: rawData.line,
        name,
        onSale: (salePrice && fullPrice && salePrice < fullPrice) || false,
        priceWithCurrency,
        quantity,
        salePriceWithCurrency,
        url,
    };
};

/**
 * Transform the data for product page to make the index-file easier to follow
 */

export const transformProductPageData = (rawData, pageRelationships, customAttributesRelationship, t) => {
    const {
        custom_attributes: nonTypedcustomAttributes,
        description,
        id,
        media,
        name,
        price,
        related_products: unfilteredRelatedProducts,
        short_description: shortDescription,
        sku,
        uri,
        variations,
    } = rawData;

    const customAttributes: Record<string, CustomAttributeMap> = nonTypedcustomAttributes;

    /* Find most precise breadcrumbs */
    let breadcrumbs: Breadcrumb[] = [];
    const initialBreadcrumbs: Breadcrumb[] = [];

    if (pageRelationships?.ecommerce_product_category) {
        const ecommerceCategoryData: object[] = Object.values(pageRelationships.ecommerce_product_category);

        breadcrumbs = ecommerceCategoryData.reduce(
            (currentBreadcrumbs: Breadcrumb[], category: Record<string, any>) => {
                if (category && currentBreadcrumbs.length < category.breadcrumbs.length + 1) {
                    currentBreadcrumbs = category.breadcrumbs.map(ancestor => {
                        const breadcrumb: Breadcrumb = {
                            title: ancestor.title,
                            permalink: ancestor.permalink,
                        };
                        return breadcrumb;
                    });

                    const breadcrumb: Breadcrumb = {
                        title: category.title,
                        permalink: category.permalink,
                    };

                    currentBreadcrumbs.push(breadcrumb);
                }

                return currentBreadcrumbs;
            },
            initialBreadcrumbs
        );
    }

    /* Merge and setup page content */
    const rawPageContent: object = pageRelationships?.product_page_content || {};
    const rawPageContentArray: object[][] = Object.values(rawPageContent);
    const pageContent = rawPageContentArray.reduce(
        (allModules: object[], moduleGroup: object[]) => [...allModules, ...moduleGroup],
        []
    );

    /* Save related products ids */
    const relatedProductsIds = unfilteredRelatedProducts.reduce((acc, related) => {
        if (!acc[related.relation]) {
            acc[related.relation] = [];
        }

        acc[related.relation].push(parseInt(related.id, 10));

        return acc;
    }, {});

    /* Get custom attribut information */
    const {
        sa_care_instructions: rawCareInstructions,
        sa_badges: rawBadges,
        sa_designers: rawDesigners,
        sa_diameter_display: diameter,
        sa_height_display: height,
        sa_hero_image_index_desktop_index: rawHeroImageDesktopIndex,
        sa_hero_image_index_mobile_index: rawHeroImageMobileIndex,
        sa_length_display: length,
        sa_materials: rawMaterials,
        sa_new_until: newUntil,
        sa_series: rawSeries,
        sa_on_sale: rawOnSaleBadge,
        sa_volume_display: volume,
        sa_width_display: width,
    } = customAttributes;

    /* Transform media data */
    const transformedMedia: Image[] = media.map(
        ({ alt, name, sizes }): Image => ({ name, alt, src: sizes.original.url })
    );

    // Get selected indexes, if any, from product data
    // If media only contains one image, set index to 0, else set initial index from Centra
    const getIndex = (rawIndex: any) =>
        rawIndex?.value && transformedMedia.length !== 1 ? parseInt(rawIndex.value, 10) : null;

    const heroImageDesktopIndex = getIndex(rawHeroImageDesktopIndex);
    const heroImageMobileIndex = getIndex(rawHeroImageMobileIndex);

    // Select hero images with indexes
    const desktopHero = heroImageDesktopIndex ? transformedMedia[heroImageDesktopIndex] : null;
    const mobileHero = heroImageMobileIndex ? transformedMedia[heroImageMobileIndex] : null;

    // Create array of indexes to remove from media gallery
    const indexesToRemove: number[] = [];
    if (heroImageDesktopIndex) {
        indexesToRemove.push(heroImageDesktopIndex);
    }
    if (heroImageMobileIndex) {
        indexesToRemove.push(heroImageMobileIndex);
    }

    const mediaGallery = transformedMedia
        .map((media, i) => (indexesToRemove.includes(i) ? null : media))
        .filter(media => media);

    /* Transfrom characteristics */
    const characteristics: Characteristic[] = [];

    // Transfrom customAttributesRelationship to a map for use with characteristics
    const attrRelationshipMap = customAttributesRelationship
        ? customAttributesRelationship.reduce((map, attr) => {
              map[attr.internal_name.trim()] = attr.relationship?.url;
              return map;
          }, {})
        : {};

    if (sku) {
        characteristics.push({
            label: t('product_page.accordions.articlenumber'),
            value: sku,
        });
    }

    const designers = rawDesigners?.values
        ? Object.values(rawDesigners.values)
              .map(designer => designer.display_text)
              .join(', ')
        : '';

    if (designers) {
        const designersWysiwyg: Wysiwyg = [];
        // each product can have several desginers so loop trough them
        if (rawDesigners.values) {
            Object.values(rawDesigners.values).forEach((designer, i) => {
                if (i > 0) {
                    designersWysiwyg.push(', ');
                }

                if (attrRelationshipMap[designer.internal_name]) {
                    // Add a link to the designers if its present in attrRelationshipMap
                    designersWysiwyg.push({
                        'tag': 'url',
                        'content': designer.display_text,
                        'attributes': {
                            'data-url': attrRelationshipMap[designer.internal_name],
                        },
                    });
                } else {
                    // Add designer as plain text
                    designersWysiwyg.push(designer.display_text);
                }
            });
        }

        characteristics.push({
            label: t('product_page.accordions.designers'),
            value: designersWysiwyg,
        });
    }

    const series = rawSeries?.display_text;
    if (series) {
        const seriesWysiwyg: Wysiwyg = [];
        if (attrRelationshipMap[rawSeries.internal_name]) {
            // Add a link to the serie if its present in attrRelationshipMap
            seriesWysiwyg.push({
                'tag': 'url',
                'content': rawSeries.display_text,
                'attributes': {
                    'data-url': attrRelationshipMap[rawSeries.internal_name],
                },
            });
        } else {
            // Add serie as plain text
            seriesWysiwyg.push(rawSeries.display_text);
        }

        characteristics.push({
            label: t('product_page.accordions.series'),
            value: seriesWysiwyg,
        });
    }

    if (width) {
        characteristics.push({
            label: t('product_page.accordions.width'),
            value: `${width.value}mm`,
        });
    }

    if (height) {
        characteristics.push({
            label: t('product_page.accordions.height'),
            value: `${height.value}mm`,
        });
    }

    const volumeVal = volume ? `${volume.value}cl` : undefined;
    if (volumeVal) {
        characteristics.push({
            label: t('product_page.accordions.volume'),
            value: volumeVal,
        });
    }

    if (diameter) {
        characteristics.push({
            label: t('product_page.accordions.diameter'),
            value: `${diameter.value}mm`,
        });
    }

    if (length) {
        characteristics.push({
            label: t('product_page.accordions.length'),
            value: `${length.value}mm`,
        });
    }

    const materials = rawMaterials?.values ? Object.values(rawMaterials.values) : [];
    if (materials) {
        characteristics.push({
            label: t('product_page.accordions.materials'),
            value: materials
                .map(material => material.display_text)
                .filter(material => material)
                .join(', '),
        });

        const materialsDescription = `${materials
            .map(material => material.description)
            .filter(material => material)
            .join('. ')}${materials.length > 1 ? '.' : ''}`;

        if (materialsDescription.length > 1) {
            characteristics.push({
                label: t('product_page.accordions.materials_description'),
                value: materialsDescription,
            });
        }
    }

    if (rawCareInstructions?.values) {
        const rawCareInstructionsValues = Object.values(rawCareInstructions.values);
        characteristics.push({
            label: t('product_page.accordions.care_instructions'),
            value: `${rawCareInstructionsValues
                .map(instruction => instruction.description)
                .filter(instruction => instruction)
                .join('. ')}${rawCareInstructionsValues.length > 1 ? '.' : ''}`,
        });
    }

    /* Setup current product with only values that we need. Used by notification in AddToBasketButton. */
    const transformedPrice = transformProductPrice(price);
    const currentProduct = transformProductCardMini(rawData);

    /* Transform badges */
    const inStock = variations.some(variation => variation.in_stock);
    const onSaleBadge = rawOnSaleBadge?.value === '1';
    const badges = transformProductBadges(
        rawBadges,
        inStock,
        newUntil?.value || '',
        materials,
        onSaleBadge,
        transformedPrice,
        t
    );

    const typedDescription: string = description;
    const typedId: string = id;
    const typedInStock: boolean = inStock;
    const typedName: string = name;
    const typedRelatedProductsIds: string[] = relatedProductsIds;
    const typedShortDescription: string = shortDescription;
    const typedSku: string = sku;
    const typedUrl: string = uri;
    const typedVariationId: string = variations[0].id;

    return {
        badges,
        breadcrumbs,
        currentProduct,
        characteristics,
        description: typedDescription,
        designers,
        series,
        desktopHero,
        id: typedId,
        inStock: typedInStock,
        mediaGallery,
        mobileHero,
        name: typedName,
        pageContent,
        price: transformedPrice,
        relatedProductsIds: typedRelatedProductsIds,
        shortDescription: typedShortDescription,
        sku: typedSku,
        url: typedUrl,
        variationId: typedVariationId,
        volume: volumeVal,
    };
};

/**
 * Transform the data for product page to make the index-file easier to follow
 */

export const transformAlgoliaProductResponse = (response, application) => {
    const clonedResponse = JSON.parse(JSON.stringify(response));

    if (clonedResponse.hits && clonedResponse.hits.length) {
        clonedResponse.hits = clonedResponse.hits
            .map(product => {
                // Go through all the variations if we have any.
                if (Array.isArray(product.variations)) {
                    product.variations = product.variations.map(variation => {
                        // Legacy support for in_stock
                        if (variation._in_stock_by_marketplace) {
                            // Set the in_stock variable to true or false if we have this variation in stock for this market.
                            variation.in_stock =
                                variation._in_stock_by_marketplace.indexOf(application.shop_config.market_id) !== -1;
                            // Delete the variations stock by market, we only need the market we are currently on.
                            delete variation._in_stock_by_marketplace;
                            // Return the variation.
                        }
                        return variation;
                    });
                }
                // Return the product
                return product;
            })
            .filter(p => p.price !== null);
    }

    return clonedResponse;
};
