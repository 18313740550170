import FilterListItem from 'components/Filter/FilterListItem';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Filter = styled('div')`
    cursor: pointer;
`;

const FilterListOption = ({ filters, handleChange, parentFilterKey }) =>
    filters
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        })
        .map(item => (
            <Filter key={item.value} onClick={() => handleChange(parentFilterKey, item.value)}>
                <FilterListItem text={item.value} isActive={item.selected} />
            </Filter>
        ));

FilterListOption.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, selected: PropTypes.bool })).isRequired,
    handleChange: PropTypes.func.isRequired,
    parentFilterKey: PropTypes.string.isRequired,
};

export default FilterListOption;
