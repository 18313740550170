import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';

const Plus = ({ color = 'black', height = '24px', viewBox = '0 0 24 24', width = '24px', ...props }) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...props}>
        <Path
            d="M12.0001 -7.03008e-05L12.0001 12M12.0001 12L12.0001 24.0001M12.0001 12L24.0001 12M12.0001 12L4.76841e-07 12"
            stroke={color}
            strokeWidth="2"
        />
    </SVG>
);

Plus.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Plus;
