import CloseSmall from 'assets/icons/CloseSmall';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    padding: 12px 0 12px 12px;
    border-bottom: 1px solid ${colors.grey4};
    border-top: 1px solid ${colors.grey4};
    margin-top: -1px;

    ${above.md} {
        border-top: none;
        padding: 12px 0px 12px 24px;
    }
`;

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
    display: flex;
    align-items: center;
`;

const ItemsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    /* To get a space at the end of the content blocks */
    &::after,
    &::before {
        content: '';
        min-width: 12px;
        display: block;
        height: 1px;
    }
    &::after {
        ${above.md} {
            min-width: 24px;
        }
    }
`;

const SelectedFilter = ({ selectedFilters = [], handleChange = {}, clear = {} }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <StyledMaxWidthWrapper>
                <Paragraph whiteSpace="nowrap">{t('filter_config.active_filter')}:</Paragraph>
                <ScrollWrapper
                    displayFadeEffect
                    direction="horizontal"
                    height="unset"
                    scrollContentProps={{ id: 'SelectedFilterScroll' }}
                >
                    <ItemsWrapper>
                        {selectedFilters.length > 0 && (
                            <ThemeButton
                                fontSize={['1.4rem', null, '1.6rem']}
                                mr="12px"
                                size="xs"
                                theme="black"
                                onClick={clear.filter}
                            >
                                {t('filter_config.clear_filter')}
                                <CloseSmall color="currentColor" ml="6px" />
                            </ThemeButton>
                        )}
                        {selectedFilters.map(({ key, label }, index) => (
                            <ThemeButton
                                fontSize={['1.4rem', null, '1.6rem']}
                                key={key}
                                mr={index !== selectedFilters.length - 1 ? '16px' : '0'}
                                size="xs"
                                theme="outlinedBlack"
                                onClick={() => handleChange.value(key, label)}
                            >
                                {label} <CloseSmall color="currentColor" ml="6px" />
                            </ThemeButton>
                        ))}
                    </ItemsWrapper>
                </ScrollWrapper>
            </StyledMaxWidthWrapper>
        </Wrapper>
    );
};

SelectedFilter.propTypes = {
    clear: PropTypes.object,
    handleChange: PropTypes.shape({
        value: PropTypes.func,
        range: PropTypes.func,
    }),
    selectedFilters: PropTypes.arrayOf(
        PropTypes.exact({ key: PropTypes.string, label: PropTypes.string, value: PropTypes.string })
    ).isRequired,
};

export default SelectedFilter;
