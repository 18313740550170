import Paragraph from 'components/text/Paragraph';
import React from 'react';
import basketProp from 'utils/proptypes/basket';
import { injectModels } from 'state';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const BasketTotals = ({ basket }) => {
    const { t } = useTranslation();
    const { shipping_price: shippingPrice, final_price: finalPrice } = basket.totals;

    return (
        <Div display="flex" justifyContent="space-between" mb={['16px', null, '24px']}>
            <Div>
                <Paragraph mb={['8px', null, '12px']}>{t('checkout_basket.shipping')}:</Paragraph>
                <Paragraph size="xxl">{shippingPrice}</Paragraph>
            </Div>

            <Div textAlign="right">
                <Paragraph mb={['8px', null, '12px']}>{t('checkout_basket.to_pay')}:</Paragraph>
                <Paragraph size="xxl">{finalPrice}</Paragraph>
            </Div>
        </Div>
    );
};

BasketTotals.propTypes = {
    basket: basketProp.isRequired,
};

export default injectModels(['basket'])(BasketTotals);
