import LevelTwoDesktop from 'Header/Default/Menu/Desktop/LevelTwo';
import React from 'react';
import { useSelector } from 'react-redux';

const MenuDesktop = () => {
    const { mainMenu, generalLinks } = useSelector(state => state.header.data.navigation);
    const activeMenu = useSelector(state => state.header.state.activeMenu);

    if (!mainMenu) {
        return null;
    }

    return mainMenu.map(({ featuredLinks, headerLink, subMenus }, index) => (
        <LevelTwoDesktop
            active={activeMenu === index}
            featuredLinks={featuredLinks}
            generalLinks={generalLinks}
            headerLink={headerLink}
            key={headerLink.label}
            subMenus={subMenus}
        />
    ));
};

export default MenuDesktop;
