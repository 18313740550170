import CloseBig from 'assets/icons/CloseBig';
import Image from 'components/products/productCardMini/Image';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
`;

const Info = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-left: 12px;
    width: 70%;
`;

const Name = styled(Link)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Icon = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 30%;
`;

const Span = styled('span')`
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
`;

const AddedToBasket = ({ closePopup, image, name, onClickPermalink, openBasket, url }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Image name={name} productLink={url} imageUrl={image} toggleBasket={() => onClickPermalink()} />
            <Info>
                <Name to={url} onClick={closePopup}>
                    {name}
                </Name>
                <Paragraph>
                    {t('checkout_basket.added_to_your')}
                    <Span
                        onClick={() => {
                            closePopup();
                            openBasket();
                        }}
                    >
                        {t('checkout_basket.basket')}
                    </Span>
                </Paragraph>
            </Info>
            <Icon>
                <CloseBig cursor="pointer" onClick={closePopup} />
            </Icon>
        </Wrapper>
    );
};

AddedToBasket.propTypes = {
    closePopup: PropTypes.func.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClickPermalink: PropTypes.func,
    openBasket: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
};

export default AddedToBasket;
