import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';

const Arrow = ({ color = 'black', height = '42px', viewBox = '0 0 42 42', width = '42px', ...props }) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...props}>
        <Path d="m14 21h13" stroke={color} strokeWidth="1.25" />
        <Path d="m21 15 6 6-6 6" stroke={color} strokeWidth="1.25" />
        <rect height="39" rx="19.5" width="39" x="1.5" y="1.5" stroke={color} />
    </SVG>
);

Arrow.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Arrow;
