import React, { useEffect, useState } from 'react';

import Arrow from 'assets/icons/Arrow';
import MouseBig from 'assets/icons/scroll/AnimatedScrollDown/MouseBig';
import MouseSmall from 'assets/icons/scroll/AnimatedScrollDown/MouseSmall';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import debounce from 'lodash/debounce';
import { inServer } from 'config/constants';
import styled from 'libs/styled';

const Wrapper = styled('div', { shouldForwardProp: prop => ['show'].indexOf(prop) === -1 })`
    width: 26px;
    height: 53px;
    opacity: ${({ show }) => (show ? '1' : '0')};

    ${above.md} {
        width: 34px;
        height: 56px;
    }
`;

const MouseWrapper = styled('div')`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Animate = styled('div')`
    position: absolute;
    top: 0;
    left: 50%;

    @keyframes scroll-down {
        0% {
            opacity: 0;
            transform: translate(-50%, 0%);
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translate(-50%, 100%);
        }
    }

    animation-duration: 2.5s;
    animation-name: scroll-down;
    animation-iteration-count: infinite;
`;

const AnimatedScrollDownIcon = ({ color = colors.white, ...rest }) => {
    const removeIconAfter = 10;

    const [show, setShow] = useState(false);

    // Show icon after 3 seconds
    const startTimer = () => {
        window.timer = setTimeout(() => {
            setShow(true);
        }, 3000);
    };

    // Remove icon and clear timer
    const stopTimer = () => {
        if (!inServer) {
            clearTimeout(window.timer);
            setShow(false);
        }
    };

    // Start/stop/clear timer and add/remove eventListener for scroll
    useEffect(() => {
        startTimer();

        const handleScroll = () => {
            if (window.innerHeight > removeIconAfter) {
                stopTimer();
            }
        };

        const delayScroll = debounce(handleScroll, 10);
        window.addEventListener('scroll', delayScroll);

        return () => {
            clearTimeout(window.timer);
            window.removeEventListener('scroll', delayScroll);
        };
    }, []);

    return (
        <Wrapper show={show} {...rest}>
            <MouseWrapper>
                <MouseSmall display={['block', null, 'none']} color={color} />
                <MouseBig display={['none', null, 'block']} color={color} />
            </MouseWrapper>
            <Animate>
                <Arrow transform="rotate(90deg)" color={color} />
            </Animate>
        </Wrapper>
    );
};

AnimatedScrollDownIcon.propTypes = {
    color: PropTypes.string,
};

export default AnimatedScrollDownIcon;
