/* eslint-disable camelcase */
import { ContentComponent, ContentComponents } from 'libs/wordpress/utils/cmsComponentHandlers';

import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import React from 'react';
import loadable from '@loadable/component';

const AccordionLayout = loadable(() => import('libs/wordpress/content/cmsLayouts/customerService/AccordionLayout'), { fallback: <Loading /> });
const ArticleFlowLayout = loadable(() => import('libs/wordpress/content/cmsLayouts/default/ArticleFlowLayout'), { fallback: <Loading /> });
const CallToActionBasicModule = loadable(() => import('libs/wordpress/content/cmsModules/default/CallToActionBasicModule'), { fallback: <Loading /> });
const CallToActionHeroModule = loadable(() => import('libs/wordpress/content/cmsModules/default/CallToActionHeroModule'), { fallback: <Loading /> });
const CallToActionModule = loadable(() => import('libs/wordpress/content/cmsModules/default/CallToActionModule'), { fallback: <Loading /> });
const ImageInlineModule = loadable(() => import('libs/wordpress/content/cmsModules/default/ImageInlineModule'), { fallback: <Loading />});
const InformationModule = loadable(() => import('libs/wordpress/content/cmsModules/default/InformationModule'), { fallback: <Loading />});
const LinkListModule = loadable(() => import('libs/wordpress/content/cmsModules/default/LinkListModule'), { fallback: <Loading /> });
const NewsletterModule = loadable(() => import('libs/wordpress/content/cmsModules/default/NewsletterModule'), { fallback: <Loading /> });
const ProductsPromotionLayout = loadable(() => import('libs/wordpress/content/cmsLayouts/default/ProductsPromotionLayout'), { fallback: <Loading /> });
const QuoteModule = loadable(() => import('libs/wordpress/content/cmsModules/default/QuoteModule'), { fallback: <Loading /> });
const TextLayout = loadable(() => import('libs/wordpress/content/cmsLayouts/default/TextLayout'), { fallback: <Loading /> });
const TextLayoutCustomerService = loadable(() => import('libs/wordpress/content/cmsLayouts/customerService/TextLayout'), { fallback: <Loading /> });
const TwoColumnsLayout = loadable(() => import('libs/wordpress/content/cmsLayouts/default/TwoColumnsLayout'), { fallback: <Loading /> });
const TwoColumnsLayoutCustomerService = loadable(() => import('libs/wordpress/content/cmsLayouts/customerService/TwoColumnsLayout'), { fallback: <Loading /> });
const VideoInlineModule = loadable(() => import('libs/wordpress/content/cmsModules/default/VideoInlineModule'), { fallback: <Loading /> });
const VideoPlayerModule = loadable(() => import('libs/wordpress/content/cmsModules/default/VideoPlayerModule'), { fallback: <Loading /> });

/**
 * NOTE This file ignores prettier to increase readability by keeping all loadable imports on their own lines
 */

// Object of avalible modules/layouts and their keys
const availableContentComponents = {
    // Add the modules/layouts that exists in core/views directly in here.
    // We don't want to dynamically import them as that would cause circular references.

    // All default modules/layouts
    '2_columns_module': TwoColumnsLayout,
    'article_flow_module': ArticleFlowLayout,
    'background_image_module': ImageInlineModule,
    'call_to_action_basic_module': CallToActionBasicModule,
    'call_to_action_hero_module': CallToActionHeroModule,
    'call_to_action_module': CallToActionModule,
    'information_module': InformationModule,
    'link_list_module': LinkListModule,
    'newsletter_module': NewsletterModule,
    'products_promotion_module': ProductsPromotionLayout,
    'quote_module': QuoteModule,
    'text_block_module': TextLayout,
    'video_inline_module': VideoInlineModule,
    'video_player_module': VideoPlayerModule,

    // Custom modules/layouts for Articles
    '2_columns_article_module': TwoColumnsLayout,
    'call_to_action_article_module': CallToActionModule,
    'image_article_module': ImageInlineModule,
    'products_promotion_article_module': ProductsPromotionLayout,
    'quote_article_module': QuoteModule,
    'text_block_article_module': TextLayout,
    'video_inline_article_module': VideoInlineModule,
    'video_player_article_module': VideoPlayerModule,

    // Custom modules/layouts for Customer Service
    '2_columns_cs_module': TwoColumnsLayoutCustomerService,
    'text_block_cs_module': TextLayoutCustomerService,
    'accordion_cs_module': AccordionLayout,
};

/**
 * Accepts raw module data from the cms and tries to match the data to a cmsModule/cmsLayout.
 * If a match is found the cmsModule/cmsLayout is returned and rendered.
 * @param {(object|object[])} data - Raw module data from the cms. Can be a object or an array of objects.
 * @param {...*} rest - All additional props will be forwarded to the cmsModule/cmsLayout.
 */

const CmsModules = ({ data, ...rest }) => {
    if (!data) {
        return null;
    } else if (Array.isArray(data)) {
        return <ContentComponents data={data} availableContent={availableContentComponents} {...rest} />;
    }
    return <ContentComponent data={data} availableContent={availableContentComponents} {...rest} />;
};

CmsModules.propTypes = {
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};

export default CmsModules;
