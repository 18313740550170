import React, { useLayoutEffect, useMemo } from 'react';

import AspectWrapper from 'components/wrappers/AspectWrapper';
import Badges from 'components/products/ProductCard/Badges';
import Events from 'libs/Events';
import Link from 'components/base/Link';
import Price from 'components/products/Price';
import ProductCardImage from 'components/products/ProductCard/ProductCardImage';
import { ProductEvents } from 'libs/Events/constants';
import PropTypes from 'prop-types';
import Text from 'components/text/Paragraph';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import placeholder from 'assets/images/placeholder-big.svg';
import styled from 'libs/styled';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useIsShowroom from 'hooks/useIsShowroom';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['history', 'location', 'match', 'staticContext'].indexOf(prop) === -1,
})`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const StyledBadges = styled(Badges)`
    position: absolute;
    left: 8px;
    bottom: 8px;

    ${above.md} {
        left: 16px;
        bottom: 16px;
    }
`;

const Info = styled(Link)`
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: 8px 4px 0;
    font-size: 1.4rem;
    line-height: 1.2;

    ${above.md} {
        padding: 16px 0 0;
        font-size: 1.6rem;
    }
`;

const NameAndDesigner = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: inherit;
    line-height: inherit;
`;

const Name = styled('h3')`
    font-size: inherit;
    line-height: inherit;
    color: ${colors.black};
    margin: auto 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

const Designer = styled(Text)`
    font-size: inherit;
    line-height: inherit;
    margin: 0 0 4px;

    ${above.md} {
        margin-bottom: 0;
    }
`;

const ProductCard = ({
    badges = [],
    category = '',
    designer = '',
    hoverImage = '',
    id,
    imagePaddingTop = '',
    imageSizes,
    largeLayout = false,
    name,
    position,
    price: priceObj,
    primaryImage,
    seeProductButton = false,
    sku,
    srcWidths,
    url,
    // InStock is added as it fixes propTypes-error
    // eslint-disable-next-line react/prop-types
    inStock,
    ...rest
}) => {
    const { t } = useTranslation();
    const isShowRoom = useIsShowroom();
    const { price, salePrice, onSale, priceWithCurrency, salePriceWithCurrency } = priceObj;
    const product = useMemo(
        () => ({
            category,
            name,
            price,
            salePrice,
            sku,
            position,
        }),
        [category, name, price, salePrice, sku, position]
    );

    const trackHandler = () => {
        Events.trigger(ProductEvents.CLICK, { product });
    };

    const [entry, ref] = useIntersectionObserver({ triggerOnce: true });
    useLayoutEffect(() => {
        if (entry.target && entry.isIntersecting) {
            Events.trigger(ProductEvents.IMPRESSION, {
                products: [product],
            });
        }
    }, [entry, product]);

    return (
        <Wrapper {...rest} ref={ref}>
            <AspectWrapper ratio={3 / 4} paddingTop={imagePaddingTop}>
                <Link to={url} onClick={trackHandler}>
                    <ProductCardImage
                        alt={name}
                        hoverImage={hoverImage ?? ''}
                        imageSizes={imageSizes}
                        primaryImage={primaryImage ?? placeholder}
                        srcWidths={srcWidths}
                        title={name}
                    />
                    {badges?.length > 0 && <StyledBadges badges={badges} />}
                    {seeProductButton && (
                        <ThemeButton
                            border="none !important"
                            bottom="16px"
                            position="absolute"
                            right="16px"
                            size="sm"
                            theme="blurWhite"
                        >
                            {t('product_card.see_product')}
                        </ThemeButton>
                    )}
                </Link>
            </AspectWrapper>
            <Info
                alignItems={largeLayout ? 'flex-end' : [null, null, null, 'flex-end']}
                flexDirection={largeLayout ? 'row' : ['column', null, null, 'row']}
                to={url}
                onClick={trackHandler}
            >
                <NameAndDesigner>
                    {name && <Name>{name}</Name>}
                    {designer && <Designer as="p">{designer}</Designer>}
                </NameAndDesigner>
                {!isShowRoom && (
                    <Price
                        fontSize={['1.4rem', null, null, '1.6rem']}
                        priceWithCurrency={priceWithCurrency}
                        salePriceWithCurrency={salePriceWithCurrency}
                        onSale={!!onSale}
                    />
                )}
            </Info>
        </Wrapper>
    );
};

ProductCard.propTypes = {
    badges: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            theme: PropTypes.string,
        })
    ),
    category: PropTypes.string,
    designer: PropTypes.string,
    hoverImage: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    imagePaddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    imageSizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    largeLayout: PropTypes.bool,
    name: PropTypes.string.isRequired,
    position: PropTypes.number, // tracking position:
    price: PropTypes.object.isRequired,
    primaryImage: PropTypes.string.isRequired,
    seeProductButton: PropTypes.bool,
    sku: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    srcWidths: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    url: PropTypes.string.isRequired,
};

export default withRouter(ProductCard);
