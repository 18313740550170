import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';

// Global font-size and line-height is set in components/styles/global.css
const Paragraph = styled('p')`
    ${({ size }) =>
        size === 'sm' &&
        `
        font-size: 1.4rem;
     
        ${above.lg}{
            font-size: 1.6rem;
        
        }
    `}

    ${({ size }) =>
        size === 'md' &&
        `
        font-size: 1.6rem;

        ${above.lg}{
            font-size: 1.8rem; 
        }
	
    `}
    
    ${({ size }) =>
        size === 'lg' &&
        `
        font-size: 1.8rem;
     
        ${above.lg}{
            font-size: 2rem;
        
        }
    `}

    ${({ size }) =>
        size === 'xl' &&
        `
        font-size: 2.4rem;
     
        ${above.lg}{
            font-size: 3.2rem;
        
        }
    `}

    ${({ size }) =>
        size === 'xxl' &&
        `
        font-size: 2.4rem;
     
        ${above.lg}{
            font-size: 3.6rem;
        
        }
    `}
`;

Paragraph.propTypes = {
    as: PropTypes.string,
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
};

Paragraph.defaultProps = {
    as: 'p',
    size: '',
};

export default Paragraph;
