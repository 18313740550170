import PropTypes from 'prop-types';
import React from 'react';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';

const FlexibleAspectWrapper = styled('div', { shouldForwardProp: prop => ['ratios'].indexOf(prop) === -1 })`
    /* Style for flexible AspectWrapper */
    /* This style will allow the component to have a fixed ratio, but if the content dose not fit that ratio it will allow the element to grow */

    display: flex;
    align-items: stretch;

    /* ::before and ::after will make the wrapper have a ratio, but if the content need more space the height is flexible */
    &::before {
        content: "";
        float: left;
        ${({ ratios }) => responsiveBreakpointsStyle(ratios, `padding-bottom: calc(100% / {value});`)}
    }
    &::after {
        display: table;
        content: "";
        clear: both;
    }

    /* This will make sure that the content is positioned properly */
    > div {
        width: 100%;
        height: auto;
    }
`;

const FixedAspectWrapper = styled('div', { shouldForwardProp: prop => ['ratio'].indexOf(prop) === -1 })`
    /* Style for fixed AspectWrapper */
    /* This style will set the element to a fixed ratio that can't be changed by its content */

    position: relative;

    /* ::before the wrapper have a ratio */
    &::before {
        display: block;
        content: '';
        width: 100%;
        ${({ ratios }) => responsiveBreakpointsStyle(ratios, 'padding-bottom: calc(100% / {value});')}
    }

    /* This will make sure that the content is positioned properly */
    > div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
`;

/**
 * AspectWrapper - A wrapper that creates a component with a fixed ratio.
 * @param {node} children - Child components
 * @param {boolean} flexibleHeight - If true, the wrapper can adjust height if content doesn't fit
 * @param {number} ratio - The ratio used by the wrapper
 * @param {object} rest - Spread remaning props to wrapper, used to add class, styles etc.
 */

const AspectWrapper = ({ children, flexibleHeight = false, ratio = 3 / 1, ...rest }) => {
    // FlexibleAspectWrapper will allow the AspectWrapper to adjust its height if the content dosen't fit
    return flexibleHeight ? (
        <FlexibleAspectWrapper ratios={ratio} {...rest}>
            {/* Use a div to make sure that the content fills its parent correclty */}
            <div>{children}</div>
        </FlexibleAspectWrapper>
    ) : (
        // FixedAspectWrapper always have the same ratio, even if the content doesn't fit
        <FixedAspectWrapper ratios={ratio} {...rest}>
            {/* Use a div to make sure that the content fills its parent correclty */}
            <div>{children}</div>
        </FixedAspectWrapper>
    );
};

AspectWrapper.propTypes = {
    children: PropTypes.node,
    flexibleHeight: PropTypes.bool,
    ratio: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    ]),
};

export default AspectWrapper;
