import React, { Fragment } from 'react';

import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import withHeightAuto from 'utils/withHeightAuto';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0;
`;

const InnerWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const AnimationWrapper = withHeightAuto(styled('div')`
    overflow: hidden;
`);

const SingleAccordion = ({
    anchorTag = '',
    animationDuration = 0,
    borderBottom = true,
    children,
    colorText = colors.black,
    handleClick,
    headerPX = '12px',
    headerPY = '16px',
    headingSize = 'xs',
    headingWeight = '400',
    isActive = false,
    maxWithContent = false,
    plusSize = '16px',
    showPlusIcon = true,
    text = '',
    timingFunction = 'linear',
    url = '',
}) => {
    // Add MaxWidthWrapper with includeContentMargins prop if maxWithContent is true
    const RenderMaxWidth = maxWithContent ? MaxWidthWrapper : Fragment;
    const RenderMaxWidthProps = maxWithContent ? { includeContentMargins: true } : {};

    return (
        <Wrapper borderBottom={borderBottom ? `1px solid ${colors.grey4}` : null} color={colorText} id={anchorTag}>
            <RenderMaxWidth {...RenderMaxWidthProps}>
                <Link volatile to={url || null} style={{ textDecoration: 'none' }}>
                    <InnerWrapper px={headerPX} py={headerPY} onClick={children && !url ? handleClick : null}>
                        <Heading as="h3" size={headingSize} fontWeight={headingWeight}>
                            {text}
                        </Heading>
                        {children && showPlusIcon && (
                            <Plus
                                color={colorText}
                                height={plusSize}
                                transform={isActive ? 'rotate(45deg)' : 'rotate(0)'}
                                transition={`transform ${transitions.primary}`}
                                width={plusSize}
                            />
                        )}
                    </InnerWrapper>
                </Link>
                {children && (
                    <AnimationWrapper
                        animationDuration={animationDuration}
                        height={isActive ? 'auto' : '0'}
                        timingFunction={timingFunction}
                        transition={animationDuration > 0 && `${animationDuration}ms ${timingFunction}`}
                    >
                        {children}
                    </AnimationWrapper>
                )}
            </RenderMaxWidth>
        </Wrapper>
    );
};

SingleAccordion.propTypes = {
    anchorTag: PropTypes.string,
    animationDuration: PropTypes.number,
    borderBottom: PropTypes.bool,
    children: PropTypes.node,
    colorText: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    headerPX: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headerPY: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingSize: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    isActive: PropTypes.bool,
    maxWithContent: PropTypes.bool,
    plusSize: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    showPlusIcon: PropTypes.bool,
    text: PropTypes.string,
    timingFunction: PropTypes.string,
    url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default SingleAccordion;
