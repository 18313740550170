/**
 * hexToRGBA
 * @version 1.0
 *
 * Transforms a hexcode to a rgb() or rgba() value 
 *
 * @param {string} hex - Full color hexcode, ex #FFFFFF. Can't be #FFF
 * @param {number|string} alpha - Alpha (opacity) value, from 0 to 1
 */

const hexToRGBA = (hex: string, alpha?: number | string) => {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha || alpha === 0) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
};

export default hexToRGBA;
