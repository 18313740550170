import { useDispatch, useSelector } from 'react-redux';

import Basket from 'assets/icons/Basket';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { closeMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 8px;

    ${above.md} {
        margin: 0 12px;
    }
`;

const Number = styled('div')`
    display: flex;
    justify-content: flex-end;
    font-size: 1.4rem;
    margin-top: -5px;
`;

const BasketHeaderIcon = () => {
    const overlay = useSelector(state => state.overlay);
    const basket = useSelector(state => state.basket);
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const dispatch = useDispatch();
    const numberOfItems = basket?.items?.length;

    const toggleBasket = () => {
        const openOverlay = overlay.current;

        if (menuIsOpen) {
            dispatch(closeMenu());
        }

        if (openOverlay !== 'basketOverlay') {
            overlay.show('basketOverlay', {
                transparent: false,
                zIndex: zIndex.basket,
                isUpsell: false,
            });
        } else {
            overlay.hide();
        }
    };

    return (
        <Wrapper onClick={() => toggleBasket()}>
            <Basket />
            {numberOfItems > 0 && (
                <Number minWidth={numberOfItems > 9 ? '24px' : '18px'} color="inherit">{`(${numberOfItems})`}</Number>
            )}
        </Wrapper>
    );
};

export default BasketHeaderIcon;
