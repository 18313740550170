import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import FooterContentDesktop from './FooterContentDesktop';
import FooterContentMobile from './FooterContentMobile';
import React from 'react';

const FooterMainContent = ({ ...props }) => (
    <>
        <Below breakpoint="md" render={() => <FooterContentMobile {...props} />} />
        <Above breakpoint="md" render={() => <FooterContentDesktop {...props} />} />
    </>
);

export default FooterMainContent;
