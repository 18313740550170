import { closeMenu, setActiveMenu } from 'state/models/Header/actions';
import { useDispatch, useSelector } from 'react-redux';

import LevelTwo from 'Header/Default/Menu/Mobile/LevelTwo';
import React from 'react';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import SubCategoryItem from 'components/SubCategoryList/SubCategoryItem';
import colors from 'config/theme/colors';

const LevelOne = () => {
    const dispatch = useDispatch();
    const mainMenu = useSelector(state => state.header.data.navigation.mainMenu);
    const activeMenu = useSelector(state => state.header.state.activeMenu);

    return (
        <div>
            <ScrollWrapper
                borderBottom={`1px solid ${colors.grey4}`}
                direction="horizontal"
                marginTop="2px"
                padding={['16px 12px', null, null, '32px 24px']}
                scrollContentProps={{
                    display: 'flex',
                    flexWrap: ['noWrap', null, 'wrap'],
                }}
            >
                {mainMenu?.map(({ headerLink, featuredLinks, subMenus }, index) => (
                    <SubCategoryItem
                        fontSize="1.6rem"
                        current={activeMenu === index}
                        key={headerLink.label}
                        title={headerLink.label}
                        to={!(featuredLinks || subMenus) ? headerLink.to : null}
                        onClick={() =>
                            featuredLinks || subMenus ? dispatch(setActiveMenu(index)) : dispatch(closeMenu())
                        }
                    />
                ))}
            </ScrollWrapper>
            <LevelTwo />
        </div>
    );
};

export default LevelOne;
