import { transformArticleCategories, transformArticleDate } from 'utils/dataTransformers/article';

import ArticleStructuredData from 'components/metadata/structuredData/ArticleStructuredData';
import CmsModules from 'libs/wordpress/content/CmsModules';
import PostHero from 'components/pages/post/PostHero';
import PropTypes from 'prop-types';
import React from 'react';
import appProp from 'utils/proptypes/application';
import { injectModel } from 'state';
import styled from 'libs/styled';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { useSelector } from 'react-redux';

const PageWrapper = styled('div')`
    position: relative;
    margin-bottom: 80px;
`;

const HeroWrapper = styled('div')`
    position: relative;
`;

const ContentWrapper = styled('div')``;

const SingleArticle = ({ application: { protocol, domain, path, config }, data }) => {
    const isAboveMd = useAboveBreakpoint('md');

    const {
        breadcrumbs,
        categories,
        created_at: createdAt,
        hero,
        publish_date: publishDate,
        page_content: pageContent,
        tags,
        title,
        seo,
    } = data;

    // Add magazine page to breadcrumbs
    const magazinePage = useSelector(state => state.application.config.options.specified_pages.magazine);

    const updatedBreadcumbs = [...breadcrumbs];
    if (magazinePage) {
        updatedBreadcumbs.unshift({
            title: magazinePage.title,
            permalink: magazinePage.slug,
        });
    }

    const { background_media_flexible: backgroundObject } = hero || {};
    const transformedBackground = transformFlexibleBackground(backgroundObject);
    const background = isAboveMd ? transformedBackground?.desktop : transformedBackground?.mobile;
    const backgroundType = isAboveMd ? transformedBackground?.desktop?.type : transformedBackground?.mobile?.type;

    const formattedDate = transformArticleDate(publishDate || createdAt);

    // If there are no tag present in the hero, add article categories as the tag
    const articleTags =
        Object.keys(tags).length > 0 ? transformArticleCategories(tags) : transformArticleCategories(categories);

    return (
        <PageWrapper>
            <HeroWrapper>
                <ArticleStructuredData
                    articleSummary={{
                        excerpt: seo?.description,
                        imageSrc: background?.url,
                    }}
                    author={data.author || ''}
                    dateModified={data.updated_at || ''}
                    datePublished={data.publish_date || data.created_at || ''}
                    domain={domain}
                    keywords={data.seo?.keywords}
                    organization={config.options?.company_data?.organization || {}}
                    path={path}
                    permalink={data.permalink}
                    protocol={protocol}
                    title={data.title || ''}
                />
                <PostHero
                    background={background}
                    backgroundType={backgroundType}
                    breadcrumbs={updatedBreadcumbs}
                    category={articleTags}
                    date={formattedDate}
                    title={title}
                    marginBottom={['80px', null, '96px', '112px']}
                />
            </HeroWrapper>
            <ContentWrapper>{pageContent && <CmsModules data={pageContent} />}</ContentWrapper>
        </PageWrapper>
    );
};

SingleArticle.propTypes = {
    application: appProp.isRequired,
    data: PropTypes.object.isRequired, // CMS Data
};

export default injectModel('application')(SingleArticle);
