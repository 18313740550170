import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const HeadingAndNumber = ({ title = '', numberOfItems = 0, ...rest }) => (
    <Wrapper {...rest}>
        <Heading as="h1" size="lg" my="0px" maxWidth={['100%', null, '50%']}>
            {title}
        </Heading>
        {numberOfItems > 0 && (
            <Heading as="span" size="lg" my="0px" lineHeight="1" flexShrink="0" paddingLeft="24px">
                ({numberOfItems})
            </Heading>
        )}
    </Wrapper>
);

HeadingAndNumber.propTypes = {
    numberOfItems: PropTypes.number,
    title: PropTypes.string,
};

export default HeadingAndNumber;
