import Path from './base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from './base/SVG';

const Search = ({ color = 'black', height = '24px', viewBox = '0 0 24 24', width = '24px', ...props }) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...props}>
        <Path
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2735 10.7617C18.2735 14.9103 14.9103 18.2735 10.7617 18.2735C6.61312 18.2735 3.25 14.9103 3.25 10.7617C3.25 6.61312 6.61312 3.25 10.7617 3.25C14.9103 3.25 18.2735 6.61312 18.2735 10.7617ZM16.4997 17.3833C14.9625 18.7166 12.9563 19.5235 10.7617 19.5235C5.92276 19.5235 2 15.6007 2 10.7617C2 5.92276 5.92276 2 10.7617 2C15.6007 2 19.5235 5.92276 19.5235 10.7617C19.5235 12.9562 18.7167 14.9622 17.3836 16.4994L21.9124 21.0283L21.0286 21.9122L16.4997 17.3833Z"
        />
    </SVG>
);

Search.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Search;
