import Badges from '../ProductCard/Badges';
import Breadcrumbs from 'components/Breadcrumbs';
import FlexibleContentPosition from 'components/wrappers/FlexibleWrapper/FlexibleContentPosition';
import Heading from 'components/text/Heading';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import contentMargins from 'config/theme/contentMargins';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';
import useIsShowroom from 'hooks/useIsShowroom';

const Hero = styled('div', { shouldForwardProp: prop => ['clientHeight', 'headerHeights'].indexOf(prop) === -1 })`
    position: relative;
    top: 0;
    left: 0%;
    width: 100%;
    height: ${({ clientHeight, headerHeights }) => `calc(${clientHeight} - ${headerHeights.banner}px)`};
    z-index: 0;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 150vw;
        height: 150vw;
        z-index: 1;
        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.2) 30%,
            rgba(0, 0, 0, 0.1) 60%,
            rgba(0, 0, 0, 0) 100%
        );
        transform: translate(-50%, 50%);
    }
`;

const StyledBreadcrumbs = styled(Breadcrumbs, { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    position: absolute;
    top: ${({ headerHeights }) => headerHeights.mobile + 12}px;
    ${above.md} {
        top: ${({ headerHeights }) => headerHeights.desktop + 16}px;
    }
`;

const StyledBadges = styled(Badges, { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    position: absolute;
    top: ${({ headerHeights }) => headerHeights.mobile + 42}px;
    ${above.md} {
        top: ${({ headerHeights }) => headerHeights.desktop + 24}px;
        right: ${contentMargins[3]};
    }
`;

const HeadingAndShortcuts = styled('div', { shouldForwardProp: prop => ['isShowroom'].indexOf(prop) === -1 })`
    color: ${colors.white};
    margin-bottom: ${({ isShowroom }) => (isShowroom ? '16px' : '115px')};

    ${above.md} {
        margin-bottom: ${({ isShowroom }) => (isShowroom ? '24px' : '110px')};
    }
`;

const Shortcuts = styled('div')`
    display: none;
    ${above.md} {
        display: flex;
        margin: 64px -12px 0;
    }
`;

const Shortcut = styled(Paragraph)`
    display: block;
    margin: 0 12px;
    text-decoration: underline;
    cursor: pointer;
`;

const ProductHero = ({
    badges = [],
    breadcrumbs = [],
    designers = '',
    name,
    scrollToShortDescriptionLabel,
    scrollToAccordions,
    scrollToAccordionsLabel,
    scrollToShortDescription,
    stickyRef,
}) => {
    const isShowroom = useIsShowroom();
    const headerHeights = useHeaderHeights();
    const clientHeight = useClientHeight();

    return (
        <Hero headerHeights={headerHeights} clientHeight={clientHeight}>
            <MaxWidthWrapper includeContentMargins position="relative" zIndex="10">
                <StyledBreadcrumbs
                    currentTitle={name}
                    headerHeights={headerHeights}
                    items={breadcrumbs}
                    textColor="white"
                />
                {badges?.length > 0 && <StyledBadges badges={badges} headerHeights={headerHeights} />}
            </MaxWidthWrapper>
            <FlexibleContentPosition
                useGridWrapper
                useMaxWidth
                customGridSize={[12, null, 6]}
                xAlignContent="left"
                yAlignContent="bottom"
            >
                <HeadingAndShortcuts ref={stickyRef} isShowroom={isShowroom}>
                    <Heading as="h1" margin="0" size="lg">
                        {name}
                        {designers && (
                            <>
                                <br />
                                {designers}
                            </>
                        )}
                    </Heading>
                    <Shortcuts>
                        <Shortcut onClick={() => scrollToShortDescription()}>{scrollToShortDescriptionLabel}</Shortcut>
                        <Shortcut onClick={() => scrollToAccordions()}>{scrollToAccordionsLabel}</Shortcut>
                    </Shortcuts>
                </HeadingAndShortcuts>
            </FlexibleContentPosition>
        </Hero>
    );
};

ProductHero.propTypes = {
    badges: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            theme: PropTypes.string,
        })
    ),
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    designers: PropTypes.string,
    name: PropTypes.string.isRequired,
    scrollToAccordions: PropTypes.func.isRequired,
    scrollToAccordionsLabel: PropTypes.string,
    scrollToShortDescription: PropTypes.func.isRequired,
    scrollToShortDescriptionLabel: PropTypes.string,
    stickyRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
        .isRequired,
};

export default ProductHero;
