/* eslint-disable camelcase */
import CloseBig from 'assets/icons/CloseBig';
import Footer from 'components/Basket/Footer';
import Paragraph from 'components/text/Paragraph';
import ProductCardMiniBasket from 'components/products/productCardMini/Basket';
import PropTypes from 'prop-types';
import React from 'react';
import { injectModel } from 'state';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Header = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

const Products = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 38px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }
`;

const Basket = ({ products, closeBasket }) => {
    const label = useSelector(state => state.header.data.basket.label);
    const numberOfProducts = products?.length;

    return (
        <>
            <Header>
                <Paragraph fontSize="3.6rem">{`${label} (${numberOfProducts})`}</Paragraph>
                <CloseBig cursor="pointer" onClick={() => closeBasket()} />
            </Header>
            <Products>
                {products.map((product, index) => (
                    <ProductCardMiniBasket
                        key={product.id}
                        position={index}
                        onClickPermalink={() => closeBasket()}
                        {...product}
                    />
                ))}
            </Products>
            <Footer closeBasket={closeBasket} />
        </>
    );
};

Basket.propTypes = {
    closeBasket: PropTypes.func.isRequired,
    products: PropTypes.object,
};

export default injectModel('basket')(Basket);
