import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Featured from 'Header/Default/Menu/Desktop/LevelTwo/Featured';
import MenuLevelThree from 'Header/Default/Menu/Desktop/LevelThree';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import { closeMenu } from 'state/models/Header/actions';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import zIndex from 'config/theme/z-index';

const Div = styled('div')``;

const Wrapper = styled('div', { shouldForwardProp: prop => ['active', 'headerHeights'].indexOf(prop) === -1 })`
    display: ${({ active }) => (active ? 'flex' : 'none')};
    width: 75%; /* 9 / 12 */
    height: 100%;
    padding-top: ${({ headerHeights }) => headerHeights.desktop + 24}px;
    z-index: ${zIndex.header};

    ${above.lg} {
        width: 58.33%; /* 7 / 12 */
    }
`;

const MenuLevelThreeWrapper = styled('div')`
    width: 57.14%; /* 4 / 7 */
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }
`;

const LevelTwoItem = styled('li', { shouldForwardProp: prop => ['active', 'activeMenuLevelTwo'].indexOf(prop) === -1 })`
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    cursor: pointer;
    color: ${({ activeMenuLevelTwo }) => (activeMenuLevelTwo ? colors.grey6 : colors.black)};

    ${({ active }) =>
        active &&
        `
        color: ${colors.black};
            :before {
                content: '';
                display: inline-block;
                border-radius: 50%;
                background: ${colors.black};
                margin-right: 0.4rem;
                width: 6px;
                height: 6px;
            }
        `}
`;

const Buttons = styled('div')`
    margin: auto -4px 0;
    padding: 32px 12px;
`;

const MenuLevelTwo = ({ active = false, featuredLinks = [], generalLinks = [], subMenus = [] }) => {
    const dispatch = useDispatch();
    const activeMenu = useSelector(state => state.header.state.activeMenu);
    const headerHeights = useHeaderHeights();
    const featuredRef = useRef(null);
    const [featuredHeight, setFeaturedHeight] = useState(0);
    const [activeMenuLevelTwo, setActiveMenuLevelTwo] = useState(null);

    useEffect(() => {
        const height = featuredRef?.current?.getBoundingClientRect().height;
        setFeaturedHeight(height);
    }, [featuredRef, activeMenu]);

    if (!(featuredLinks || subMenus)) {
        return null;
    }

    const handleClickLevelThree = () => {
        setActiveMenuLevelTwo(null);
        dispatch(closeMenu());
    };

    return (
        <Wrapper active={active} headerHeights={headerHeights}>
            <Div display="flex" flexDirection="column" justifyContent="space-between" width="42.85%">
                <Div>
                    <Featured featuredRef={featuredRef} featuredLinks={featuredLinks} />
                    <Div as="ul" margin="-4px 0">
                        {subMenus?.length > 0 &&
                            subMenus.map(({ label }, index) => (
                                <LevelTwoItem
                                    active={activeMenuLevelTwo === index}
                                    activeMenuLevelTwo={activeMenuLevelTwo !== null}
                                    key={label}
                                    py="4px"
                                    onClick={() => setActiveMenuLevelTwo(index)}
                                >
                                    {label}
                                </LevelTwoItem>
                            ))}
                    </Div>
                </Div>
                {generalLinks?.length > 0 && (
                    <Buttons>
                        {generalLinks.map(({ label, to }) => (
                            <ThemeButton
                                key={label}
                                mx="4px"
                                theme="outlinedBlack"
                                to={to}
                                onClick={() => dispatch(closeMenu())}
                            >
                                {label}
                            </ThemeButton>
                        ))}
                    </Buttons>
                )}
            </Div>
            <MenuLevelThreeWrapper>
                {subMenus?.length > 0 &&
                    subMenus.map(({ contentType, label, links, items }, index) => (
                        <MenuLevelThree
                            active={activeMenuLevelTwo === index}
                            contentType={contentType}
                            handleClick={handleClickLevelThree}
                            items={items}
                            key={label}
                            links={links}
                            spaceTop={`${featuredHeight}px`}
                        />
                    ))}
            </MenuLevelThreeWrapper>
        </Wrapper>
    );
};

MenuLevelTwo.propTypes = {
    active: PropTypes.bool.isRequired,
    featuredLinks: PropTypes.array,
    generalLinks: PropTypes.array,
    subMenus: PropTypes.array,
};

export default MenuLevelTwo;
