import React, { useState } from 'react';

import LevelThreeImages from 'Header/Default/Menu/LevelThreeImages';
import LevelThreeLinks from 'Header/Default/Menu/LevelThreeLinks';
import PropTypes from 'prop-types';
import SingleAccordion from 'components/accordions/SingleAccordion';
import { closeMenu } from 'state/models/Header/actions';
import colors from 'config/theme/colors';
import styled from 'libs/styled';
import { useDispatch } from 'react-redux';

const Wrapper = styled('div')`
    border-top: 1px solid ${colors.grey4};
    border-bottom: 1px solid ${colors.grey4};
`;

const LinkWrapper = styled('div')`
    padding-bottom: 24px;
`;

const MobileMenuAccordion = ({ subMenus = [] }) => {
    const dispatch = useDispatch();
    const [activeAccordion, setActiveAccordion] = useState([]);

    const handleClickLevelThree = () => {
        setActiveAccordion([]);
        dispatch(closeMenu());
    };

    return (
        <Wrapper>
            {subMenus.map((menu, index) => {
                // There are both links and items due to contentTypes
                const contentLinks = menu.contentType === 'links' ? menu.links : menu.items;
                const grid = menu.contentType === 'imageGrid';

                const mediaQuery = [
                    /* 0  <-  xs */ { w: grid ? 375 / 2 : 375, q: 70 },
                    /* xs <-> sm */ { w: grid ? 768 / 2 : 768, q: 70 },
                    /* sm <-> md */ { w: grid ? 1024 / 2 : 1024, q: 70 },
                    /* md <-> lg */ { w: grid ? 1024 / 2 : 1024, q: 70 },
                    /* lg <-> xl */ { w: grid ? 1024 / 2 : 1024, q: 70 },
                    /* xl ->     */ { w: grid ? 1024 / 2 : 1024, q: 70 },
                ];

                return (
                    <SingleAccordion
                        animationDuration={200}
                        borderBottom={subMenus.length - 1 !== index}
                        colorText={colors.black}
                        headerPX="12px"
                        headerPY="16px"
                        headingSize="xs"
                        headingWeight="500"
                        isActive={activeAccordion.includes(index)}
                        key={menu.label}
                        text={menu.label}
                        timingFunction="ease"
                        handleClick={() =>
                            setActiveAccordion(
                                activeAccordion.includes(index)
                                    ? activeAccordion.filter(activeIndex => activeIndex !== index)
                                    : [...activeAccordion, index]
                            )
                        }
                    >
                        <LinkWrapper>
                            {menu.contentType === 'links' ? (
                                <LevelThreeLinks handleClick={handleClickLevelThree} links={contentLinks} />
                            ) : (
                                <LevelThreeImages
                                    contentType={menu.contentType}
                                    handleClick={handleClickLevelThree}
                                    links={contentLinks}
                                    mediaQuery={mediaQuery}
                                />
                            )}
                        </LinkWrapper>
                    </SingleAccordion>
                );
            })}
        </Wrapper>
    );
};

MobileMenuAccordion.propTypes = {
    subMenus: PropTypes.arrayOf(
        PropTypes.shape({
            contentType: PropTypes.string,
            label: PropTypes.string,
            links: PropTypes.array,
            items: PropTypes.array,
        })
    ),
};

export default MobileMenuAccordion;
