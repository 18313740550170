/* eslint-disable camelcase */
import React, { Fragment } from 'react';

import ArchiveHero from 'components/ArchiveHero';
import CmsModules from 'libs/wordpress/content/CmsModules';
import PropTypes from 'prop-types';
import styled from 'libs/styled';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    position: relative;
`;

const NotFound = ({ data }) => {
    const isAboveMd = useAboveBreakpoint('md');
    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    const { page_content: pageContent, hero } = data;
    const { background_media_flexible: backgroundObject, heading, paragraph } = hero?.data || {};
    const { mobile, desktop } = transformFlexibleBackground(backgroundObject);

    return (
        <Fragment>
            <Wrapper>
                <ArchiveHero
                    background={isAboveMd ? desktop : mobile}
                    button={{
                        label: t('not_found.to_the_front_page'),
                        to: '/',
                        theme: 'outlinedBlack',
                    }}
                    heroDescription={paragraph}
                    heroTitle={heading}
                    shortCutText="404"
                />
            </Wrapper>
            {pageContent && <CmsModules data={pageContent} />}
        </Fragment>
    );
};

NotFound.propTypes = {
    data: PropTypes.object.isRequired,
};

export default NotFound;
