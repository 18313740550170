import Path from './base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from './base/SVG';

const Basket = ({ color = 'black', height = '24px', viewBox = '0 0 24 24', width = '24px', ...props }) => (
    <SVG height={height} viewBox={viewBox} width={width} fill="none" {...props}>
        <Path
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 0.375C9.44568 0.375 7.375 2.44568 7.375 5H4.25H3V6.25V20.75V22H4.25H19.75H21V20.75V6.25V5H19.75H16.625C16.625 2.44568 14.5543 0.375 12 0.375ZM15.375 6.25V9H16.625V6.25H19.75V20.75H4.25V6.25H7.375V9H8.625V6.25H15.375ZM15.375 5C15.375 3.13604 13.864 1.625 12 1.625C10.136 1.625 8.625 3.13604 8.625 5H15.375Z"
        />
    </SVG>
);

Basket.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Basket;
