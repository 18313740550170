import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';

const MouseBig = ({ color = 'black', ...props }) => (
    <SVG height="56px" viewBox="0 0 34 56" width="34px" fill="none" {...props}>
        <Path
            fill={color}
            fillOpacity="0.3"
            d="M32 17V39H34V17H32ZM2 39V17H0V39H2ZM17 54C8.71573 54 2 47.2843 2 39H0C0 48.3888 7.61116 56 17 56V54ZM32 39C32 47.2843 25.2843 54 17 54V56C26.3888 56 34 48.3888 34 39H32ZM17 2C25.2843 2 32 8.71573 32 17H34C34 7.61116 26.3888 0 17 0V2ZM17 0C7.61116 0 0 7.61116 0 17H2C2 8.71573 8.71573 2 17 2V0Z"
        />
    </SVG>
);

MouseBig.propTypes = {
    color: PropTypes.string,
};

export default MouseBig;
