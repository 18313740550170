import Featured from 'Header/Default/Menu/Mobile/LevelTwo/Featured';
import LevelThree from 'Header/Default/Menu/Mobile/LevelThree';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div', { shouldForwardProp: prop => ['active'].indexOf(prop) === -1 })`
    display: ${({ active }) => (active ? 'block' : 'none')};
`;

const LevelTwo = ({ active = false, featuredLinks = [], subMenus = [] }) => (
    <Wrapper display="flex" flexDirection="column" active={active}>
        {featuredLinks.length > 0 && <Featured featuredLinks={featuredLinks} />}
        {subMenus.length > 0 && <LevelThree subMenus={subMenus} />}
    </Wrapper>
);

LevelTwo.propTypes = {
    active: PropTypes.bool,
    featuredLinks: PropTypes.array,
    subMenus: PropTypes.array,
};

export default LevelTwo;
