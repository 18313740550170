import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import Rect from 'assets/icons/base/Rect';
import SVG from 'assets/icons/base/SVG';

const Increase = ({ color = 'black', ...props }) => (
    <SVG height="25px" viewBox="0 0 24 25" width="24px" fill="none" {...props}>
        <Path
            stroke={color}
            strokeWidth="1.25"
            d="M12.0001 6.00686L12.0001 12.0069M12.0001 12.0069L12.0001 18.007M12.0001 12.0069L18.0001 12.0069M12.0001 12.0069L6 12.0069"
        />
        <Rect stroke={color} x="0.5" y="0.506927" width="23px" height="23px" rx="11.5" />
    </SVG>
);

Increase.propTypes = {
    color: PropTypes.string,
};

export default Increase;
