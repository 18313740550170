import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';

/**
 * Add a fixed padding to left and right.
 * Useful when content high up in the DOM-tree should have the same padding on each side. Like content, header and footer.
 * If some content inside the ContentWrapper should cover the whole viewport, BleedWrapper can be used.
 */

export const contentPadding = ['12px', null, '24px'];

const ContentWrapper = styled('div')`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    ${responsiveBreakpointsStyle(contentPadding, 'padding-left: {value}; padding-right: {value};')}
`;

export default ContentWrapper;
