import { horizontalAlignmentProp, verticalAlignmentProp } from 'utils/proptypes/modules/styleProps';

import ContentWrapper from 'components/wrappers/ContentWrapper';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ModuleGridWrapper from 'components/wrappers/ModuleGridWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

// Make sure that the component always covers 100% width and 100% height
const ContentWrapperHeight = styled(ContentWrapper)`
    position: relative;
    z-index: 2;
    height: 100%;

    // MaxWidthWrapper
    > div {
        height: 100%;

        // GridWrapper
        > div {
            height: 100%;
        }
    }
`;

const PositionWrapper = styled('div')`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const UseMaxWidth = ({ children, useMaxWidth }) =>
    useMaxWidth ? <MaxWidthWrapper>{children}</MaxWidthWrapper> : <div>{children}</div>;

/**
 * Position the content inside FlexibleWrapper correct.
 *
 * @param {node} children - JSX children
 * @param {array} customGridSize - Pass a custom variable to ModuleGridWrapper used inside FlexibleContentPosition to overwrite the default.
 * @param {bool} useGridWrapper - Should the children be wrapped by ModuleGridWrapper.
 * @param {bool} useMaxWidth - Should the children be wrapped by MaxWidthWrapper. Can only be used if useGridWrapper also is true.
 * @param {string} xAlignContent - Align the content to left, center or right.
 * @param {string} yAlignContent - Align the content to top, center or bottom.
 */

const FlexibleContentPosition = ({
    children,
    customGridSize,
    useGridWrapper,
    xAlignContent,
    yAlignContent,
    useMaxWidth,
}) => {
    const textAlign = xAlignContent;
    let alignItems, justifyContent;

    switch (xAlignContent) {
        case 'left':
            justifyContent = 'flex-start';
            break;
        case 'right':
            justifyContent = 'flex-end';
            break;
        default:
            justifyContent = 'center';
            break;
    }

    switch (yAlignContent) {
        case 'top':
            alignItems = 'flex-start';
            break;
        case 'bottom':
            alignItems = 'flex-end';
            break;
        default:
            alignItems = 'center';
            break;
    }

    if (customGridSize) {
        const keys = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
        customGridSize = Object.fromEntries(keys.map((_, i) => [keys[i], customGridSize[i] || null]));
    }

    // This option will adapt the width of the content to the ModuleGridWrapper
    // Recomended only for flexible content that is 100vw
    if (useGridWrapper) {
        return (
            <ContentWrapperHeight>
                <UseMaxWidth useMaxWidth={useMaxWidth}>
                    <ModuleGridWrapper contentPosition={textAlign} {...customGridSize}>
                        <PositionWrapper textAlign={textAlign} justifyContent={justifyContent} alignItems={alignItems}>
                            {children}
                        </PositionWrapper>
                    </ModuleGridWrapper>
                </UseMaxWidth>
            </ContentWrapperHeight>
        );
    }

    // This option will add a fixed padding to the content
    // Recommended for content used in a column
    return (
        <PositionWrapper textAlign={textAlign} justifyContent={justifyContent} alignItems={alignItems}>
            {children}
        </PositionWrapper>
    );
};

UseMaxWidth.propTypes = {
    children: PropTypes.node.isRequired,
    useMaxWidth: PropTypes.bool.isRequired,
};

FlexibleContentPosition.propTypes = {
    children: PropTypes.node.isRequired,
    customGridSize: PropTypes.array,
    useGridWrapper: PropTypes.bool,
    useMaxWidth: PropTypes.bool,
    xAlignContent: horizontalAlignmentProp,
    yAlignContent: verticalAlignmentProp,
};

FlexibleContentPosition.defaultProps = {
    customGridSize: undefined,
    useGridWrapper: false,
    useMaxWidth: true,
    xAlignContent: 'center',
    yAlignContent: 'center',
};

export default FlexibleContentPosition;
