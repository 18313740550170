import AddToBasketButton from 'components/buttons/functionalButtons/AddToBasketButton';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import zIndex from 'config/theme/z-index';

const StickyAddToBasket = styled('div', {
    shouldForwardProp: prop => ['positionLeft'].indexOf(prop) === -1,
})`
    position: fixed;
    left: ${({ positionLeft }) => positionLeft}px;
    bottom: 24px;
    margin-bottom: 24px;
    z-index: ${zIndex.stickyAddToBasket};
    width: ${({ positionLeft }) => `calc(100vw - ${positionLeft * 2}px)`};
    max-width: 450px;

    @supports (position: sticky) {
        position: sticky;
    }
`;

const ProductStickyAddToBasket = ({ currentProduct, inStock = false, variationId, positionLeft = 0, price = {} }) => {
    return (
        <StickyAddToBasket positionLeft={positionLeft}>
            <AddToBasketButton
                currentProduct={currentProduct}
                inStock={inStock}
                price={price}
                variationId={variationId}
            />
        </StickyAddToBasket>
    );
};

ProductStickyAddToBasket.propTypes = {
    currentProduct: PropTypes.object.isRequired,
    inStock: PropTypes.bool.isRequired,
    positionLeft: PropTypes.number.isRequired,
    price: PropTypes.object,
    variationId: PropTypes.string,
};

export default ProductStickyAddToBasket;
