import { above, media } from 'utils/mediaqueries';

import ArrowCircle from 'assets/icons/ArrowCircle';
import DefaultLink from 'components/base/Link';
import PaginationMeta from 'components/metadata/PaginationMeta';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import { extractQueryParams } from 'utils/query';
import { stringReplace } from 'utils/string';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')``;

const Navigation = styled('nav')`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.grey6};
    font-size: 2.4rem;
    line-height: 1;

    ${above.xs} {
        font-size: 3.6rem;
    }

    ${above.lg} {
        font-size: 6.4rem;
    }
`;

const Dots = styled('span')`
    font-size: inherit;
    line-height: inherit;
    min-width: 18px;

    ${above.xs} {
        min-width: 25px;
    }

    :before {
        content: '...';
    }
`;

const BaseLink = styled(DefaultLink, {
    shouldForwardProp: prop => ['current'].indexOf(prop) === -1,
})`
    color: ${({ current }) => (current ? colors.black : colors.grey6)};
    transition: color ${transitions.primaryFast};

    ${media.hover} {
        &:hover {
            color: ${colors.black};
        }
    }
`;

const NumberLink = styled(BaseLink)`
    position: relative;
    margin: 0 4px;
    font-size: inherit;
    line-height: inherit;
`;

const Message = styled(Paragraph)`
    margin-top: 12px;
    color: ${colors.grey6};
    text-align: center;

    ${above.lg} {
        margin-top: 24px;
    }
`;

/**
 * createPaginationUrl
 * Helper function to create pagination urls for Pagination pages-prop
 *
 * @param {number} pageNumber - Number of the page to link to
 * @param {func} location - window.location object
 */

export const createPaginationUrl = (pageNumber, location) => {
    const query = extractQueryParams(location.search);
    delete query.page;

    return `${location.pathname}?${Object.entries({ ...query, page: pageNumber })
        .map(q => q.join('='))
        .join('&')}`;
};

/**
 * createPaginationPages
 * Helper function to create pages-prop for Pagination
 *
 * @param {number} currentPage - Number of the page current page
 * @param {number} totalPages - Total number of pages
 * @param {func} location - window.location object, used by createPaginationUrl
 */

export const createPaginationPages = (currentPage, totalPages, location) => {
    return [...new Array(totalPages)].map((_, index) => ({
        name: index + 1,
        current: currentPage === index + 1,
        url: createPaginationUrl(index + 1, location),
    }));
};

/**
 * Pagination
 * @version 2.0
 *
 * The pagination can display something like:
 *     <- 1 ... 4 5 6 7 8 ... 14 ->
 *      Show products 61-75 of 159
 *
 * @param {number} currentIndex - The index of the current page.
 * @param {func} handleClick - Add a click function to the pagination.
 * @param {number} maxSiblings - Max number of siblings to shows to the left/right of current page.
 * @param {string} messagege - Display a text below. Ex "Show products 16-30 of 99".
 * @param {number} pageItemCount - The number of items displayed on the current page.
 * @param {object[]} pages - Array of all pages.
 * @param {bool} showPrevNext - Should the prev/next icons be shown?
 * @param {number} totalItemCount - The number of items across all pages.
 * @param {bool} usePaginationMeta - Should pagination meta be applied? Not always wanted by SEO-agencies.
 */

const Pagination = ({
    currentIndex = 0,
    handleClick = () => {},
    maxSiblings = 1,
    message,
    pageItemCount = 0,
    pageSize = 0,
    pages = [],
    showPrevNext = true,
    totalItemCount = 0,
    usePaginationMeta = false,
    ...rest
}) => {
    const { t } = useTranslation();

    if (!pages?.length > 0) {
        return null;
    }

    // Remove ?page=1 from the first page
    pages = pages.map(page => ({
        ...page,
        url: page.url.replace(/\?page=1$/, ''),
    }));

    // Save the different kind of pages as variables
    const currentPage = pages[currentIndex];
    const prevPages = pages.slice(Math.max(currentIndex - maxSiblings, 0), currentIndex);
    const prevPage = pages[currentIndex - 1];
    const nextPages = pages.slice(currentIndex + 1, currentIndex + 1 + maxSiblings);
    const nextPage = pages[currentIndex + 1];
    const firstPage = pages[0];
    const lastPage = pages[pages.length - 1];

    // Visible pages will be the pages displayed nex to current. Ex when current is 5: ... 3 4 5 6 7 ...
    const visiblePages = [...prevPages, currentPage, ...nextPages];

    // Should the first page be displayed like: 1 ... 3 4 5 6 7 ...
    const showFirst = visiblePages[0] !== firstPage;

    // Should the last page be displayed like: ... 3 4 5 6 7 ... 20
    const showLast = visiblePages[visiblePages.length - 1] !== lastPage;

    // Variables for optional pagination message
    const offset = currentIndex * pageSize;
    const totalItemsSeen = currentIndex > 0 ? offset + pageItemCount : pageSize;

    let displayMessage;
    const messageVariables = {
        '{span}': `${offset + 1}-${offset + pageItemCount}`, // Number of product visible on current page (16-30)
        '{seen}': totalItemsSeen, // Total items on PREVIOUS pages and CURRENT page
        '{total}': totalItemCount, // Total items on ALL pages
    };
    if (message) {
        displayMessage = stringReplace(message, messageVariables);
    }

    return (
        <Wrapper {...rest}>
            {/* This is not always wanted by an SEO agency, (so double check before you implement it) */}
            {usePaginationMeta && (
                <PaginationMeta
                    updateMetaDescription
                    currentIndex={currentIndex}
                    currentPage={currentPage}
                    extraDescription={stringReplace(`${t('pagination.meta_description')}`, messageVariables)}
                    nextPageUrl={nextPage?.url}
                    prevPageUrl={prevPage?.url}
                    seen={totalItemsSeen}
                    totalItemCount={totalItemCount}
                    totalPages={pages.length}
                />
            )}
            <Navigation>
                {showPrevNext && (
                    <BaseLink
                        volatile
                        to={prevPage?.url}
                        rel={firstPage !== currentPage ? 'prev' : undefined}
                        lineHeight="0"
                        whiteSpace="nowrap"
                        onClick={e => handleClick(e, prevPage)}
                    >
                        <ArrowCircle transform="rotate(180deg)" mr={['30px', null, '44px']} color="currentColor" />
                    </BaseLink>
                )}
                {showFirst && (
                    <>
                        <NumberLink
                            to={firstPage.url}
                            current={firstPage === currentPage}
                            onClick={e => handleClick(e, firstPage)}
                        >
                            {firstPage.name}
                        </NumberLink>
                        {pages.indexOf(visiblePages[0]) > 1 && <Dots />}
                    </>
                )}
                {visiblePages.map(page => {
                    // Setting the correct attribute for rel
                    let relAtt;
                    if (currentIndex === page.name) {
                        relAtt = 'prev';
                    } else if (currentIndex + 2 === page.name) {
                        relAtt = 'next';
                    }

                    return (
                        <NumberLink
                            rel={relAtt}
                            key={page.url}
                            current={!!page.current}
                            to={page.url}
                            onClick={e => handleClick(e, page)}
                        >
                            {page.name}
                        </NumberLink>
                    );
                })}
                {showLast && (
                    <>
                        {pages.indexOf(visiblePages[visiblePages.length - 1]) < pages.length - 2 && <Dots />}
                        <NumberLink
                            to={lastPage.url}
                            current={lastPage === currentPage}
                            onClick={e => handleClick(e, lastPage)}
                        >
                            {lastPage.name}
                        </NumberLink>
                    </>
                )}
                {showPrevNext && (
                    <BaseLink
                        volatile
                        to={nextPage?.url}
                        rel={showLast !== currentPage ? 'next' : undefined}
                        lineHeight="0"
                        whiteSpace="nowrap"
                        onClick={e => handleClick(e, nextPage)}
                    >
                        <ArrowCircle ml={['30px', null, '44px']} color="currentColor" />
                    </BaseLink>
                )}
            </Navigation>
            {displayMessage && <Message>{displayMessage}</Message>}
        </Wrapper>
    );
};

Pagination.propTypes = {
    currentIndex: PropTypes.number,
    handleClick: PropTypes.func,
    maxSiblings: PropTypes.number,
    message: PropTypes.string,
    pageItemCount: PropTypes.number,
    pageSize: PropTypes.number,
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.number,
            current: PropTypes.bool,
            url: PropTypes.string,
        })
    ),
    showPrevNext: PropTypes.bool,
    totalItemCount: PropTypes.number,
    usePaginationMeta: PropTypes.bool,
};

export default Pagination;
