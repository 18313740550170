import Checkout from './Checkout';
import Default from './Default';
import React from 'react';
import { useSelector } from 'react-redux';

const Header = () => {
    const pageTemplate = useSelector(state => state.page.template);

    const checkoutTemplates =
        ['ecommerce_checkout', 'ecommerce_checkout_success', 'ecommerce_checkout_error'].indexOf(pageTemplate) !== -1;

    return checkoutTemplates ? <Checkout /> : <Default />;
};

export default Header;
