import MobileMenuAccordion from 'components/accordions/MobileMenuAccordion';
import PropTypes from 'prop-types';
import React from 'react';

const LevelThree = ({ subMenus = [] }) => <MobileMenuAccordion subMenus={subMenus} />;

LevelThree.propTypes = {
    subMenus: PropTypes.array,
};

export default LevelThree;
