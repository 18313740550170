/* eslint-disable camelcase */
import React, { Fragment } from 'react';

import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import createHrefLangs from 'components/metadata/utils/createHrefLangs';
import createOgTags from 'components/metadata/utils/createOgTags';
import createTwitterTags from 'components/metadata/utils/createTwitterTags';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { extractQueryParams } from 'utils/query';
import getOptimalContentImageSrc from 'components/metadata/utils/getOptimalContentImageSrc';
import suffix from 'components/metadata/utils/suffix';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

/**
 * PageMeta for Orrefors
 * @version 1.1
 *
 * PageMeta Init in RouteResolver
 * @param {string} suffix - Dont forget to change this to current project name.
 * @param {func} buildCanonicalUrl - This creates a dynamic canonical url. We can add specific condition ex. also add the magazine type
 * @param {string} protocol - application.protocol string. always https
 * @param {string} domain - application.domain, current domain
 * @param {string} path - application.path, current path
 * @param {object} pageData -  Getting the needed SEO information, and building the meta also important for the canonical
 * @param {external func} createOgTags - Creates the necessary og tags passed down to it
 * @param {external func} createHrefLangs - Creates the hreflang meta tags
 * @param {external func} createTwitterTags - Creates the necessary twitter tags passed down to it
 * Note: The file will only fire if the permalink has changed.
 */

const PageMeta = React.memo(
    ({ protocol, domain, path, pageData }) => {
        const { t } = useTranslation();
        const location = useLocation();

        // Url/Canonical setup
        const permalink = pageData.permalink;
        const protocolPath = protocol ? `${protocol}://` : '';
        const localePath = path || '';
        const baseUrl = protocolPath + domain || '';
        const seoObject = pageData.seo || {};

        // Build the canonical if we have a canonical or permalink
        const canonical = seoObject.canonical || permalink ? `${baseUrl + localePath + permalink}` : null;

        // Generall setup of desc and title.
        const pageName = seoObject.title || pageData.title || null;
        const title = pageName && decodeHTMLEntities(pageName + suffix);
        const description = seoObject.meta_description || seoObject.description || t('utils.seo_description_fallback');

        // Little bit of a work in progress. Will get the first best image provided for the current site.
        // Very specific function
        const contentImageSrc = getOptimalContentImageSrc(pageData);

        // The no follow || no index, noIndex will we overwritten hence the let.
        const noFollow = parseInt(seoObject.no_follow, 10) || false;
        let noIndex = parseInt(seoObject.no_index, 10) || false;

        // Do not index pages with search parameters
        if (location.search) {
            const queryParams = extractQueryParams(location.search);
            if (queryParams.s) {
                noIndex = true;
            }
        }

        // Setup OG object data for tags
        const ogData = {
            title: seoObject.og_title || title || null,
            description: seoObject.og_description || description || null,
            media: seoObject.og_image || contentImageSrc,
        };

        // Setup twitter object data for tags
        const twitterData = {
            title: seoObject.twitter_title || title || null,
            description: seoObject.twitter_description || description || null,
            media: seoObject.twitter_image || contentImageSrc,
        };

        // Add speciallangs, numbers are alias_id, given by the api. Also this is not needed if project has any special cases.
        const specialLangs = {
            4: 'en',
            5: 'en_US',
            6: 'en_DK',
        };

        // Used in the hreflangs special locale. Specified for some project with this setup.
        const specialLocale = 'en_GB';

        // Creates the hreflangs, not doing this if no alternate_hrefs
        const locales =
            (pageData.alternate_hrefs && createHrefLangs(pageData.alternate_hrefs, specialLangs, specialLocale)) || [];

        // Create ogTags (facebook)
        const ogTags = createOgTags(ogData).props.children || [];

        // Create twitter tags
        const twitterTags = createTwitterTags(twitterData).props.children || [];

        return (
            <Fragment>
                <Helmet>
                    <meta
                        name="robots"
                        content={`${noIndex ? 'noindex' : 'index'}, ${noFollow ? 'nofollow' : 'follow'}`}
                    />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    {title && <title>{title}</title>}
                    {canonical && <link rel="canonical" href={canonical} />}
                    {description && <meta name="description" content={description} />}
                    {twitterTags.map(tag => tag)}
                    {ogTags.map(tag => tag)}

                    {locales.map(({ uri, locale }) => (
                        <link key={locale} rel="alternate" href={uri} hrefLang={locale} />
                    ))}
                </Helmet>
            </Fragment>
        );
    },
    // Use React.memo() to only re-render if page slug changes.
    (prevProps, nextProps) => prevProps.pageData.permalink === nextProps.pageData.permalink
);

PageMeta.propTypes = {
    domain: PropTypes.string.isRequired,
    pageData: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
};

export default PageMeta;
