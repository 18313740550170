import React, { FunctionComponent } from 'react';

import G from './base/G';
import Path from './base/Path';
import Polygon from './base/Polygon';
import SVG from './base/SVG';

interface Props {
    color: string;
    height: (string | null)[] | string;
    viewBox: string;
    width: (string | null)[] | string;
}

const OrreforsLogo: FunctionComponent<Props> = ({
    color = 'white',
    height = '25px',
    viewBox = '0 0 200 43',
    width = '115px',
    ...props
}: Props) => (
    <SVG width={width} height={height} viewBox={viewBox} fill={color} {...props}>
        <G clipPath="url(#clip0)" id="orrefors-logo">
            <Path
                class="st0"
                d="M112.8,7.8c-7,0-12.6,5.3-12.6,12.6c0,7.2,5.6,12.6,12.6,12.6c7,0,12.6-5.4,12.6-12.6
		C125.4,13.1,119.8,7.8,112.8,7.8z M112.8,30.4c-5.4,0-9.8-4.5-9.8-10c0-5.6,4.4-10,9.8-10c5.4,0,9.8,4.5,9.8,10
		C122.7,25.9,118.3,30.4,112.8,30.4z"
            />
            <Path
                class="st0"
                d="M42.3,14.8c0-2.7-1.3-4.6-2.8-5.6c-1.9-1.3-4.4-1.4-6.1-1.4h-4V33h2.8v-11h0.7l7.7,11h3.4l-8-11.3
		C39.7,21.4,42.3,18.6,42.3,14.8z M32.1,19.4v-9h1.7c1.2,0,2.5,0.1,3.6,0.7c1.6,0.9,2,2.3,2,3.7c0,2.9-2.1,4.6-5.6,4.6H32.1z"
            />
            <Path
                class="st0"
                d="M61.2,14.8c0-2.7-1.3-4.6-2.8-5.6c-1.9-1.3-4.4-1.4-6.1-1.4h-4V33h2.8v-11h0.7l7.7,11h3.4l-8-11.3
		C58.7,21.4,61.2,18.6,61.2,14.8z M51.1,19.4v-9h1.7c1.2,0,2.5,0.1,3.6,0.7c1.6,0.9,2,2.3,2,3.7c0,2.9-2.1,4.6-5.6,4.6H51.1z"
            />
            <Path
                class="st0"
                d="M142.5,14.8c0-2.7-1.3-4.6-2.8-5.6c-1.9-1.3-4.4-1.4-6.1-1.4h-4V33h2.8v-11h0.7l7.7,11h3.4l-8-11.3
		C140,21.4,142.5,18.6,142.5,14.8z M132.4,19.4v-9h1.7c1.2,0,2.5,0.1,3.6,0.7c1.6,0.9,2,2.3,2,3.7c0,2.9-2.1,4.6-5.6,4.6H132.4z"
            />
            <Path
                class="st0"
                d="M157.3,18.8l-2.4-1c-0.9-0.4-1.9-0.8-2.6-1.5c-0.7-0.6-0.9-1.2-0.9-2.1c0-2.3,1.7-3.8,4.2-3.8
		c1.4,0,2.3,0.5,2.8,0.8c0.6,0.4,1.1,1.2,1.5,1.8l2.2-1.4c-0.6-1.1-1.4-2-2.5-2.7c-1.2-0.8-2.5-1.1-4-1.1c-4.1,0-7.1,2.5-7.1,6.3
		c0,1.8,0.6,3,1.7,4c1.1,1.1,2.4,1.6,3.4,2l2.3,1c1.3,0.6,2.3,1,3,1.7c0.9,0.8,1.2,1.8,1.2,2.8c0,2.4-1.6,4.8-5.1,4.8
		c-1.3,0-2.5-0.3-3.6-1.4c-0.7-0.7-1.3-1.8-1.5-3.1l-2.7,0.7c0.3,1.5,1.1,3.1,2.3,4.3c1.7,1.6,3.8,2,5.5,2c4.5,0,8-3,8-7.5
		c0-1.8-0.5-3.2-1.8-4.4C160.1,20,158.8,19.4,157.3,18.8z"
            />
            <Path
                class="st0"
                d="M12.6,7.8C5.6,7.8,0,13.1,0,20.4C0,27.6,5.6,33,12.6,33c7,0,12.6-5.4,12.6-12.6C25.2,13.1,19.6,7.8,12.6,7.8z
        M12.6,30.4c-5.4,0-9.8-4.5-9.8-10c0-5.6,4.4-10,9.8-10c5.4,0,9.8,4.5,9.8,10C22.4,25.9,18,30.4,12.6,30.4z"
            />
            <Polygon
                class="st0"
                points="80.3,10.4 80.3,7.8 67.3,7.8 67.3,33 80.3,33 80.3,30.3 70.1,30.3 70.1,20.5 79.3,20.5 79.3,17.9 
		70.1,17.9 70.1,10.4"
                fill={color}
            />
            <Polygon
                class="st0"
                points="97.7,10.4 97.7,7.8 86.2,7.8 86.2,33 89,33 89,20.5 96.6,20.5 96.6,17.9 89,17.9 89,10.4"
                fill={color}
            />
        </G>
        <Path
            class="st0"
            d="M196.5,11.3c-0.6-0.4-1.3-0.4-1.9,0c-1.2,0.7-3.9,3.2-3.9,3.2s-1.1-0.1-3,0.3c-1.5,0.3-2.8,1-3.5,1.5
	c0.2-0.7,0.3-1.3,0.3-1.3c-1.7,0-1.8,1.5-1.8,1.5s-0.7,0-1.1,0.7c-0.2,0.4-0.2,0.9,0,1.3c-0.3,0.1-0.9,0.1-1.3-0.3
	c-0.4-0.4-0.4-1.2,0-1.9c0,0-1.3-0.2-1.8,1.3c-0.2,0.7,0,1.6,0.5,2.2c0.9,0.9,2.5,0.7,2.5,0.7s-0.8,0.5-1.1,1.6
	c-0.4,1.5,0.6,2.9,2.2,2.9c1.5,0,2.1-0.9,2.1-0.9s-1.6-0.2-1.9-1.2c-0.2-0.8,0.4-1.1,0.4-1.1s1,0.8,3.4,1.2l0.4,1.4
	c0,0-1.3,0.1-1.3,0.7c0.8,0,1.5,0,1.5,0s0.5,0.5,0.7,0.7c0.4,0.4,0.7,0.5,1.1,0.7c0,0,0.1-0.2,0-0.5c-0.1-0.4-0.7-0.9-0.7-0.9h2.2
	c-0.1-0.2-0.4-0.4-0.9-0.5c-0.7-0.2-1.8-0.2-1.8-0.2s0-0.2,0-1.3c1.9-0.1,3.5-0.9,5-3.5c1.1-1.9,1.7-4.1,2.2-5.7
	c0.1,0.5,0.8,0.6,1.1,0.3c0.3-0.3,0.3-0.9,0.7-1.2c0.3-0.3,1-0.3,1-0.3S197.3,11.8,196.5,11.3z M195.9,12.4c-0.2,0-0.3-0.1-0.3-0.3
	c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3C196.2,12.2,196,12.4,195.9,12.4z"
        />
        <Path
            class="st0"
            d="M199.7,7.8h-26.4c-0.2,1.3-0.3,2.6-0.3,4c0,9.4,5.5,17.5,13.5,21.2c8-3.7,13.5-11.8,13.5-21.2
	C200,10.4,199.9,9.1,199.7,7.8z M186.5,32.2c-3.7-1.8-6.9-4.6-9.1-8c-2.4-3.6-3.6-7.9-3.6-12.2c0-1.1,0.1-2.3,0.3-3.4h25
	c0.2,1.1,0.3,2.3,0.3,3.4c0,4.4-1.2,8.6-3.6,12.2C193.4,27.6,190.2,30.4,186.5,32.2z"
        />
    </SVG>
);

export default OrreforsLogo;
