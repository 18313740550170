import maxWidth from './maxWidth';

export type BreakPointsType = {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
};

export const breakpoints = ['375px', '768px', '1024px', '1440px', maxWidth];
export const labels = ['xs', 'sm', 'md', 'lg', 'xl'];

// Prepared mediaqueries for common usecases with Image or BackgroundImage
export const mediaQueries = {
    fullWidth: [
        /* 0  <-  xs */ { w: 375, q: 70 },
        /* xs <-> sm */ { w: 768, q: 70 },
        /* sm <-> md */ { w: 1024, q: 70 },
        /* md <-> lg */ { w: 1440, q: 70 },
        /* lg <-> xl */ { w: 1920, q: 70 },
        /* xl ->     */ { w: 2200, q: 70 },
    ],
    maxWidth: [
        /* 0  <-  xs */ { w: 375, q: 70 },
        /* xs <-> sm */ { w: 768, q: 70 },
        /* sm <-> md */ { w: 1024, q: 70 },
        /* md <-> lg */ { w: 1440, q: 70 },
        /* lg <-> xl */ { w: 1920, q: 70 },
        /* xl ->     */ { w: 1920, q: 70 },
    ],
    twoColumn: [
        /* 0  <-  xs */ { w: 375, q: 70 },
        /* xs <-> sm */ { w: 768 / 2, q: 70 },
        /* sm <-> md */ { w: 1024 / 2, q: 70 },
        /* md <-> lg */ { w: 1440 / 2, q: 70 },
        /* lg <-> xl */ { w: 1920 / 2, q: 70 },
        /* xl ->     */ { w: 1920 / 2, q: 70 },
    ],
    threeColumn: [
        /* 0  <-  xs */ { w: 375, q: 70 },
        /* xs <-> sm */ { w: 768 / 3, q: 70 },
        /* sm <-> md */ { w: 1024 / 3, q: 70 },
        /* md <-> lg */ { w: 1440 / 3, q: 70 },
        /* lg <-> xl */ { w: 1920 / 3, q: 70 },
        /* xl ->     */ { w: 1920 / 3, q: 70 },
    ],
};
