import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    margin: -6px 12px;
`;

const StyledLink = styled(Link)`
    margin: 6px 0;
`;

const Links = ({ handleClick, links = [], twoColumns = false }) => {
    if (!links.length) {
        return null;
    }

    return (
        <Wrapper flexDirection={twoColumns ? 'row' : 'column'}>
            {links.map((link, index) => {
                if (!link) {
                    return null;
                }

                return link.label && link.to ? (
                    <StyledLink
                        key={`${link.label}-${index}`}
                        to={link.to}
                        width={twoColumns ? '50%' : '100%'}
                        onClick={() => handleClick()}
                    >
                        {link.label}
                    </StyledLink>
                ) : null;
            })}
        </Wrapper>
    );
};

Links.propTypes = {
    handleClick: PropTypes.func.isRequired,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ).isRequired,
    twoColumns: PropTypes.bool,
};

export default Links;
