import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    cursor: pointer;

    ${above.sm} {
        padding: 4px 12px;
    }

    ${above.md} {
        padding: 4px 24px;
    }
`;

const Text = styled(Paragraph, {
    shouldForwardProp: prop => ['isActive'].indexOf(prop) === -1,
})`
    display: flex;
    align-items: center;
    margin: 0;
    color: ${({ isActive }) => (isActive ? colors.black : colors.grey5)};

    &::before {
        content: '';
        display: ${({ isActive }) => (isActive ? 'block' : 'none')};
        width: 6px;
        height: 6px;
        margin-right: 6px;
        border-radius: 50%;
        background-color: ${colors.black};
    }
`;

const FilterListItem = ({ text = '', isActive = false }) => (
    <Wrapper>
        <Text isActive={isActive}>{text}</Text>
    </Wrapper>
);

FilterListItem.propTypes = {
    isActive: PropTypes.bool,
    text: PropTypes.string,
};

export default FilterListItem;
