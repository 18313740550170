import React, { useState } from 'react';

import Price from 'components/products/Price';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import { injectModels } from 'state';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Button = styled(ThemeButton)`
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: none;
    font-size: 1.6rem;

    ${above.md} {
        font-size: 1.8rem;
    }
`;

const StyledPrice = styled(Price)`
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;

    ${above.md} {
        font-size: 1.8rem;
    }

    .salePrice {
        margin: 4px 0 0 0;
    }

    @media screen and (min-width: 500px) {
        flex-direction: row;

        .salePrice {
            margin: 0 0 0 8px;
        }
    }
`;

const Dash = styled('div')`
    margin: 0 8px;
`;

const AddToBasketButton = ({
    basket,
    currentProduct = {},
    inStock = false,
    latestAddedProduct = {},
    price,
    variationId,
}) => {
    const { t } = useTranslation();

    const [buttonState, setButtonState] = useState({
        success: false,
        loading: false,
        warning: false,
    });

    const { priceWithCurrency, salePriceWithCurrency, onSale } = price;

    const addToCart = async quantity => {
        setButtonState({ ...buttonState, loading: true });

        try {
            const response = await basket.addToBasket(variationId, quantity, null);

            if (response.status === 200 || response.status === 201) {
                latestAddedProduct.addLatestProduct(currentProduct);
                setButtonState({ ...buttonState, success: true, loading: false });
            } else {
                setButtonState({ ...buttonState, warning: true, success: false, loading: false });
            }
        } catch (error) {
            console.error(error);
            setButtonState({ ...buttonState, warning: true });
        } finally {
            setTimeout(
                () =>
                    setButtonState({
                        success: false,
                        loading: false,
                        warning: false,
                    }),
                2800
            );
        }
    };

    const getButtonTheme = buttonState => {
        return buttonState.warning ? 'error' : 'green';
    };

    const getButtonText = (inStock, buttonState) => {
        if (!inStock) {
            return t('checkout_basket.out_of_stock');
        }
        return buttonState.warning
            ? t('checkout_basket.error_try_again')
            : buttonState.success
            ? t('checkout_basket.added_to_basket')
            : t('checkout_basket.add_to_basket');
    };

    const getLoading = () => {
        return basket.isFetching || buttonState.loading;
    };

    const isLoading = getLoading();
    const buttonTheme = getButtonTheme(buttonState);
    const buttonText = getButtonText(inStock, buttonState);
    const quantity = 1;
    const showPrice = !buttonState.success && !buttonState.loading && !buttonState.warning;

    return (
        <Button
            size="xl"
            disabled={!inStock}
            theme={buttonTheme}
            onClick={() => {
                if (!isLoading && inStock) {
                    addToCart(quantity);
                }
            }}
        >
            {buttonText}
            {showPrice && (
                <>
                    <Dash>&#8212;</Dash>
                    <StyledPrice
                        priceWithCurrency={priceWithCurrency}
                        salePriceWithCurrency={salePriceWithCurrency}
                        onSale={!!onSale}
                    />
                </>
            )}
        </Button>
    );
};

AddToBasketButton.propTypes = {
    basket: PropTypes.shape({
        addToBasket: PropTypes.func,
        toggleBasket: PropTypes.func,
        isFetching: PropTypes.bool,
    }).isRequired,
    currentProduct: PropTypes.object,
    inStock: PropTypes.bool,
    latestAddedProduct: PropTypes.exact({
        addLatestProduct: PropTypes.func,
        product: PropTypes.object, // Only passing through
    }),
    price: PropTypes.shape({
        onSale: PropTypes.bool,
        priceWithCurrency: PropTypes.string,
        salePriceWithCurrency: PropTypes.string,
    }).isRequired,
    variationId: PropTypes.string,
};

export default injectModels(['basket', 'latestAddedProduct'])(AddToBasketButton);
